
















































































.operat-place-con {
  display: inline-block;
  margin: 0 16px;
  cursor: pointer;
  .img {
    width: 410px;
    height: 41px;
  }
  .text-con {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    color: #363636;
    padding: 0 16px;
    .left-icon {
      height: 28px;
      width: 28px;
      margin-right: 4px;
    }
    .back-img {
      // width: 16px;
      // height: 16px;
      margin-left: 8px;
    }
  }
}
