



















































.module-to-ask {
  height: 200px;
  border-radius: 4px;
  background: linear-gradient(180deg, #d6e5ff 0%, #fbfdff 22%, #ffffff 100%);
  position: relative;
  .background-icon-1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .to-ask-ctx {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 23px 16px 16px;
    margin-bottom: 8px;
    .to-ask-ctx-title {
      font-size: 18px;
      color: #313131;
      line-height: 25px;
      font-weight: bolder;
      margin-bottom: 19px;
    }
    .to-ask-ctx-input {
      display: flex;
      margin-bottom: 4px;
      .input-1 {
        width: 100%;
        height: 40px;
        ::v-deep {
          .el-input__inner {
            // border: 1px solid #cccccc !important;
            border-right: none !important;
            border-radius: 4px 0 0 4px !important;
          }
        }
      }
      .btn-1 {
        flex-shrink: 0;
        width: 65px;
        height: 40px;
        background: #4b8fff;
        border-radius: 0px 4px 4px 0px;
        font-size: 16px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  .or-line {
    width: 100%;
    height: 20px;
    margin-bottom: 8px;
  }
  .to-ask-btn {
    height: 40px;
    width: 100%;
    color: #ffffff;
    font-size: 16px;
    border-radius: 4px;
    background: #4b8fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .to-ask-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
