









































































.dynamic-list {
  .bottom-txt {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #bbbbbb;
    line-height: 20px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.pointer {
      cursor: pointer;
      color: #bbbbbb;
      &:hover {
        color: #4b8fff;
      }
    }
  }
}
