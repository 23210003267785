




















































































































































































































































.el-popup-parent--hidden {
  padding-right: 0 !important;
  overflow: visible;
}
.v-modal {
  opacity: unset !important;
  background: rgba(0, 0, 0, 0.5) !important;
  transition: unset;
}
.fun-custom-el-confirm {
  width: 500px !important;
  padding-bottom: 24px !important;
  .el-message-box__header {
    padding: 16px 21px 0px 32px;
    .el-message-box__title {
      font-weight: 500;
      color: #333333;
    }
  }
  .el-message-box__content {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    padding: 31px 54px 32px 34px;
    .el-message-box__message {
      padding-right: 0;
    }
    .el-icon-warning {
      top: 12px;
      color: #faad14;
      font-size: 21px !important;
    }
  }
  .el-message-box__btns {
    .el-button {
      padding: 9px 18px;
      &.el-button--default {
        float: right;
        margin-left: 10px;
      }
      &.el-button--primary {
        border-color: #4b8fff !important;
        background-color: #4b8fff !important;
      }
    }
  }
}
