



























.tabs-errata-list {
  padding: 23px 0;
  .errata-item {
    border-left: 6px solid #ffffff;
    padding: 20px 48px;
    cursor: pointer;
    &:hover {
      background-clip: padding-box;
      background-color: rgba(75, 143, 255, 0.1);
      border-left: 6px solid rgba(75, 143, 255, 0.1);
    }
    .errata-book-title {
      color: #363636;
      font-size: 16px;
      font-weight: bolder;
      line-height: 22px;
      margin-bottom: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .errata-content {
      color: #5a5a5a;
      font-size: 14px;
      margin-bottom: 8px;
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .errata-time {
      font-size: 14px;
      line-height: 20px;
      color: #5a5a5a;
    }
  }
  .active-item {
    background-clip: padding-box;
    background-color: rgba(75, 143, 255, 0.1);
    border-left: 6px solid #4b8fff !important;
    .errata-book-title,
    .errata-content,
    .errata-time {
      color: #4b8fff;
    }
  }
}
