



















































.ellipsis-content {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  word-break: break-all;
  text-align: justify;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  &::before {
    content: '';
    height: calc(100% - var(--lineHeight));
    float: right;
  }
  .detail-span {
    clear: both;
    float: right;
    padding-left: 6px;
  }
  .click-span {
    color: #4b8fff;
    cursor: pointer;
    display: inline;
  }
}
