




































































































































































































































































.icon-lottie {
  margin: 0 2px;
  position: relative;
  ::v-deepsvg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
.lesson-item-content {
  cursor: pointer;
}
