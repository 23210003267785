








































.my-ask-favorite {
  padding: 16px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  .default-btn {
    width: 130px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    .default-btn-icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
    .default-btn-behind {
      width: 12px;
      height: 12px;
      margin-left: 2px;
    }
  }
}
.blue-btn {
  background: rgba(75, 143, 255, 0.03);
}
.orange-btn {
  background: rgba(255, 169, 52, 0.03);
}
