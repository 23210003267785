






































































































































::v-deep {
  .bt-default-diaolog {
    border-radius: 8px;
    .el-dialog__header {
      text-align: center;
      font-size: 18px;
      color: #262626;
      font-weight: bolder;
    }
    .el-dialog__body {
      padding: 20px 42px;
      .tip-item {
        color: #595959;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .true-btn {
        width: 200px;
        height: 40px;
        border-radius: 20px;
        background: #4b8fff;
        margin: 62px auto 0;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
.facing-card {
  width: 254px;
  height: 176px;
  margin: 0 6px 16px;
  background-image: url('../../../../../assets/images/coupon-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .is-used {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 79px;
    display: none;
  }
  .is-failure {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 79px;
    display: none;
  }
  .is-choose {
    position: absolute;
    top: 8px;
    right: 16px;
    width: 20px;
    height: 20px;
  }
  .facing-card-header {
    height: 116px;
    .facing-card-header-1 {
      margin-bottom: 7px;
      .reply-time {
        background: linear-gradient(317deg, #ffeda3 0%, #ffd66a 100%);
        border-radius: 4px 0px 4px 0px;
        height: 24px;
        width: auto;
        padding: 4px 8px;
        font-size: 12px;
        color: #b23800;
        display: inline-block;
      }
    }
    .facing-card-header-2 {
      display: flex;
      align-items: baseline;
      justify-content: center;
      margin-bottom: 16px;
      .category-type {
        color: #f0153d;
        font-size: 28px;
        line-height: 40px;
        font-weight: bolder#F0153D;
        margin-right: 6px;
      }
      .has-length {
        font-size: 18px;
        color: #f0153d;
      }
    }
    .facing-card-header-3 {
      font-size: 12px;
      color: #843f29;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      cursor: pointer;
      line-height: 16px;
      .other-text {
        flex-shrink: 0;
      }
      .category-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .open-icon {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        display: inline-block;
      }
    }
  }
  .facing-card-footer {
    height: 60px;
    font-size: 12px;
    color: #843f29;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.is-history {
  .facing-card-header,
  .facing-card-footer {
    opacity: 0.5;
  }
  .is-used {
    display: block;
  }
  .is-failure {
    display: block;
  }
  .facing-card-header-3 {
    cursor: inherit;
    pointer-events: none;
  }
}
.is-choose {
  cursor: pointer;
}
