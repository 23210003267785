.my-colume-con {
  .form-horizontal {
    .control-label {
      width: 88px;
      height: 40px;
      text-align: left;
      margin-bottom: 0;
      padding-top: 7px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }

    .form-group {
      margin-bottom: 24px;
    }
  }

  .form-control {
    color: #616161;
    width: 302px;
    height: 40px;
    border-radius: 4px;
    border: none;
    border: 1px solid rgba(225, 225, 225, 1);
    overflow: hidden;
    padding: 0 0 0 10px;

    .el-input__inner {
      border: none;
      padding-left: 0px;
    }

    &.invalid {
      border: 1px solid #f22435;
    }

    &:focus {
      border: 1px solid #1e73ff;
      outline: 0;
    }
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #bfbfbf;
  }

  .bottom-btn {
    margin-left: 98px;
  }
}