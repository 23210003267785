input.invalid,textarea.invalid {
  border: 1px solid #f22435;
  &:focus {
    border: 1px solid #f22435;
  }
}

.error {
  display: inline-block;
  margin-top: 4px;
  color: #f22435;
}

.font-family-pinfang {
  font-family: PingFangSC-Semibold, PingFang SC;
}

.animation-flashing {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &::before {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;
    position: absolute;
    z-index: 6;
    overflow: hidden;
    background: linear-gradient(270deg,
        rgba(255, 245, 174, 0) 0%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 247, 152, 0) 100%);
    opacity: 0.79;

    -webkit-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    -webkit-animation: funaniBlink 2s infinite;
    -moz-animation: funaniBlink 2s infinite;
    -o-animation: funaniBlink 2s infinite;
    animation: funaniBlink 2s infinite;
  }
}

@keyframes funaniBlink {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 65px;
  }
}

@-webkit-keyframes funaniBlink {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 65px;
  }
}

@font-face {
  font-family: 'GilroyBold4';
  src: url('../fonts/gilroy-bold-4.otf');
  font-weight: normal;
  font-style: normal;
}