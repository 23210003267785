// 直播课时
.live-lesson-con-activity {
  background-image: url('https://tmp.btclass.cn/yunyin/o_1hep1u67edj5putvaj7815lt.png');
  background-size: 100% 99.9%;
  height: 13.4375rem;
  padding: .7617rem .5859rem 1.1914rem .5859rem;
  display: flex;
  flex-direction: column;
  &.no-cur-lesson {
    background-image: url('https://tmp.btclass.cn/yunyin/o_1hep1u67e1eb1a6f7b5ema1p4nu.png');
    height: 5.8594rem;
    margin-top: .3125rem;
  }
  .lesson-item-con {
    display: flex;
    justify-content: center;
    .detail-btn {
      position: absolute;
      bottom: .2734rem;
      right: .293rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .num-con {
      font-size: .3906rem;
      font-weight: 400;
      color: #511700;
      line-height: .5469rem;
      opacity: 0.4;
      .avators {
        padding-left: .2344rem;
        display: flex;
        align-items: center;
        .avator-item {
          width: .4297rem;
          height: .4297rem;
          border-radius: 50%;
          margin-left: -0.2344rem;
        }
      }
      .num {
        margin-left: .0391rem;
        flex-shrink: 0;
        margin-top: .0195rem;
      }
    }
    .teacher-info {
      display: flex;
      align-items: center;
      .teacher-tag {
        display: inline-block;
        padding: 0 .1563rem;
        height: .6836rem;
        line-height: .6836rem;
        background: #ffe3df;
        border-radius: .1563rem .1563rem .1563rem 0rem;
        font-size: .3906rem;
        color: #ff5353;
        line-height: .6836rem;
        margin-left: .2734rem;
      }
    }

    .lesson-item-content {
      display: flex;
      justify-content: center;
    }

    .lesson-item-large {
      width: 20.3125rem;
      height: 5.4883rem;
      background: rgba(255, 255, 255, 0.39);
      border-radius: .1563rem;
      border: .0195rem solid #ffc158;
      position: relative;

      .course-img {
        width: 9.7656rem;
        height: 5.4883rem;
        flex-shrink: 0;
        position: relative;
        border-radius: .1563rem;
        overflow: hidden;
        position: relative;
        .learn-status {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          border-radius: .1563rem;
          position: absolute;
          .living-tag {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5195rem;
            height: .9766rem;
            background: linear-gradient(90deg, #fb494a 0%, #fb494a 100%);
            border-radius: .2148rem .0586rem .2148rem .0586rem;
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: .5078rem;
            color: #ffffff;
            transform: translate(-50%, -50%);
          }
        }

        .img-responsive {
          height: 100%;
          width: 100%;
        }
      }
      .course-info-con {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: .625rem .7813rem .8203rem .3906rem;
        width: 100%;

        .course-title {
          font-size: .5469rem;
          font-weight: 600;
          color: #333333;
          line-height: .7813rem;
          width: 9.3164rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
        }

        .teacher-info {
          height: .6445rem;
          font-size: .4688rem;
          color: #511700;
          line-height: .6445rem;
        }
        .time-span {
          font-size: .4688rem;
          color: #ff6e55;
          line-height: .6445rem;
        }
        .large-btn {
          height: 1.0352rem;
          padding: 0 .3906rem;
          background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
          border-radius: .6445rem;
          font-size: .4688rem;
          text-align: center;
          font-weight: 600;
          color: #ffffff;

          right: .8398rem;
          bottom: .625rem;
        }
      }
    }
    .lesson-item {
      width: 7.1875rem;
      height: 100%;
      background-image: url('https://tmp.btclass.cn/yunyin/o_1hep1uvh4fuotdg1md9133h5e518.png');
      background-size: 100% 99.9%;
      position: relative;
      display: flex;
      align-items: center;
      .lesson-item-content {
        padding: .2344rem;
      }
      .living-tag {
        width: 1.7578rem;
        height: .4492rem;
        background: linear-gradient(90deg, #fb494a 0%, #fb494a 100%);
        border-radius: .1758rem 0rem .1758rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        z-index: 2;
        font-size: .2344rem;
      }
      .time-tag {
        width: 1.7578rem;
        font-size: .2344rem;
        height: .4492rem;
        background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
        border-radius: .1758rem 0rem .1758rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        z-index: 2;
      }
      .teacher-img {
        width: 1.8164rem;
        height: 1.8164rem;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .lesson-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0rem .0781rem 0 .1758rem;
        width: 100%;
        .lesson-title {
          display: inline-block;
          max-width: 4.6484rem;
          font-size: .3516rem;
          font-weight: 600;
          color: #363636;
          line-height: .4883rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          margin-bottom: .3125rem;
        }

        .teacher-info {
          font-size: .2734rem;
          color: #5a5a5a;
          line-height: .3906rem;
          margin-bottom: .0781rem;
          .teacher-tag {
            font-size: .2344rem;
            height: .3711rem;
            line-height: .3711rem;
            background: #ffefed;
            border-radius: .0781rem .0781rem .0781rem 0rem;
            padding: 0 .0586rem;
            margin-left: .1758rem;
          }
        }

        .time-span {
          font-size: .2734rem;
          color: #4e9afb;
          line-height: .3516rem;
        }
        .num-con {
          font-size: .2344rem;
          line-height: .3125rem;
          .icon {
            font-size: .2734rem;
          }
        }
        .s-btn {
          width: 1.4063rem;
          height: .5469rem;
          border: .0195rem solid #ffffff;
          font-size: .2734rem;
          font-weight: 600;
          border-radius: .3125rem;
          color: #ffffff;
          background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
        }
      }
    }
  }
  .lesson-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 .1563rem;
    &.cur-lesson-block {
      margin-bottom: .7813rem;
      min-height: 6.6406rem;
    }
    .lesson-block-title {
      height: .8203rem;
      font-size: .5859rem;
      color: #511700;
      font-weight: 600;
      line-height: .8203rem;
      margin-bottom: .4297rem;
    }
  }
  .swiper-slider {
    position: relative;
    width: 100%;

    .swiper-wrapper {
      width: 7.1875rem;
    }

    .swiper-btn {
      width: .7422rem;
      height: .7422rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      background-size: 100% 99.9%;
      background-repeat: no-repeat;
    }
    .swiper-left-btn {
      left: -0.1953rem;
      background-image: url('https://tmp.btclass.cn/yunyin/o_1hep1n5go22eauo1bp1bik1mr9b.png');
    }
    .swiper-right-btn {
      right: -0.1953rem;
      background-image: url('https://tmp.btclass.cn/yunyin/o_1hep1n5gog6v2mht6uvf1innc.png');
    }
  }
  .cur-swiper-slider {
    position: relative;
    width: 100%;

    .swiper-btn {
      width: .9366rem !important;
      height: .9366rem !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      background-size: 100% 99.9%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .cur-swiper-left-btn {
      left: -0.1951rem;
      background-image: url('https://tmp.btclass.cn/yunyin/o_1hep1osm21cnvlm1gqeuqm1q5im.png');
    }
    .cur-swiper-right-btn {
      right: -0.1951rem;
      background-image: url('https://app-cdn.btclass.cn/new-prefix/turtle/FrI4HC85tolYMjT9TeRf7qybVjqb.png');
    }
  }
}
