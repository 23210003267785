




























































































































































::v-deep {
  .upgrade-facing {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0 0 20px 0;
    }
    .header-ctx {
      font-size: 18px;
      color: #262626;
      padding: 20px 0 16px;
      font-weight: bolder;
      text-align: center;
      position: relative;
      .close-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }
    }
    .dialog-ctx {
      padding: 0 40px 20px;
      .mode-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
        .mode-1-title {
          font-size: 16px;
          color: #313131;
        }
        .mode-1-num {
          color: #ff6262;
          .f-z-24 {
            font-size: 24px;
            font-weight: bolder;
          }
          .f-z-16 {
            font-size: 16px;
          }
        }
      }
      .mode-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;
        .mode-2-title {
          font-size: 16px;
          color: #313131;
          font-weight: bolder;
        }
        .mode-2-num {
          color: #ff6262;
          .f-z-24 {
            font-size: 24px;
            font-weight: bolder;
          }
          .f-z-16 {
            font-size: 16px;
          }
        }
      }
      .midlle-line {
        width: 100%;
        border-bottom: 1px solid #efefef;
        margin: 2px 0 22px;
      }
      .b-number {
        display: flex;
        align-items: center;
        margin-bottom: 29px;
        .b-number-1 {
          font-size: 16px;
          color: #595959;
          line-height: 20px;
          margin-right: 20px;
          .blue-text {
            color: #4b8fff;
          }
          .blue-text-loading {
            color: #4b8fff;
          }
        }
        .b-number-2 {
          font-size: 14px;
          color: #4b8fff;
          line-height: 20px;
          margin-right: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }
        }
        .b-number-3 {
          color: #4b8fff;
          font-size: 14px;
          cursor: pointer;
          line-height: 20px;
        }
      }
      .rules-tip {
        color: #919191;
        font-size: 16px;
        line-height: 22px;
        .rules-tip-title {
          margin-bottom: 11px;
        }
      }
      .submit-btn {
        width: 200px;
        height: 40px;
        color: #ffffff;
        font-size: 14px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 0;
        cursor: pointer;
      }
    }
  }
}
