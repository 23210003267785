


















































































































































































































































































































.lottery-9-con {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-image: url('https://tmp.btclass.cn/yunyin/o_1he1r0nrf12d41jrv16rt1jg31l42a.png');
  background-size: cover;
  width: 11.8359rem;
  height: 100%;
  animation: cBg 1s infinite;

  .coin-info {
    width: 7.2266rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.6445rem;
    font-size: 0.4688rem;
    font-family: AlibabaPuHuiTiMedium;
    font-weight: normal;
    color: #6f3c00;
    line-height: 0.6445rem;
    position: absolute;
    top: 0.3516rem;
    .recharge-btn {
      cursor: pointer;
      font-size: 0.3906rem;
    }
  }
  .lottery-content {
    width: 10.4688rem;
    height: 10.4688rem;
    padding: 0.2344rem;
    border-radius: 0.5469rem;
    margin-bottom: 1.2891rem;
    display: grid;
    grid-template-columns: repeat(3, 3.2031rem);
    grid-template-rows: repeat(3, 3.2031rem);
    grid-row-gap: 0.1758rem;
    grid-column-gap: 0.1758rem;

    .lottery-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url('https://tmp.btclass.cn/yunyin/o_1he1rgsabhjnvafau1npia1s1q.png');
      background-size: cover;
      position: relative;

      .prize-img {
        width: 2.1875rem;
        height: 2.1875rem;
      }
      .label {
        height: 0.5664rem;
        font-size: 0.4102rem;
        font-family: AlibabaPuHuiTiMedium;
        font-weight: normal;
        color: #6f3c00;
        display: flex;
        align-items: center;
      }
      &.start-btn {
        animation: sport 0.8s infinite;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .start-txt {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0.3125rem;
          font-size: 0.4102rem;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #821f09;
          line-height: 0.5664rem;
          white-space: nowrap;
        }
      }
    }
    &.is-prizing {
      .lottery-item {
        &:not(.start-btn):not(.is-active)::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.49);
          position: absolute;
          border-radius: 0.5273rem;
        }
        &.start-btn {
          pointer-events: none;
          animation: none;
        }
      }
    }
  }
  .need-b-coin {
    width: 7.1875rem;

    .f600 {
      font-weight: 600;
    }
    .tip-span {
      font-size: 0.3125rem;
      font-weight: 400;
      color: #363636;
      line-height: 0.4297rem;
      text-align: center;
      margin-bottom: 0.6641rem;
      display: block;
    }
  }
  .no-coin-con {
    width: 7.1875rem;
    text-align: center;
    .c-title {
      display: inline-block;
      height: 0.4883rem;
      font-weight: 600;
      background: linear-gradient(180deg, #fe393c 0%, #cf171e 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 0.4883rem;
      font-size: 0.3906rem;
      position: relative;
      margin: 0 0.1563rem;
      margin-bottom: 0.332rem;
      font-family: AlibabaPuHuiTi;
      &::after,
      &::before {
        content: '';
        display: inline-block;
        width: 0.8008rem;
        height: 0.0586rem;
        background: linear-gradient(297deg, rgba(239, 201, 158, 0) 0%, #fe3535 100%, #da9865 100%);
        border-radius: 0.0391rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &::before {
        left: -1.2695rem;
      }
      &::after {
        right: -1.2695rem;
      }
      &::before {
        transform: rotate(180deg);
      }
    }
    .tip-span {
      font-size: 0.3516rem;
      font-weight: 600;
      color: #363636;
      line-height: 0.4883rem;
      text-align: center;
      margin-bottom: 0.4688rem;
      margin-top: 0.1758rem;
    }

    .step-con {
      width: 5.925rem;
      height: 1.3281rem;
      background: #ffffff;
      border-radius: 0.1563rem;
      font-size: 0.3125rem;
      font-weight: 400;
      color: #363636;
      line-height: 0.3906rem;
      padding: 0.1953rem 0.3125rem;
      margin: 0 auto;
      margin-bottom: 0.6641rem;
      text-align: left;
      padding-right: 0;
      .f600 {
        font-weight: 600;
      }
      .mgb8 {
        margin-bottom: 0.1563rem;
      }
    }
  }
  .dialog-b-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2734rem;
    margin-bottom: 0.2344rem;
    font-size: 0.3125rem;
    font-weight: 600;
    color: #ffffff;

    .left-btn,
    .right-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 100% 100%;
      cursor: pointer;
      width: 3.3594rem;
      height: 1.0156rem;
    }
    .left-btn {
      background-image: url('https://tmp.btclass.cn/yunyin/o_1he4dpi89vfqtvn16qp1nbb1uv5c.png');
    }
    .right-btn {
      background-image: url('https://tmp.btclass.cn/yunyin/o_1he4dpi89okhi868q711q73fub.png');
    }
  }
  .tip-con {
    width: 7.1875rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .tip-span {
      height: 0.9766rem;
      font-size: 0.3125rem;
      font-weight: 400;
      color: #363636;
      line-height: 0.4883rem;
      margin-bottom: 0.2148rem;
      text-align: center;
    }
    .tip-btn {
      background-image: url('https://tmp.btclass.cn/yunyin/o_1he9lu4u53fb7pjcru9jt18kca.png');
      background-size: cover;
      width: 5.3125rem;
      height: 1.0156rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.2734rem;
      font-size: 0.3125rem;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

@keyframes sport {
  0% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(0.98);
  }
}

@keyframes cBg {
  0% {
    background-image: url('https://tmp.btclass.cn/yunyin/o_1he1r0nrf12d41jrv16rt1jg31l42a.png');
  }
  50% {
    background-image: url('https://tmp.btclass.cn/yunyin/o_1he7d1rbhkdjqt5163k1ph9rcsa.png');
  }
  100% {
    background-image: url('https://tmp.btclass.cn/yunyin/o_1he1r0nrf12d41jrv16rt1jg31l42a.png');
  }
}
.red-text {
  color: #ee2d32;
}
