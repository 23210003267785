























































































































.complain-dailog-container {
  position: relative;
  width: 730px;
  background: linear-gradient(180deg, #e4eaff 0%, #fefeff 18%, #ffffff 100%);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  min-height: 345px;
  .top--icon-img {
    position: absolute;
    width: 118px;
    height: 86px;
    top: 0;
    right: 0;
  }
  .close-icon {
    position: absolute;
    cursor: pointer;
    right: 21px;
    top: 21px;
    width: 21px;
    height: 21px;
  }
  .title {
    font-size: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #262626;
    line-height: 24px;
    margin-bottom: 32px;
    padding-top: 20px;
    text-align: center;
  }
  .content {
    padding: 40px;
    padding-top: 0;
    .complain-form {
      ::v-deep {
        .el-form-item__label {
          font-size: 18px;
          font-weight: 600;
          color: #262626;
          line-height: 24px;
          margin-bottom: 20px;
          padding: 0;
        }
        .el-radio {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 136px;
          height: 32px;
          background: #f7f8f9;
          border-radius: 4px;
          font-size: 14px;
          margin-right: 20px;
          margin-bottom: 20px;
          .el-radio__label {
            color: #313131;
          }
          &.is-checked {
            background: #4b8fff;
            color: #fff;
            .el-radio__label {
              color: #fff;
            }
          }
          .el-radio__input {
            display: none;
          }
        }
      }
    }
    .pic-info {
      position: relative;
      .tip-span {
        font-size: 14px;
        font-weight: 400;
        color: #919191;
        line-height: 24px;
        position: absolute;
        top: -43px;
        left: 130px;
      }
    }
  }
  .submit-btn {
    width: 300px;
    height: 40px;
    background: #4b8fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 40px;
  }
  .no-choose {
    .submit-btn {
      opacity: 0.6;
    }
  }
}
@media only screen and (max-height: 700px) {
  .complain-dailog-container {
    transform: scale(0.7);
  }
}
