





































































































































































































































































::v-deep {
  .choose-question {
    margin-top: 10vh !important;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .choose-question-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 32px 0;
      border-bottom: 1px solid #d8d8d8;
      .back-ctx {
        display: flex;
        align-items: center;
        color: #595959;
        font-size: 16px;
        margin-top: 5px;
        cursor: pointer;
      }
      .search-tabs {
        .el-tabs__item {
          height: 28px;
          line-height: 28px;
          margin-bottom: 15px;
          font-size: 16px;
          color: #313131;
        }
        .is-active {
          color: #4b8fff;
        }
        .el-tabs__active-bar {
          height: 4px;
          border-radius: 2px;
          background: #4b8fff;
        }
        .el-tabs__nav-wrap::after {
          height: 1px;
          background-color: #d8d8d8;
        }
        .el-tabs__header {
          margin-bottom: 0px !important;
        }
      }
      .submit-btn {
        width: 91px;
        height: 32px;
        border-radius: 4px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        &.is-disabled {
          background: rgba(75, 143, 255, 0.39) !important;
          border: none !important;
        }
      }
    }
    .search-from {
      padding: 20px 32px;
      display: flex;
      align-items: center;
      .search-select {
        .el-input__inner {
          width: 90px;
          font-size: 14px;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
        }
      }
      .el-select .el-input__inner:focus,
      .el-select .el-input.is-focus .el-input__inner {
        border: 1px solid #4b8fff !important;
      }
      .search-input {
        .el-input__inner {
          width: 100%;
          font-size: 14px;
          border-radius: 0px 4px 4px 0px;
        }
      }
      .search-btn {
        flex-shrink: 0;
        width: 80px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #4b8fff;
        color: #4b8fff;
        font-size: 16px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .reset-btn {
        flex-shrink: 0;
        width: 80px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        color: #cccccc;
        font-size: 16px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .search-list {
      height: 568px;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 32px;
      .search-list-item {
        display: flex;
        border-bottom: 1px solid #efefef;
        margin-bottom: 16px;
        cursor: pointer;
        .active-status {
          flex-shrink: 0;
          margin-right: 12px;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .q-content {
          flex: 1;
          width: 0;
          .q-stem {
            font-size: 16px;
            color: #313131;
            line-height: 24px;
            margin-bottom: 8px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // line-clamp: 2;
            // -webkit-box-orient: vertical;
            .q-type {
              color: #4b8fff;
            }
            .is-del {
              text-decoration: line-through;
            }
          }
          .q-id {
            color: #919191;
            font-size: 14px;
            margin-bottom: 16px;
            text-align: right;
          }
        }
      }
      .no-bottom {
        border-bottom: none;
        margin-bottom: 0px;
      }
      .no-list-ctx {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        color: #919191;
        line-height: 20px;
        padding-top: 150px;
        .no-list-icon {
          width: 118px;
          height: 92px;
          margin-bottom: 30px;
        }
      }
    }
    .search-footer {
      flex-shrink: 0;
      .el-pagination {
        padding: 20px 0 !important;
        margin: 0 !important;
        .active {
          background-color: #4B8FFF !important;
        }
      }
    }
  }
}
