






























































































































































































































































































































.circle-detail-container {
  min-height: 350px;
  .content {
    display: grid;
    grid-template-columns: 74px 781px 300px;
    grid-auto-rows: min-content;
    grid-column-gap: 10px;
    margin-bottom: 48px;
  }

  .detail-content {
    width: 100%;
    .main-detial {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      min-height: 260px;
    }
    .prev-next-con {
      height: 98px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #efefef;
      padding: 0 24px;
      .prev-item,
      .next-item {
        display: flex;
        flex-direction: column;
        color: #919191;
        font-size: 14px;
        font-weight: 400;
        .label-span {
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 12px;
          text-align: left;
          padding: 0;
        }
        .d-title {
          width: 320px;
          height: 20px;
          color: #363636;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          &:hover {
            cursor: pointer;
            color: #4b8fff;
          }
        }
      }
      .next-item {
        .label-span,
        .d-title {
          text-align: right;
        }
      }
    }
    .comment-container {
      padding: 24px;
      margin-top: 10px;
      background-color: #ffffff;
      border-radius: 8px;
    }
  }

  .left-operate {
    padding-top: 24px;
    padding-right: 14px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    .o-btn {
      width: 60px;
      height: 60px;
      background: #ffffff;
      border-radius: 30px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #616161;

      line-height: 17px;
      cursor: pointer;
      .icon {
        height: 21px;
        width: 20px;
        margin-bottom: 4px;
      }
      &.more {
        .more-icon {
          width: 32px;
          height: 33px;
          margin-bottom: 0;
        }
      }
      &:hover {
        color: #4b8fff;
      }
    }
    .like-btn {
      &.is-active {
        color: #4b8fff;
      }
      &.is-disabled {
        pointer-events: none;
      }
    }
  }
  // .side-right {
  //   position: -webkit-sticky;
  //   position: sticky;
  //   top: 0;
  // }
  &.is-vialot {
    .like-btn,
    .comment-btn,
    .share-btn {
      opacity: 0.7;
      pointer-events: none;
    }
  }
}
.error-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 898px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #595959;
  line-height: 22px;
  text-align: center;
  margin-bottom: 24px;
  .no-img {
    width: 166px;
    height: 111px;
    margin-bottom: 24px;
  }
  .go-circle-home {
    width: 300px;
    height: 40px;
    background: #4b8fff;
    border-radius: 20px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
  }
}
