


























.circle-comman-dialog {
  background-color: transparent !important;
  width: auto !important;
  position: absolute !important;
  margin: 0 !important;
  box-shadow: none !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .el-dialog__header {
    display: none;
  }
  .el-dialog__footer {
    display: none;
  }
  .el-dialog__body {
    background-color: transparent;
    padding: 0;
  }
}
