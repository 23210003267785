































































::v-deep
.reset-password-dialog {
  /*padding: 20px;*/
  /*.el-dialog__header {*/
  /*  display: none;*/
  /*}*/
  width: 400px;
  @media (max-width: 500px) {
    width: 310px;
  }
  .el-dialog__body {
    padding: 16px 56px 10px;
    @media (max-width: 500px) {
      padding: 16px 16px 10px;
    }
  }
}
