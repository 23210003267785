
















































.errata-progress-circle {
  display: flex;
  align-items: center;
  .read-circle-ctx {
    width: 16px;
    height: 16px;
    overflow: hidden;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .read-circle {
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep {
      // .el-progress-circle {
      //   width: 12px !important;
      //   height: 12px !important;
      // }
      .el-progress__text {
        display: none;
      }
      svg path:first-child {
        stroke: #4b8fff;
      }
    }
  }
  .is-active {
    ::v-deep {
      svg path:first-child {
        stroke: #ffffff !important;
      }
    }
  }
  .progress-over-icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
}
