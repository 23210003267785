






























@font-face {
  font-family: 'JinBuTi';
  src: url('../../../assets/fonts/DingTalk\ JinBuTi.ttf');
  font-weight: normal;
  font-style: normal;
}

.out-con {
  overflow: hidden;
  .ani-content {
    display: flex;
    font-size: 20px;
    line-height: 24px;
    font-family: JinBuTi;
    .ani-item {
      transition: opacity 0.3s, transform 0.3s;
    }
  }

  .vdt-slide-y-enter,
  .vdt-slide-y-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
}
