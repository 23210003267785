

























































































.is-hidden {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
.password-login {
  width: 252px;
  margin: 20px auto 20px;
  position: relative;

  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    color: #363636;
    height: 31px;
    padding: 7px 7px 7px 0;
    outline-style: none;
    line-height: normal;
    border-radius: 0;
    font-size: 12px;
  }
  ::v-deep .el-form-item__content {
    line-height: 32px;
  }
  ::v-deep .el-form-item__error {
    text-align: left;
  }
  ::v-deep .el-link {
    font-weight: normal;
  }
  .forget-password {
    color: #bbbbbb;
    font-size: 12px;
    position: absolute;
    right: -6px;
    bottom: -4px;
  }
}
.full-width-btn {
  width: 100%;
  background: #4b8fff;
  height: 34px;
  line-height: 34px;
  padding: 0;
  border: none;
  margin: 10px 0 20px 0;
  &:disabled {
    background: #cccccc;
    border-color: #cccccc;
    color: #ffffff;
  }
}
