






































































.module-operating {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff;
  max-height: 120px;
  min-height: 120px;
  position: relative;
  img {
    width: 100%;
    cursor: pointer;
  }
}
.swiper-pagination {
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
}
.module-operating ::v-deep .swiper-pagination-bullet {
  margin: 0 6px;
  width: 8px;
  height: 8px;
  background: transparent;
  opacity: 1;
  border: 1px solid #f7f8f9;
}
.module-operating ::v-deep .swiper-pagination-bullet-active {
  width: 8px;
  background: #f7f8f9;
  border-radius: 50%;
}
