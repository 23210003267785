


































































































































































.ask-search {
  border-radius: 4px;
  background: #ffffff;
  padding: 48px 48px 0;
  margin-bottom: 60px;
  &.from-search {
    padding: 25px 0 0;
  }
  .search-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    ::v-deep {
      .el-input__inner {
        border-right: none;
        border-radius: 4px 0px 0px 4px;
        color: #5a5a5a;
        font-size: 16px;
      }
    }
    .search-btn {
      width: 88px;
      height: 40px;
      background: #4b8fff;
      border-radius: 0px 4px 4px 0px;
      color: #ffffff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .search-tabs {
    margin-bottom: 40px;
    border-bottom: 1px solid #efefef;
    display: flex;
    .search-tabs-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: bolder;
      .search-tabs-title {
        color: #4b8fff;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 12px;
      }
      .search-tabs-line {
        width: 60px;
        height: 4px;
        background: #4b8fff;
        border-radius: 2px;
      }
    }
    ::v-deep {
      .el-tabs__item {
        height: 28px;
        line-height: 28px;
        margin-bottom: 17px;
        font-size: 20px;
        color: #313131;
      }
      .is-active {
        color: #4b8fff;
      }
      .el-tabs__active-bar {
        height: 4px;
        border-radius: 2px;
        background: #4b8fff;
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #efefef;
      }
    }
  }
  .list-container {
    min-height: 700px;
    position: relative;
    .main-loading-box {
    }
  }
  .ask-index-list {
    .ask-index-item {
      background: #ffffff;
      border-radius: 4px;
      margin-bottom: 10px;
      .middle-line {
        width: calc(100% + 96px);
        height: 10px;
        background: #f6f9fb;
        margin: 20px -48px;
      }
    }
    .search-footer {
      flex-shrink: 0;
      padding-bottom: 1px;
      .el-pagination {
        margin: 46px 0 20px !important;
      }
    }
  }
  .no-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 160px;
    padding-bottom: 316px;
    .no-result-icon {
      width: 113px;
      height: 101px;
      margin-bottom: 32px;
    }
    .no-result-text {
      font-size: 16px;
      color: #595959;
      margin-bottom: 40px;
      text-align: center;
    }
    .to-ask-btn {
      width: 300px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 16px;
      background: #4b8fff;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}
