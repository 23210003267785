




























.success-reserve-dialog {
  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      color: #363636;
      line-height: 28px;
      .success-icon {
        margin-right: 6px;
      }
    }
    .qrcode-img {
      width: 198px;
      height: 198px;
      margin: 8px 0;
    }
    .tips {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #363636;
      line-height: 20px;
    }
  }
}
