









































































































































































































































::v-deep {
  .ask-answer-box:nth-last-child(1) {
    border-bottom: none;
  }
}
.ask-details {
  display: flex;
  margin-bottom: 80px;
  .ask-details-left {
    flex: 1;
    width: 0;
    padding-right: 20px;
    .ask-details-left-container {
      position: relative;
      .ask-detail-top {
        padding: 16px 32px 20px;
        background: #ffffff;
        border-radius: 4px;
      }
      .no-login-answer {
        border-radius: 4px;
        background: #ffffff;
        position: relative;
        .no-login-img {
          width: 100%;
        }
        .no-login-ctx {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 30px;
          .no-login-tips {
            color: #363636;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 36px;
            font-weight: bolder;
          }
          .login-btn {
            width: 300px;
            height: 40px;
            color: #ffffff;
            font-size: 16px;
            background: #4b8fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            cursor: pointer;
          }
        }
      }
      .ask-detail-answer {
        padding: 20px 32px 0px;
        background: #ffffff;
        border-radius: 4px;
        min-height: 591px;
        .ask-answer-title {
          color: #313131;
          font-size: 18px;
          font-weight: bolder;
          margin-bottom: 20px;
          line-height: 25px;
        }
      }
      .upgrade-ctx {
        background: #ffffff;
        border-radius: 4px 4px 0 0;
        margin-bottom: -2px;
        .upgrade-ctx-title {
          padding: 20px 32px 16px;
          border-bottom: 1px solid #efefef;
          font-size: 16px;
          font-weight: bolder;
          color: #313131;
        }
        .service-list {
          padding: 16px 32px;
          .service-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .service-title {
              font-size: 14px;
              color: #313131;
              display: flex;
              align-items: center;
              font-weight: bolder;
              .discount-icon {
                padding: 0 8px;
                height: 18px;
                background: linear-gradient(140deg, #ff9b9b 0%, #ff6262 100%);
                border-radius: 100px 100px 100px 0px;
                color: #ffffff;
                font-size: 12px;
                margin-left: 4px;
              }
            }
            .service-btn {
              font-size: 14px;
              color: #4b8fff;
              display: flex;
              align-items: center;
              cursor: pointer;
              img {
                width: 9px;
                height: 10px;
                margin-left: 4px;
              }
            }
          }
        }
      }
      .no-answer-ctx {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 140px;
        background: #ffffff;
        border-radius: 4px;
        .no-answer-icon {
          width: 112px;
          height: 113px;
          margin-bottom: 32px;
        }
        .no-answer-text {
          margin-bottom: 30px;
          .text-style {
            font-size: 14px;
            color: #595959;
            line-height: 20px;
            text-align: center;
            margin-bottom: 8px;
          }
        }
        .to-ask-btn {
          width: 300px;
          height: 40px;
          color: #ffffff;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          background: #4b8fff;
          margin-bottom: 95px;
          cursor: pointer;
        }
      }
    }
  }
  .ask-details-right {
    flex-shrink: 0;
    width: 300px;
    margin-bottom: 32px;
  }
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
