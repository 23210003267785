




































































































































































































@font-face {
  font-family: 'AlibabaPuHuiTi';
  src: url('../../../../assets/fonts/AlibabaPuHuiTi-2-105-Heavy.otf');
  font-weight: normal;
  font-style: normal;
}

.act-page-container {
  ::v-deep {
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border: 1px solid #f7f8f9;
      opacity: 1;
      background: transparent;
      &.swiper-pagination-bullet-active {
        background: #f7f8f9;
        border: none;
      }
    }
  }
  background: linear-gradient(180deg, #be1020 0%, #990817 100%), linear-gradient(180deg, #be1020 0%, #990817 100%);

  .act-page-item {
    width: 23.4375rem;
    margin: 0 auto;
    margin-bottom: 0.4688rem;
  }

  .aside-zixun-con {
    cursor: pointer;
    height: 0.8594rem;
    font-size: 0.3125rem;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.8594rem;

    .menu-zixun-icon {
      display: inline-block;
      width: 0.4102rem;
      height: 0.4297rem;
    }

    .zixun-img {
      width: 114%;
      margin-left: -7%;
    }

    padding: 0.1953rem auto;
  }

  .aside-tuijian-icon {
    width: 3.125rem;
    height: 2.8516rem;
    position: absolute;
    top: -2.6367rem;
    left: -0.4297rem;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .item-title-con {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .item-title-content {
      display: flex;
      align-items: center;
      justify-content: center;

      .item-title {
        background: linear-gradient(180deg, #ffffff 0%, #ffeca4 100%);
        background-clip: text;
        color: transparent;
        font-size: 0.9375rem;
        font-weight: bold;
        font-family: AlibabaPuHuiTi;
        margin: 0 0.3125rem;
      }

      .left-icon {
        width: 1.1719rem;
      }

      .right-icon {
        width: 1.1719rem;
      }
    }

    .item-s-title {
      font-size: 0.5469rem;
      font-weight: 400;
      color: #ffdeb9;
      line-height: 0.7813rem;
      margin-top: 0.390625rem !important;
    }
  }

  .tip-content {
    font-weight: 400;
    font-size: 0.2734rem;
    color: #ffdeb9;
    padding-bottom: 1.1719rem;
    margin-bottom: 0;

    .tip-con {
      display: flex;
      justify-content: center;
      align-items: center;

      .tip-btn {
        cursor: pointer;
        margin-top: 0.3125rem;
        font-size: 0.4297rem;
      }
    }
  }

  .bottom-btns {
    width: 23.4375rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 1.7578rem;
    background: linear-gradient(180deg, #fff9e4 0%, #ffd0af 100%);
    box-shadow: 0rem 0rem 0.293rem 0rem rgba(237, 40, 71, 0.41);
    border-radius: 0.5859rem 0.5859rem 0rem 0rem;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    z-index: 2;

    .bottom-btn {
      width: 7.1289rem;
      height: 1.1719rem;
      background: #ffc75b;
      box-shadow: 0rem 0rem 0.1758rem 0rem rgba(255, 255, 255, 0.5), inset -0.0586rem -0.2148rem 0.293rem 0rem rgba(255, 0, 0, 0.45), inset 0.1563rem 0.1172rem 0.4492rem 0rem #fff5e0,
        inset 0rem -0.1172rem 0.0977rem 0rem rgba(255, 181, 239, 0.38);
      border-radius: 0.5859rem;
      font-weight: 600;
      font-size: 0.4688rem;
      color: #511700;
      line-height: 1.1719rem;
      text-align: center;
      cursor: pointer;
    }
    .bottom-btn-red {
      width: 7.1289rem;
      height: 1.1719rem;
      background: #fd343f;
      box-shadow: 0 0 0.1758rem 0 hsla(0, 0%, 100%, 0.5), inset -0.0586rem -0.2148rem 0.293rem 0 #ff82db, inset 0.1563rem 0.1172rem 0.4492rem 0 #fff5b9,
        inset 0 -0.1172rem 0.0977rem 0 rgba(255, 181, 239, 0.38);
      border-radius: 0.5859rem;
      border: 0.0391rem solid #fbffde;
      color: #fff;
      font-weight: 600;
      font-size: 0.4688rem;
      color: #ffffff;
      line-height: 1.1719rem;
      text-align: center;
      cursor: pointer;
    }
  }

  .mgb60 {
    margin-bottom: 1.1719rem !important;
  }
}
.rule-con {
  width: 13.7695rem;
  padding: 0 0.7813rem 0.7813rem;
  white-space: pre-line;
}
