























































































































































































































































































::v-deep {
  .choose-book {
    margin-top: 10vh !important;
    border-radius: 16px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      display: flex;
      flex-direction: column;
      height: 706px;
      padding: 0;
      position: relative;
    }
  }
}
.choose-book-header {
  height: 105px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  .header-btn-list {
    display: flex;
    align-items: center;
    .header-btn-1 {
      height: 30px;
      padding: 0 30px;
      font-size: 16px;
      color: #4b8fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      border: 1px solid #4b8fff;
      margin-right: 16px;
    }
    .header-btn-2 {
      height: 30px;
      padding: 0 30px;
      font-size: 16px;
      background-color: #4b8fff;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      border: 1px solid #4b8fff;
    }
    .is-disabled {
      background-color: rgba(75, 143, 255, 0.4) !important;
      cursor: default !important;
      background-clip: padding-box;
      border: 1px solid rgba(75, 143, 255, 0.4) !important;
      pointer-events: none;
    }
  }
}
.search-box {
  padding: 24px 40px 0px;
}
.book-list {
  height: 0;
  flex: 1;
  position: relative;
  /*修改滚动条样式*/
  // ::-webkit-scrollbar {
  //   width: 4px;
  //   height: 4px;
  // }
  // ::-webkit-scrollbar-track {
  //   border-radius: 2px;
  //   background: #efefef !important;
  //   margin-right: 4px !important;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background: #c7c7c7;
  //   border-radius: 2px;
  // }
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #c7c7c7;
  // }
  // ::-webkit-scrollbar-corner {
  //   background: transparent;
  // }
  .book-list-scroll {
    padding: 0 40px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .book-list-ctx {
      padding-bottom: 24px;
      .book-item {
        margin-bottom: 20px;
      }
      .loading-more {
        display: flex;
        justify-content: center;
        padding: 24px 0;
        color: #919191;
        font-size: 12px;
      }
    }
  }
  .book-list-scroll {
  }
}
.list-no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  .no-result-icon {
    width: 95px;
    margin-bottom: 24px;
  }
  .no-result-text {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
  }
}
