




























































.swiper-con {
  height: 100%;
  border-radius: 8px;
}
.img-responsive {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-carousel {
  ::v-deep {
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border: 1px solid #f7f8f9;
      opacity: 1;
      background: transparent;
      &.swiper-pagination-bullet-active {
        background: #f7f8f9;
        border: none;
      }
    }
  }
}
