

























































































































































































































































































































































































































































































































































































































































































































































.circle-link-popver {
  .qr-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #313131;
      line-height: 20px;
      text-align: center;
      margin-bottom: 8px;
      .line1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;
        .wechat-img {
          width: 20px;
          height: 21px;
          margin-right: 4px;
        }
      }
    }
    .qr-img {
      width: 120px;
      height: 120px;
    }
  }
}
.dynamic-item-container {
  table {
    border: 1px solid #bbb;
    border-collapse: collapse;
    width: 100% !important;
    td,
    th {
      border: 1px solid #bbb;
      padding: 0 4px;
    }
    border-spacing: 0;
    p {
      margin: 2px 0;
    }
  }
}
