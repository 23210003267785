















.mobile-register {
  padding: 8px 43px;

  p {
    font-size: 14px;
    color: #5A5A5A;
    text-align: left;
    line-height: 20px;

    .strong {
      color: #363636;
      font-weight: bold;
    }
  }

  img {
    margin: 26px auto 0;
    width: 80px;
    display: block;
  }

  .download-btn {
    display: inline-block;
    font-size: 14px;
    color: #FFFFFF;
    background: #01A0EA;
    height: 38px;
    line-height: 38px;
    padding: 0 28px;
    border-radius: 4px;
    margin: 16px 0 20px;
  }
}
