


















































































































































































.send-smscode-btn {
  border: none !important;
}
.forget-con {
  margin-left: 320px;
  margin-top: -16px;
  margin-bottom: 8px;
}
.is-hidden {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
.phone-number-wrap {
  font-size: 14px;

  a {
    display: inline-block;
    margin-left: 10px;
  }
}
