

























































































































































.raffle-activity {
  min-height: 19.6456rem;
  .activity-end {
    height: 19.6456rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .end-icon {
      width: 99px;
      height: 104px;
      margin-bottom: 24px;
    }
    .end-tips-text {
      font-size: 16px;
      color: #5a5a5a;
      margin-bottom: 40px;
    }
    .end-btn {
      width: 300px;
      height: 40px;
      font-size: 16px;
      color: #ffffff;
      background: #4b8fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
  }
  .activity-main {
    background: #990817;
    .activity-container {
      font-size: 16px;
      padding: 1.58rem 0px;
      width: 23.4375rem;
      height: 100%;
      margin: 0 auto;
      .activity-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0.7283rem;
        .title-icon {
          flex-shrink: 0;
          width: 1.4843rem;
          height: 1.4843rem;
        }
        .title-text {
          margin: 0 0.1388rem;
          background: linear-gradient(180deg, #ffffff 0%, #ffeca4 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: rgba(0, 0, 0, 0);
          font-size: 0.9375rem;
          font-weight: bold;
          font-family: AlibabaPuHuiTi;
        }
      }
    }
  }
}
.marketing-page {
  // min-height: 19.6456rem;
  .module-container {
    font-size: 16px;
    // padding: 1.58rem 0px;
    padding: 0.5rem 0;
    width: 23.4375rem;
    height: 100%;
    margin: 0 auto;
    .lottery-container {
      margin-bottom: 1.5rem;
    }
  }
}
