
























































































































































































































.grade-rank-con-new {
  margin-bottom: 1.5625rem;

  .rank-container {
    .title {
      display: flex;
      align-items: flex-end;

      .left {
        flex: 1;
      }

      .right {
        width: 8.8672rem;
      }

      .radius-title {
        box-sizing: border-box;
        display: inline-block;
        padding: 0.332rem 0.7813rem;
        background: linear-gradient(180deg, #fff2d1 0%, #ffcf88 100%);
        border-radius: 0.4102rem 0.4102rem 0 0;
        border: 0.0195rem solid #ffe7c2;
        font-size: 0.625rem;
        font-weight: 600;
        color: #511700;
        line-height: 0.8789rem;
        text-shadow: 0 0.0195rem 0.0391rem rgba(232, 117, 50, 0.58);
        border-bottom: none;

        &.s-radius-title {
          padding: 0.1367rem 0.6055rem;
          line-height: 0.7227rem;
          font-size: 0.5078rem;
        }
      }
    }

    .rank-content {
      background: linear-gradient(135deg, #ff8b38 0%, #ff5c41 23%, #ff3850 37%, #ff3750 49%, #ff5770 100%);
      border-radius: 0 0.1758rem 0.1758rem 0.1758rem;
      display: flex;

      .left {
        flex: 1;
        width: 15.4883rem;
        padding: 0.6641rem 0.2344rem 0 0.6641rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .sub-header {
          width: 100%;
          height: 0.7422rem;
          background: linear-gradient(135deg, #ff5770 0%, #ff3750 23%, #ff3850 37%, #ff5c41 49%, #ff8b38 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.2734rem;
          font-weight: 500;
          color: #ffffff;

          span {
            position: relative;

            &::before,
            &::after {
              content: '';
              display: inline-block;
              background: url('../../../assets/images/home/icon-grade-module-fireworks.png');
              width: 0.4297rem;
              height: 0.4297rem;
              background-size: contain;
              position: absolute;
              top: -0.0293rem;
            }

            &::before {
              left: -0.6641rem;
            }

            &::after {
              right: -0.6641rem;
            }
          }
        }

        .bottom-tips {
          padding: 0.2734rem 0 0.1563rem;
          display: block;
          text-align: right;
          font-size: 0.2344rem;
          font-weight: 500;
          color: #ffdfaa;
          line-height: 0.332rem;
          margin-bottom: 0.4883rem;
        }

        .table {
          width: 100%;
          border-radius: 0.1953rem;
          border: 0.0391rem solid #ffe8ae;
          max-height: 11.875rem;
          margin-bottom: 0;
          overflow: hidden;
          position: relative;
          background-color: #fff;

          .tr {
            display: flex;
            justify-content: center;
          }

          .td-1 {
            width: 4.4922rem;
          }

          .td-2 {
            width: 2.1484rem;
          }

          .td-3 {
            width: 2.7344rem;
          }

          .td-4 {
            width: 1.5625rem;
          }

          .td-5 {
            width: 1.1719rem;
          }

          .header {
            background: linear-gradient(180deg, #fff2d1 0%, #ffcf88 100%);
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 2;

            .td {
              height: 0.7031rem;
              font-size: 0.2734rem;
              font-weight: 600;
              color: #602005;
              line-height: 0.7031rem;
              letter-spacing: 0.0977rem;
              line-height: 0.7031rem;
              text-align: left;
            }
          }

          .body {
            background: #fff;
            height: 11.1328rem;
            margin-top: 0.7031rem;

            .swiper-slide {
              border-bottom: 0.0195rem solid #ff577045;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              &.no-border {
                border: none;
              }
            }

            .tr {
              // background: #fff;
              line-height: 0.7422rem;
              height: 0.7422rem;
              font-size: 0.2734rem;
              color: #333333;

              .td {
                text-align: left;
              }

              .td-1 {
                font-weight: 600;
                position: relative;

                .username {
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  word-wrap: normal;
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  .name {
                    margin-right: 0.1563rem;
                    width: 0.5859rem;
                  }
                }

                .tag {
                  height: 0.3906rem;
                  line-height: 0.3906rem;
                  background: #ffead6;
                  border-radius: 0.1953rem;
                  font-size: 0.2344rem;
                  color: #e83d2c;
                  display: inline-block;
                  text-align: center;
                  margin-right: 0.0781rem;
                  padding: 0 0.1758rem;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }
              }

              .td-4 {
                color: #e83d2c;
              }

              .td-5 {
                font-size: 0.2539rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }

              .article-link {
                margin-left: 0.0977rem;
                color: #333333;
              }

              .td-1::before {
                content: '';
                display: inline-block;
                height: 0.6445rem;
                width: 0.6445rem;
                position: absolute;
                left: -0.6445rem;
                top: 0.0781rem;
                background-size: contain;
              }

              &.tr-1 .td-1::before {
                background-image: url('../../../assets/images/home/icon-grade-module-first.png');
              }

              &.tr-2 .td-1::before {
                background-image: url('../../../assets/images/home/icon-grade-module-second.png');
              }

              &.tr-3 .td-1::before {
                background-image: url('../../../assets/images/home/icon-grade-module-third.png');
              }
            }
          }
        }

        .btn-list {
          margin-bottom: 0.6641rem;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .btn-line {
            width: 6.25rem;
            height: 0.8984rem;
            border-radius: 0.4492rem;
            border: 0.0391rem solid #ffdeb9;
            font-size: 0.3516rem;
            font-weight: 600;
            color: #ffdeb9;
          }

          .btn-shi {
            width: 6.25rem;
            height: 0.8984rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.332rem;
            font-weight: 600;
            color: #511700;
            cursor: pointer;
            background: #ffc75b;
            box-shadow: 0rem 0rem 0.1367rem 0rem rgba(255, 255, 255, 0.5), inset -0.0391rem -0.1758rem 0.2344rem 0rem rgba(255, 0, 0, 0.45), inset 0.1172rem 0.0977rem 0.3516rem 0rem #fff5e0,
              inset 0rem -0.0977rem 0.0781rem 0rem rgba(255, 181, 239, 0.38);
            border-radius: 0.4492rem;
            border: 0.0195rem solid #fbffde;
            position: relative;
            margin-left: 0.7813rem;

            .btn-bli {
              width: 100%;
              height: 100%;
              position: absolute;
              border-radius: 0.4492rem;
              overflow: hidden;

              &::before {
                content: '';
                width: 0.3516rem;
                height: 100%;
                top: 0;
                left: -0.293rem;
                position: absolute;
                z-index: 1;
                overflow: hidden;
                background: linear-gradient(270deg, rgba(255, 245, 174, 0) 0%, #fffad0 32%, #fffad0 55%, rgba(255, 247, 152, 0) 100%);
                opacity: 0.5;

                -webkit-transform: skewX(-25deg);
                -moz-transform: skewX(-25deg);
                -webkit-animation: funaniBlink 1.5s infinite;
                -moz-animation: funaniBlink 1.5s infinite;
                -o-animation: funaniBlink 1.5s infinite;
                animation: funaniBlink 1.5s infinite;
              }
            }
          }
        }
      }

      .right {
        width: 8.9258rem;
        padding: 0.6445rem 0.6055rem 0.6445rem 0;

        .turn-btn {
          width: 100%;
          height: 0.4297rem;
          background: linear-gradient(180deg, #fff2d1 0%, #ffcf88 100%);
          box-shadow: 0 0.0391rem 0.0781rem 0 rgba(188, 9, 11, 0.39);
          border-radius: 0.0781rem;
          border: 0.0195rem solid #ffe7c2;
          /*no*/
          position: relative;
          outline: none;

          &::before {
            content: '';
            width: 0.3711rem;
            height: 0.2148rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../../../assets/images/home/icon-grade-module-arrow.png');
            position: absolute;
            left: 50%;
            margin-left: -0.1855rem;
            top: 0.0977rem;
          }

          &.disabled {
            background: linear-gradient(180deg, #fff2d1 0%, #ffcf88 100%);
            box-shadow: 0px 2.0019px 3.9987px 0px rgba(188, 9, 11, 0.39);
            border-radius: 3.9987px;
            opacity: 0.7;
            pointer-events: none;

            &::before {
              background-image: url('../../../assets/images/home/icon-grade-module-arrow-disabled.png');
            }
          }
        }

        .prev-btn::before {
          top: 0.0586rem;
          transform: rotateZ(-180deg);
        }
      }
    }

    .grad-rank-new-lessons {
      .live-lesson-con {
        width: 100%;
        height: 12.7734rem;
        overflow: hidden;

        &.has-margin {
          margin: 0.2734rem 0;
        }

      }
    }
  }
}

@keyframes funaniBlink {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 110%;
  }
}

@-webkit-keyframes funaniBlink {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 110%;
  }
}
