








































































.home-right-menu {
  .el-scrollbar__bar {
    display: none;
  }
}
