

























.ai-adress-wrap {
  width: 420px;
}
.ai-adress {
  position: relative;
  width: 420px;
  height: 111px;
  background: #f3f3f3;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 0px 0px 32px 12px;
  ::v-deep {
    .el-textarea__inner {
      background: rgb(243, 243, 243);
      height: 74px;
      padding: 0;
      border: none;
      padding-top: 5px;
      padding-right: 8px;
    }
  }
  .ai-btn {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 44px;
    height: 24px;
    padding: 0;
  }
}
.ai-adress-line {
  width: 652px;
  height: 1px;
  border: 1px dashed #e8e8e8;
  transform: translateX(-126px);
  margin-top: 24px;
}
