


















































































































.upload-ctx-con {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  border: 1px dashed #bcbcbc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #595959;
  .icon {
    font-size: 31px;
    margin-bottom: 8px;
  }
  .add-text {
    font-size: 14px;
    line-height: 20px;
  }
}
