


























































































































































// px2rem -u 51.2 home.css
@import url('./home.scss');
// @import url('./home.css');
// 不要删除下面的css,如果在下面新加了css就可以删除了 因为@import在最后一行会导致页面后端渲染失败
div {
  display: block;
}
