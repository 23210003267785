








































































































































.ask-class-filter {
  .project-class {
    display: flex;
    .label-name {
      flex-shrink: 0;
      .label-name-box {
        padding: 4px 12px 4px 0px;
        color: #313131;
        font-size: 16px;
      }
    }
    .list-ctx {
      flex: 1;
      width: 0;
      display: flex;
      flex-wrap: wrap;
      margin-right: -8px;
      .list-item {
        font-size: 14px;
        padding: 6px 12px;
        border-radius: 4px;
        margin-right: 8px;
        margin-bottom: 14px;
        color: #595959;
        cursor: pointer;
      }
      .is-active {
        color: #ffffff;
        background: #4b8fff;
      }
    }
  }
  .middle-border {
    border-bottom: 1px dashed #cccccc;
    margin-bottom: 20px;
  }
  .subject-class {
    display: flex;
    .label-name {
      flex-shrink: 0;
      .label-name-box {
        padding: 4px 12px 4px 0px;
        color: #313131;
        font-size: 16px;
      }
    }
    .list-ctx {
      flex: 1;
      width: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .list-item {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        .list-item-icon {
          width: 14px;
          height: 14px;
        }
        .list-item-text {
          color: #595959;
          font-size: 14px;
          line-height: 20px;
          margin-left: 4px;
        }
      }
    }
  }
  .create-time-class {
    display: flex;
    margin-bottom: 20px;
    .label-name {
      flex-shrink: 0;
      .label-name-box {
        padding: 4px 12px 4px 0px;
        color: #313131;
        font-size: 16px;
      }
    }
    .list-ctx {
      flex: 1;
      width: 0;
      display: flex;
      align-items: center;
      .search-btn {
        width: 80px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        background: #4b8fff;
        margin-left: 8px;
        cursor: pointer;
      }
      .reset-btn {
        width: 80px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        background: #cccccc;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
::v-deep {
  .el-radio-group {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .el-radio {
      height: 32px;
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 14px;
    }
    .el-radio__label {
      padding-bottom: 2px;
      color: #595959;
      font-weight: 400;
    }
  }
}
