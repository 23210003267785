
































































































































































































































































































































































































































.tabs-chapter-box {
  height: 100%;
  position: relative;
  padding-top: 10px;
  .is-opacity {
    opacity: 0.4 !important;
  }
  .chapter-list {
    .chapter-item {
      .chapter-parent {
        display: flex;
        padding: 0 34px;
        border-left: 6px solid #ffffff;
        cursor: pointer;
        &:hover {
          background-clip: padding-box;
          background-color: rgba(75, 143, 255, 0.1);
          border-left: 6px solid rgba(75, 143, 255, 0.1);
        }
      }
      .chapter-childs {
        .chapter-child {
          display: flex;
          padding: 0 34px;
          border-left: 6px solid #ffffff;
          cursor: pointer;
          &:hover {
            background-clip: padding-box;
            background-color: rgba(75, 143, 255, 0.1);
            border-left: 6px solid rgba(75, 143, 255, 0.1);
          }
        }
        .hide-hover {
          cursor: default !important;
          &:hover {
            background-color: transparent !important;
            border-left: 6px solid #ffffff !important;
          }
        }
      }
    }
  }
}
.is-read-chapter {
  color: #8e8e93;
  .f-w-bolder {
    color: #8e8e93;
  }
}
.active-item {
  background-clip: padding-box;
  background-color: rgba(75, 143, 255, 0.1);
  border-left: 6px solid #4b8fff !important;
  .tree-right {
    color: #4b8fff;
    .add-info {
      color: #4b8fff;
    }
  }
  .f-w-bolder {
    color: #4b8fff;
  }
  .read-end {
    color: #4b8fff;
  }
  .read-doing {
    color: #4b8fff;
  }
}
.tree-left {
  flex-shrink: 0;
  width: 24px;
  position: relative;
  .tree-left-top {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    height: calc(50% - 10px);
    border-right: 1px solid #efefef;
    z-index: 9;
  }
  .tree-left-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: calc(50% - 10px);
    border-right: 1px solid #efefef;
    z-index: 9;
  }
  .tree-edit-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
  }
  .child-top,
  .child-bottom {
    height: calc(50% - 8px);
  }
}
.tree-right {
  padding: 16px 0 16px 8px;
  font-size: 14px;
  line-height: 20px;
}
.f-w-bolder {
  font-weight: bolder;
}
.add-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #5a5a5a;
  margin-top: 5px;
  .status-text {
    margin-right: 32px;
  }
}
.no-result {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #8e8e93;
}
.read-end {
  color: #8e8e93;
}
.read-doing {
  color: #4b8fff;
}
.footer-tools {
  padding: 0 40px;
  .show-is-read {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8e8e93;
    font-size: 14px;
    margin: 16px 0;
    padding-bottom: 36px;
    .show-is-read-icon {
      width: 14px;
      height: 14px;
      display: block;
      margin-left: 4px;
    }
  }
}
.show-is-read {
  display: flex;
  align-items: center;
  color: #8e8e93;
  font-size: 14px;
  cursor: pointer;
  .show-is-read-icon {
    width: 14px;
    height: 14px;
    display: block;
    margin-left: 4px;
  }
}
