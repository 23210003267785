



































































































































































































































































































































































































































































































































































































































































@font-face {
  font-family: 'AlibabaPuHuiTiMedium';
  src: url('../../../../../assets/fonts/AlibabaPuHuiTi-2-65-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

.lotter-prize-diaolog {
  background-color: transparent !important;
  box-shadow: none !important;
  position: absolute !important;
  margin: 0 !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .prize-content {
    width: 12.8906rem;
    height: 12.6367rem;
    background-image: url('https://app-cdn.btclass.cn/new-prefix/turtle/FqTsP8OtcfK6R7aDWX6NPiYst5du.png');
    background-size: cover;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

    .prize-info {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      bottom: 2.5195rem;

      .lotter-title {
        font-size: .3516rem;
        font-weight: 500;
        color: #c40622;
        line-height: .4883rem;
        margin-bottom: .0781rem;
      }

      .tips {
        height: .3906rem;
        font-size: .2734rem;
        font-weight: 400;
        color: #c40622;
        line-height: .3906rem;
        margin-bottom: .1563rem;
      }

      .lotter-img {
        width: 2.5781rem;
        height: 2.5781rem;
        margin-bottom: .1367rem;
      }

      .lottery-btn {
        background-image: url('https://tmp.btclass.cn/yunyin/o_1he2hmhqi935bkl1s1r1kd6n6fa.png');
        width: 5.0781rem;
        height: 1.2891rem;
        background-size: cover;
        font-size: .3906rem;
        font-weight: 600;
        color: #ffffff;
        line-height: 1.2891rem;
        text-align: center;
        cursor: pointer;
      }
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      bottom: .2344rem;
      left: 50%;
      transform: translateX(-50%);

      .close-icon-img {
        width: .625rem;
        height: .625rem;
      }
    }
  }
}
