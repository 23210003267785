



























.ask-create-tips {
  background: linear-gradient(153deg, #F4F6FF 0%, #FEFFFF 34%, #FFFFFF 100%);
  border-radius: 4px;
  padding: 20px 16px;
  .ask-create-tips-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .title-text {
      font-size: 16px;
      font-weight: bolder;
      margin-bottom: 2px;
    }
    img {
      width: 23px;
      height: 24px;
      margin-right: 6px;
    }
  }
  .tips-text {
    color: #595959;
    font-size: 14px;
    line-height: 20px;
  }
}
