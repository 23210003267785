























































































.pc-container {
  padding-bottom: 2.148438rem;

  .title {
    margin-bottom: .78125rem;
  }
}

.swiper-ctx {
  padding: .390625rem 0;
}

.swiper-wrapper {
  .swiper-slide {
    display: flex;

    .swiper-slide-child {
      flex-shrink: 0;
      margin: 0 .205078rem;
      border-radius: .214844rem;
      background: #FFFFFF;
      width: 2.753906rem;
      height: 2.578125rem;
      transition: transform 0.3s ease;
      /* 添加过渡效果 */

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .46875rem .234375rem 0;

        img {
          width: .898438rem;
          height: .898438rem;
        }

        .name {
          padding-top: .234375rem;
          color: #919191;
          font-size: .273438rem;
          line-height: .390625rem;
          text-align: center;
        }
      }

      &:hover {
        .name {
          color: #4b8fff;
        }

        transform: translateY(-0.15625rem);
        box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
        /* 鼠标悬浮时向上移动10px */
      }
    }
  }
}

.swiper-pagination-friend {
  width: 25.390625rem;
  text-align: center;
  margin-top: .78125rem;

  ::v-deep {
    .swiper-pagination-bullet {
      width: .78125rem;
      height: .15625rem;
      margin: 0 .078125rem;
      border-radius: .078125rem;
      color: #CCCCCC;
    }

    .swiper-pagination-bullet-active {
      color: #4B8FFF;
    }
  }
}
