



























































































































































































































.my-ask {
  display: flex;
  .my-ask-left {
    flex: 1;
    width: 0;
    padding-right: 20px;
    .my-ask-left-container {
      margin-bottom: 88px;
      .type-name {
        font-size: 20px;
        color: #313131;
        line-height: 28px;
        padding: 20px 32px 16px;
        font-weight: bolder;
        border-bottom: 1px solid #efefef;
        background: #ffffff;
        border-radius: 4px 4px 0 0;
      }
      .filter-box {
        padding: 20px 32px;
        border-radius: 0 0 4px 4px;
        background: #ffffff;
      }
      .list-container {
        border-radius: 4px;
        // min-height: 600px;
        position: relative;
        .my-ask-list {
          .my-ask-item {
            padding: 20px 32px;
            background: #ffffff;
            border-radius: 4px;
            margin-bottom: 10px;
          }
          .b-b-10 {
            border-bottom: 10px solid #f7f9fb;
          }
        }
        .no-list-ctx {
          padding: 160px 0 254px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          .no-list-icon {
            width: 100px;
            height: 104px;
            margin-bottom: 23px;
          }
          .no-list-tip {
            font-size: 16px;
            color: #5a5a5a;
            margin-bottom: 40px;
            line-height: 22px;
          }
          .to-index-btn {
            width: 300px;
            height: 40px;
            border-radius: 20px;
            color: #ffffff;
            background: #4b8fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
      .search-footer {
        ::v-deep {
          .el-pagination {
            margin: -10px auto 0;
            background: #ffffff;
            padding: 25px 0;
          }
        }
      }
    }
  }
  .my-ask-right {
    flex-shrink: 0;
    width: 300px;
    margin-bottom: 32px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .min-height-600 {
    min-height: 600px;
  }
}
