






























































































































































































.module-to-search {
  border-radius: 4px;
  background: linear-gradient(180deg, #d6e5ff 0%, #fbfdff 22%, #ffffff 100%);
  position: relative;
  margin-bottom: 8px;
  .background-icon-1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .to-ask-ctx {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 23px 16px 16px;
    margin-bottom: 8px;
    .to-ask-ctx-title {
      font-size: 18px;
      color: #313131;
      line-height: 25px;
      font-weight: bolder;
      margin-bottom: 19px;
    }
    .to-ask-ctx-input {
      display: flex;
      margin-bottom: 4px;
      .input-1 {
        width: 100%;
        height: 40px;
        ::v-deep {
          .el-input__inner {
            // border: 1px solid #cccccc !important;
            border-right: none !important;
            border-radius: 4px 0 0 4px !important;
          }
        }
      }
      .btn-1 {
        flex-shrink: 0;
        width: 65px;
        height: 40px;
        background: #4b8fff;
        border-radius: 0px 4px 4px 0px;
        font-size: 16px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .btn-list {
    display: flex;
    justify-content: space-around;
    padding: 24px 0;

    .btn-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #313131;
      line-height: 17px;
      cursor: pointer;

      .icon {
        width: 45px;
        height: 45px;
        margin-bottom: 6px;
      }
    }
  }

  .user-info-con {
    border-top: 1px solid #efefef;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .avator-con {
      margin-bottom: 8px;
    }
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #363636;
      line-height: 20px;
      margin-bottom: 16px;
      text-align: center;
      cursor: pointer;
    }
    .info-list {
      display: flex;
      justify-content: center;
      cursor: pointer;
      .info-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #8e8e93;
        line-height: 20px;
        min-width: 45px;
        .label-span {
          color: #5a5a5a;
        }
        &.mid-line {
          padding: 0 25px;
          margin: 0 25px;
          position: relative;
          &::after,
          &::before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: #cccccc;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }
        }
      }
    }
  }
  .no-login-con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .login-btn {
      width: 160px;
      height: 40px;
      background: #4b8fff;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
    .desrc {
      font-size: 14px;
      font-weight: 400;
      color: #8e8e93;
      line-height: 20px;
      margin-top: 24px;
    }
  }
}
