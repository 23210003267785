














































































































































































::v-deep {
  .question-analysis {
    .subjective-of-ask {
      display: none;
    }
  }
}
.ask-details-questions {
  height: 100px;
  margin-bottom: 62px;
  .ask-details-questions-container {
    height: 100%;
  }
  .ask-question-ctx {
    height: 100%;
    background: #ffffff;
    display: flex;
    .has-parentid {
      flex-shrink: 0;
      width: 50%;
      box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.1);
      height: 100%;
      padding: 24px 0 12px 24px;
      overflow: hidden;
      .parent-stem-wrap {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        padding-right: 24px;
        .parent-stem-header {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .stem-label {
            padding: 2px 8px;
            font-size: 14px;
            font-weight: 600;
            color: #1e73ff;
            background: #eef2ff;
            border-radius: 3px;
            margin-right: 4px;
          }
          .strm-score {
            font-size: 16px;
            color: #919191;
          }
          .parent-id {
            font-size: 16px;
            margin-left: auto;
            color: #919191;
          }
        }
        .parent-stem-ctx {
          display: flex;
          .q-seq-index {
            color: #313131;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            margin-right: 8px;
          }
        }
        .parent-stem {
          font-size: 14px;
          font-weight: 400;
          color: #313131;
          line-height: 24px;
          overflow-y: auto;
          // padding-right: 37px;
          padding-bottom: 24px;
          &.del {
            text-decoration: line-through;
            margin-bottom: 0;
          }
          ::v-deep {
            table {
              border-collapse: collapse !important;
              border: none !important;
              td {
                border: 1px solid #000 !important;
              }
            }
          }
        }
      }
    }
    .alone-question {
      flex: 1;
      height: 100%;
      padding: 24px 0 12px 24px;
      position: relative;
      overflow: hidden;
      width: 100%;
      .alone-scroll {
        height: 100%;
        overflow-y: auto;
        padding-right: 24px;
      }
    }
  }
  .ask-question-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.08);
    z-index: 1999;
    .ask-question-footer-ctx {
      height: 100%;
      width: calc(100% - 40px);
      display: flex;
      align-items: center;
      justify-content: right;
      margin: 0 auto;
      .default-btn {
        width: 150px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #4b8fff;
        color: #4b8fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-left: 8px;
        cursor: pointer;
        ::v-deep {
          span {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }
  }
}
.m-l-auto {
  margin-left: auto;
}
