




















































































































.no-botttom {
  padding-bottom: 0 !important;
}
.timeout {
  padding-top: 16px !important;
}
.btns-con {
  padding: 0.625rem 0.742rem 0 !important;
}
