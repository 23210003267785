





































































































































































































































































































































































































































































































.card-ask-item {
  background: #ffffff;
  cursor: pointer;
  .detail-c-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .c-categroy {
      background: #f7f7f7;
      border-radius: 2px;
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      font-size: 12px;
      color: #595959;
      margin-right: 32px;
    }
    .ask-tag {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .left-icon {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        z-index: 99;
      }
      .right-text {
        height: 24px;
        display: flex;
        align-items: center;
        padding-right: 8px;
        padding-left: 16px;
        font-size: 12px;
        color: #ffffff;
        background: linear-gradient(144deg, #9bc5ff 0%, #6293ff 100%);
        border-radius: 0px 100px 100px 0px;
        margin: 6px -1px 4px -16px;
      }
      .right-text-2 {
        height: 24px;
        display: flex;
        align-items: center;
        padding-right: 8px;
        padding-left: 16px;
        font-size: 12px;
        color: #ffffff;
        background: linear-gradient(134deg, #ffdd6f 0%, #ffb93c 100%);
        border-radius: 0px 100px 100px 0px;
        margin: 6px -1px 4px -16px;
      }
    }
    .look-same-btn {
      margin-left: auto;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #4b8fff;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .c-tag-ctx {
      position: relative;
      .c-tag-icon {
        height: 32px;
      }
      .c-tag-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: right;
        align-items: center;
        font-size: 12px;
        color: #ffffff;
        padding-right: 8px;
      }
    }
  }
  .c-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .c-categroy {
      background: #f7f7f7;
      border-radius: 2px;
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      font-size: 12px;
      color: #595959;
    }
    .c-time {
      font-size: 12px;
      color: #919191;
    }
  }
  .c-content {
    color: #363636;
    font-size: 16px;
    font-weight: bolder;
    line-height: 24px;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .c-content-hover:hover {
    color: #4B8FFF;
    font-weight: 500;
  }
  .c-question {
    background: #f7f8f9;
    border-radius: 4px;
    margin-bottom: 8px;
    height: 33px;
    width: 100%;
    display: flex;
    align-items: center;
    .c-q-icon {
      margin-left: 6px;
      width: 16px;
      height: 16px;
      background: url('~@/assets/images/learning-center/question-icon.png');
      background-size: 16px 16px;
      transform: translateY(-1px);
    }
    .c-q-text {
      margin-left: 3px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      color: #5a5a5a;
      padding-right: 16px;
    }
    &:hover {
      .c-q-icon {
          background: url('~@/assets/images/learning-center/question-icon-active.png');
          background-size: 16px 16px;
      }
      .c-q-text {
        color: #4B8FFF;
      }
    }
    
  }
  .c-images {
    display: flex;
    margin-bottom: 12px;
    .c-images-item {
      width: 80px;
      height: 80px;
      margin-right: 8px;
      .c-images-box {
        width: 80px;
        height: 80px;
        display: block;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
  .c-answer-images {
    display: flex;
    margin-bottom: 16px;
    .c-answer-images-item {
      width: 60px;
      height: 60px;
      margin-right: 8px;
      .c-answer-images-box {
        width: 60px;
        height: 60px;
        display: block;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
  .c-tools {
    display: flex;
    align-items: center;
    justify-content: left;
    // margin-bottom: 8px;
    .author-info {
      margin-right: auto;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #919191;
      .author-avatar {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        border-radius: 50%;
      }
      .author-info-name {
        height: 20px;
        display: flex;
        align-items: center;
      }
    }
    .c-tools-item {
      display: flex;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;
      .c-tools-icon {
        width: 24px;
        height: 24px;
        display: block;
        margin-right: 2px;
      }
      .c-tools-text {
        height: 24px;
        line-height: 27px;
        font-size: 12px;
        flex-shrink: 0;
        // display: flex;
        // align-items: center;
        position: relative;
        .c-tools-count {
          position: absolute;
          top: 0;
          width: 80px;
          right: -80px;
        }
      }
    }
  }
  .c-answer {
    margin-top: 10px;
    background: #f5f8ff;
    padding: 12px 16px 12px;
    position: relative;
    border-radius: 4px;
    .c-answer-top-icon {
      position: absolute;
      top: -20px;
      left: 30px;
      width: 0;
      height: 0;
      border: 12px solid;
      border-color: transparent transparent #f5f8ff transparent;
    }
    .c-answer-tag {
      position: absolute;
      top: 0;
      right: 16px;
      width: 81px;
    }
    .c-answer-tag-favorite {
      position: absolute;
      top: 0;
      right: 16px;
      width: 93px;
    }
    .c-answer-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .c-answer-avatar {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        margin-right: 8px;
        .avatar-icon {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .v-icon {
          position: absolute;
          right: -2px;
          bottom: 0;
          width: 10px;
          height: 10px;
        }
      }
      .answer-user {
        font-size: 14px;
        color: #5a5a5a;
      }
      .answer-text {
        color: #4b8fff;
        font-size: 14px;
        font-weight: bolder;
      }
    }
    .c-answer-content {
      display: flex;
      align-items: center;
      .c-answer-q {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #595959;
        font-size: 14px;
        // font-weight: bolder;
        position: relative;
        .last-style {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 29px;
          background: linear-gradient(90deg, rgba(251, 252, 255, 0) 0%, rgba(246, 249, 255, 0.85) 47%, rgba(245, 248, 255, 0.98) 81%, #f5f8ff 100%);
        }
      }
      .c-answer-more {
        flex-shrink: 0;
        font-size: 14px;
        color: #4b8fff;
        cursor: pointer;
        font-weight: bolder;
      }
    }
  }
}
.card-type-detail {
  cursor: inherit;
  .c-content {
    overflow: inherit;
    white-space: inherit;
    text-overflow: inherit;
    -webkit-line-clamp: inherit;
    display: inherit;
    -webkit-box-orient: inherit;
  }
  .c-question {
    cursor: pointer;
  }
}
.m-b-0 {
  margin-top: 8px !important;
  margin-bottom: 0px !important;
}
.m-l-32 {
  margin-left: 32px;
}
.m-r-0 {
  margin-right: 0px !important;
}
.card-tools-item {
}
::v-deep {
  .bt-ask-highlight {
    color: #4b8fff !important;
  }
}
