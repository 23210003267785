@font-face {
  font-family: 'AlibabaPuHuiTi';
  src: url('../../../assets/fonts/AlibabaPuHuiTi-2-105-Heavy.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AlibabaPuHuiTiMedium';
  src: url('../../../assets/fonts/AlibabaPuHuiTi-2-65-Medium.otf');
  font-weight: normal;
  font-style: normal;
}
.activity-con {
  .tuijian-icon {
    width: 3.125rem;
    height: 2.8516rem;
    position: absolute;
    top: -2.6367rem;
    left: -0.4297rem;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .red-packet {
    position: relative;
    .bg-img {
      width: 100%;
    }
    .hot-con {
      position: absolute;
      right: 0;
      cursor: pointer;
      width: 8rem;
      height: 3rem;

      &.free-hot-con {
        top: 3.5156rem;
      }
      &.packet-hot-con {
        bottom: 1.5rem;
      }
    }
  }
  .open-course-con {
    .course-item {
      position: relative;
      z-index: 1;
      margin-bottom: .3906rem;
      -webkit-border-radius: .0781rem;
      -moz-border-radius: .0781rem;
      border-radius: .0781rem;
      box-shadow: 0rem .1172rem .2344rem 0rem rgba(198, 198, 198, 0.3);
      background-color: #fff;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;

      &.loading {
        background: url('../../../assets/images/home/free-course-default.png') 0 0 no-repeat;
        background-size: 100% 99.9%;
      }

      &:hover {
        transform: translateY(-0.1172rem);
        -webkit-transform: translateY(-0.1172rem);
        -moz-transform: translateY(-0.1172rem);
      }

      &.course-default {
        background-color: #f5f5f5;
        padding: .9766rem .3906rem 1.0742rem;
        border: .0195rem dashed #e1e1e1;
        color: #e1e1e1;
        text-align: center;
        font-size: .4688rem;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
          transform: translateY(0);
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -o-transform: translateY(0);
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }

      .course-img {
        width: 100%;
        // height: 3.418rem;
        // display: inline;

        .img-responsive {
          border-top-left-radius: .0781rem;
          border-top-right-radius: .0781rem;
          width: 100%;
          padding: 0;
        }

        .learn-status {
          background: url('../../../assets/images/home/learn-status.png') 0 0 no-repeat;
          text-align: center;
          background-size: 100% 99.9%;
          z-index: 99;

          .status {
            font-size: .2344rem;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
          }
        }
      }

      .course-info {
        .metas {
          position: relative;
          height: 1.0156rem;
          padding: .3125rem .3125rem .3906rem;
          color: #c1c1c1;

          .start-time {
            font-size: .3125rem;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            color: rgba(0, 35, 51, 1);
            vertical-align: middle;
          }

          .num {
            font-size: .2344rem;
            height: .3125rem;
            line-height: .3125rem;
            color: rgba(138, 148, 153, 1);
          }
        }
      }
    }
  }
  .normal-course-con {
    .course-item {
      overflow: hidden;
      height: 6.1914rem;
      position: relative;
      z-index: 1;
      margin-bottom: .5859rem;
      -webkit-border-radius: .0781rem;
      -moz-border-radius: .0781rem;
      border-radius: .0781rem;
      box-shadow: 0rem .1172rem .2344rem 0rem rgba(198, 198, 198, 0.3);
      background-color: #fff;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;

      &.loading {
        background: url('../../../assets/images/home/course-default.png') 0 0 no-repeat;
        background-size: 100% 99.9%;
      }

      &:hover {
        transform: translateY(-0.1563rem);
        -webkit-transform: translateY(-0.1563rem);
        -moz-transform: translateY(-0.1563rem);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      &.course-default {
        background-color: #f5f5f5;
        padding: .9766rem .3906rem 1.0742rem;
        border: .0195rem dashed #e1e1e1;
        color: #e1e1e1;
        text-align: center;
        font-size: .4688rem;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        &:hover {
          transform: translateY(0);
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -o-transform: translateY(0);
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }

        & > i {
          font-size: 1.4063rem;
          display: inline-block;
          margin-bottom: .3516rem;
        }
      }

      .course-img {
        position: relative;
        border-top-left-radius: .0781rem;
        border-top-right-radius: .0781rem;
        width: 100%;

        .img-responsive {
          width: 100%;
          padding: 0;
        }
      }

      .title {
        margin-top: .1953rem;
        padding: 0 .1953rem;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
      }

      .course-info {
        width: 6.0156rem;
        height: 3.0078rem;

        .metas {
          position: relative;
          padding: .3125rem .3125rem .3906rem .3125rem;
          color: #c1c1c1;

          .course-title {
            height: .8594rem;
            font-size: .3125rem;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            color: rgba(0, 35, 51, 1);
            line-height: .4297rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .course-person {
            margin-top: .1758rem;
            width: 100%;
            height: .2734rem;

            .teacher {
              font-size: .2344rem;
              font-family: PingFangSC-Regular, sans-serif;
              font-weight: 400;
              color: rgba(138, 148, 153, 1);
              vertical-align: top;
            }

            .num {
              font-size: .2344rem;
              line-height: .2344rem;
              vertical-align: middle;
              color: rgba(138, 148, 153, 1);
            }
          }

          .course-prices {
            margin-top: .3125rem;
            position: relative;
            vertical-align: bottom;

            &.is-vip {
              .is-vip-price {
                display: inline-block;
              }
            }

            .member-role {
              display: inline-block;
              width: 1.25rem;
              height: .4688rem;
              margin-left: -0.3125rem;
              margin-right: .1563rem;
              vertical-align: bottom;
            }

            .course-price {
              font-size: .3906rem;
              line-height: .3516rem;
              font-family: STHeitiSC-Medium, sans-serif;
              font-weight: 500;
              color: rgba(255, 77, 54, 1);
              vertical-align: bottom;
              margin-right: .0781rem;

              .sales-zk {
                font-weight: 500;
                font-size: .3711rem;
                font-family: PingFangSC-Regular;
              }

              &.ml0 {
                margin-left: 0 !important;
              }
            }

            .course-origin-price {
              line-height: .3516rem;
              font-family: STHeitiSC-Medium, sans-serif;
              font-weight: 500;
              vertical-align: bottom;
              color: rgba(255, 77, 54, 1);
              font-size: .3906rem;

              &.del {
                font-size: .2734rem;
                color: rgba(138, 148, 153, 1);
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
    .course-info {
      width: 6.0156rem;
      height: 3.0078rem;

      .metas {
        position: relative;
        padding: .3125rem .3125rem .3906rem .3125rem;
        color: #c1c1c1;

        .course-title {
          height: .8594rem;
          font-size: .3125rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-weight: 400;
          color: rgba(0, 35, 51, 1);
          line-height: .4297rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .course-person {
          margin-top: .1758rem;
          width: 100%;
          height: .2734rem;

          .teacher {
            font-size: .2344rem;
            font-family: PingFangSC-Regular, sans-serif;
            font-weight: 400;
            color: rgba(138, 148, 153, 1);
            vertical-align: top;
          }

          .num {
            font-size: .2344rem;
            line-height: .2344rem;
            vertical-align: middle;
            color: rgba(138, 148, 153, 1);
          }
        }

        .course-prices {
          margin-top: .3125rem;
          position: relative;
          vertical-align: bottom;

          &.is-vip {
            .is-vip-price {
              display: inline-block;
            }
          }

          .member-role {
            display: inline-block;
            width: 1.25rem;
            height: .4688rem;
            margin-left: -0.3125rem;
            margin-right: .1563rem;
            vertical-align: bottom;
          }

          .course-price {
            font-size: .3906rem;
            line-height: .3516rem;
            font-family: STHeitiSC-Medium, sans-serif;
            font-weight: 500;
            color: rgba(255, 77, 54, 1);
            vertical-align: bottom;
            margin-right: .0781rem;

            .sales-zk {
              font-weight: 500;
              font-size: .3711rem;
              font-family: PingFangSC-Regular;
            }

            &.ml0 {
              margin-left: 0 !important;
            }
          }

          .course-origin-price {
            line-height: .3516rem;
            font-family: STHeitiSC-Medium, sans-serif;
            font-weight: 500;
            vertical-align: bottom;
            color: rgba(255, 77, 54, 1);
            font-size: .3906rem;

            &.del {
              font-size: .2734rem;
              color: rgba(138, 148, 153, 1);
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
  .page-con {
    background-color: #fff;
    padding-top: .9766rem;
    .page-title {
      width: 25.3906rem;
      margin: 0 auto;
      font-size: .4688rem;
      font-weight: 600;
      color: #000000;
      line-height: .5469rem;
      text-align: left;
    }
    .page-content {
      width: 100%;

      .list-con {
        .list-content {
          width: 25.3906rem;
          margin: 0 auto;

          .title {
            font-size: .3906rem;
            font-weight: 600;
            color: #393939;
            line-height: .4492rem;
            margin-bottom: .3906rem;
            margin-left: .1953rem;
          }
          .course-list {
            overflow: hidden;
          }
        }
        padding: .9766rem 0;
        &:nth-child(n) {
          background: #ffffff;
        }
        &:nth-child(2n) {
          background: #fafafa;
        }
      }
    }
  }
  .anniversary-container {
    min-height: 100vh;
    margin-top: 1.5625rem;
    .activity-carousel {
      min-width: 23.4375rem;
      height: 11.7188rem;
      .swiper-container {
        height: 100%;
        width: 100%;
      }

      .swiper-pagination-bullet {
        font-size: 0;
        margin: 0 .1953rem;
        width: .293rem;
        height: .293rem;
        display: inline-block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: unset;
        border: .0391rem solid #fff;
        opacity: 1;
      }

      .swiper-pagination-bullet:hover {
        cursor: pointer;
        background: #fff;
        opacity: 1;
      }

      .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #fff;
        opacity: 1;
      }
      .img-responsive {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0 auto;
      }
    }
    .out-content {
      background: linear-gradient(180deg, #c61b25 0%, #a10c1d 24%, #990817 100%);
      padding-top: 1.5625rem;
      padding-bottom: .3906rem;
    }
    .content {
      width: 23.4375rem;
      margin: 0 auto;
    }

    .progress-con {
      height: .293rem;
      color: white;
      width: 100%;
      height: .1953rem;
      background: #ffffff;
      border-radius: .1953rem;
      position: relative;
      .active-progress {
        height: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #ffffff linear-gradient(140deg, #fdb450 0%, #fedc90 100%);
        border-radius: .1953rem;
      }
      .progress-item {
        width: .918rem;
        height: .8398rem;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url('./images/progress-dot.png');
        background-size: 100% 99.9%;
      }
    }

    .more-con {
      text-align: center;
      margin: .3125rem 0;
      .more-btn {
        display: inline-block;
        width: 3.3984rem;
        height: .7813rem;
        border-radius: .3711rem;
        border: .0391rem solid #fd3659;
        font-size: .3125rem;
        font-weight: 600;
        color: #fd3659;
        text-align: center;
        line-height: .7422rem;
      }
    }
    .cpa-score-content {
      background: linear-gradient(134deg, #fff9f3 0%, #ffe3c1 100%);
      border-radius: .625rem;
      padding: 2.2266rem .625rem 1.2109rem .7031rem;
      position: relative;
      margin-top: 3.0664rem;

      .cpa-title {
        width: 18.1836rem;
        height: 2.4219rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .3906rem;
        background-image: url('./images/cpa-title-bg.png');
        background-size: cover;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -1.3672rem;
        .title-lingxing-list {
          display: flex;
          align-items: center;
          margin: 0 .3906rem;
          .lingxing-item {
            width: .2344rem;
            height: .2344rem;
            background: #d8d8d8 linear-gradient(180deg, #ffffff 0%, #ffeca4 100%);
            border-radius: .0391rem;
            transform: rotate(-45deg);
            margin: 0 .1172rem;
          }
        }
        .title-span {
          font-size: .9375rem;
          color: #ffffff;
          line-height: 1.2695rem;
          background: linear-gradient(180deg, #ffffff 0%, #ffeca4 100%);
          background-clip: text;
          color: transparent;
          font-weight: 600;
        }
      }
      .descrip-con {
        margin: 0 0 1.1133rem 0;
        position: relative;
        height: 2.6953rem;

        .descrip-span {
          width: 15.8594rem;
          position: absolute;
          right: .8203rem;
          background: #ffffff;
          border-radius: .4883rem;
          padding: .5078rem .8398rem .4297rem 1.0352rem;
          font-size: .625rem;
          font-weight: 600;
          color: #d75701;
          line-height: .8789rem;
          &::after {
            content: '';
            border-left: .1953rem solid transparent;
            border-top: .1953rem solid transparent;
            border-right: .1953rem solid #fff;
            border-bottom: .1953rem solid transparent;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            position: absolute;
            left: -0.3906rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .descrip-img {
          display: inline-block;
          width: 5.3516rem;
          height: 5.3516rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-image: url('./images/manfen.png');
          background-size: cover;
        }
      }
      .cpa-btn-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .8008rem;
        .cpa-btn {
          width: 10.6641rem;
          height: 1.6797rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffc75b;
          box-shadow: 0rem 0rem .1758rem 0rem rgba(255, 255, 255, 0.5), inset -0.0586rem -0.2148rem .293rem 0rem rgba(255, 0, 0, 0.45), inset .1563rem .1172rem .4492rem 0rem #fff5e0,
            inset 0rem -0.1172rem .0977rem 0rem rgba(255, 181, 239, 0.38);
          border-radius: .8398rem;
          border: .0391rem solid #fbffde;
          font-size: .625rem;
          font-weight: 600;
          color: #511700;
          cursor: pointer;
          position: relative;
          .icon-img {
            width: 2.2852rem;
            position: absolute;
            right: .7031rem;
            bottom: .6641rem;
          }
          &.btn2 {
            background: #fd343f;
            box-shadow: 0rem 0rem .1758rem 0rem rgba(255, 255, 255, 0.5), inset -0.0586rem -0.2148rem .293rem 0rem #ff82db, inset .1563rem .1172rem .4492rem 0rem #fff5b9,
              inset 0rem -0.1172rem .0977rem 0rem rgba(255, 181, 239, 0.38);
            color: #ffffff;
          }
        }
        .btn-bli {
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: .8398rem;
          overflow: hidden;
          &::before {
            content: '';
            width: .5078rem;
            height: 100%;
            top: 0;
            left: -0.293rem;
            position: absolute;
            z-index: 1;
            overflow: hidden;
            background: linear-gradient(270deg, rgba(255, 245, 174, 0) 0%, #fffad0 32%, #fffad0 55%, rgba(255, 247, 152, 0) 100%);
            opacity: 0.5;

            -webkit-transform: skewX(-25deg);
            -moz-transform: skewX(-25deg);
            -webkit-animation: funaniBlink 1.5s infinite;
            -moz-animation: funaniBlink 1.5s infinite;
            -o-animation: funaniBlink 1.5s infinite;
            animation: funaniBlink 1.5s infinite;
          }
        }
      }
      .card-list {
        background: #ffffff linear-gradient(136deg, #ffd06f 26%, #fce8b9 72%, #fce8b7 61%, #fed263 100%);
        border-radius: .3906rem;
        opacity: 0.94;
        display: flex;
        justify-content: space-between;
        padding: .4883rem .7813rem;
        .card-item {
          width: 4.707rem;
          height: 5.1367rem;
          background: #feeabf;
          box-shadow: .0391rem .0586rem .2344rem 0rem rgba(110, 77, 0, 0.06);
          border: .0586rem solid rgba(255, 255, 255, 0.7);
          border-radius: .3516rem;
          display: flex;
          flex-direction: column;
          padding: .4883rem 0rem .3516rem .5078rem;

          .c-s-title {
            height: .8594rem;
            font-size: .625rem;
            color: #d75701;
            font-weight: 600;
            line-height: .8594rem;
            text-shadow: .0391rem .0586rem .2344rem rgba(110, 77, 0, 0.06);
            margin-bottom: .4297rem;
          }
          .card-content {
            font-size: .4297rem;
            color: #d75701;
            line-height: .5859rem;
            text-shadow: .0391rem .0586rem .2344rem rgba(110, 77, 0, 0.06);
            margin-bottom: .957rem;
            .card--content-item {
              margin-bottom: .1563rem;
            }
          }
          .link-btn {
            height: .5859rem;
            font-size: .4297rem;
            font-weight: 600;
            color: #d14f19;
            line-height: .5859rem;
            text-shadow: .0391rem .0586rem .2344rem rgba(110, 77, 0, 0.06);
            cursor: pointer;
            position: relative;
            &:hover {
              &::after {
                content: '';
                height: .0391rem;
                width: 2.5rem;
                display: inline-block;
                position: absolute;
                background-color: #d14f19;
                left: 0;
                bottom: .0195rem;
              }
            }
          }
        }
      }
      .bottom-icon {
        position: absolute;
        right: .0781rem;
        bottom: .2148rem;
        width: 2.4805rem;
        height: 2.207rem;
        background-image: url('./images/gift-icon.png');
        background-size: cover;
      }
    }
    // 低价板块
    .low_price-con {
      width: 100%;
      height: 14.4922rem;
      background: linear-gradient(134deg, #fff9f3 0%, #ffe3c1 100%);
      border-radius: .625rem;
      overflow: hidden;
      position: relative;
      padding: 0 .7422rem;
      .right-icon {
        width: 2.6172rem;
        height: 2.9688rem;
        position: absolute;
        top: 0;
        right: .5273rem;
      }
      .title-container {
        background-image: url('./images/low-price-title-bg.png');
        background-size: 100% 99.9%;
        background-repeat: no-repeat;
        height: 2.4219rem;
        width: 14.9609rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        .title {
          font-size: .9375rem;
          color: #ffffff;
          line-height: 1.2695rem;
          background: linear-gradient(180deg, #ffffff 0%, #ffeca4 100%);
          background-clip: text;
          color: transparent;
          font-weight: 600;
          margin-top: .1367rem;
          font-family: AlibabaPuHuiTi;
        }
        .s-title {
          font-size: .5469rem;
          font-weight: 400;
          color: #ffdeb9;
          line-height: .7813rem;
        }
      }
      .items {
        display: flex;
        justify-content: space-between;
        margin-top: 4.0234rem;
        .img,
        .btn-con-price {
          width: 4.3164rem;
          height: 4.3164rem;
        }
        .item-con {
          width: 5.2539rem;
          height: 6.3281rem;
          background: #ffffff;
          border-radius: .3125rem;
          border: .0195rem solid #d2d2d2;
          position: relative;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: .3125rem 0 .3125rem 0;

          .name-content {
            font-size: .3906rem;
            color: #363636;
            line-height: .5469rem;
            text-align: center;
          }
        }
        .active-con {
          width: 100%;
          height: 75%;
          padding-top: .1563rem;
          background-color: #fff;
          position: absolute;
          display: flex;
          align-items: center;
          flex-direction: column;
          top: 0;
          left: 0;
          .span-fen {
            font-size: .2734rem;
            color: #fd4162;
            line-height: .3906rem;
          }
          .img {
            width: 3.4961rem;
            height: 3.4961rem;
            margin: .3125rem 0 .0781rem 0;
          }
          .btn-con-price {
            display: flex;
            justify-content: center;
            align-items: center;
            .go-btn {
              display: inline-block;
              width: 3.9258rem;
              height: .8594rem;
              border-radius: .4297rem;
              font-size: .3516rem;
              font-weight: 600;
              color: #fd3659;
              border: .0391rem solid #fd3659;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
        }
      }
      .operate-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.1719rem;
        .btn-item {
          width: 50%;
          height: 1.6797rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffc75b;
          box-shadow: 0rem 0rem .1758rem 0rem rgba(255, 255, 255, 0.5), inset -0.0586rem -0.2148rem .293rem 0rem rgba(255, 0, 0, 0.45), inset .1563rem .1172rem .4492rem 0rem #fff5e0,
            inset 0rem -0.1172rem .0977rem 0rem rgba(255, 181, 239, 0.38);
          border-radius: .8398rem;
          border: .0391rem solid #fbffde;
          font-size: .625rem;
          font-weight: 600;
          color: #511700;
          cursor: pointer;

          &.fenbtn {
            background: #fd343f;
            box-shadow: 0rem 0rem .1758rem 0rem rgba(255, 255, 255, 0.5), inset -0.0586rem -0.2148rem .293rem 0rem #ff82db, inset .1563rem .1172rem .4492rem 0rem #fff5b9,
              inset 0rem -0.1172rem .0977rem 0rem rgba(255, 181, 239, 0.38);
            border: .0391rem solid #fbffde;
            color: #ffffff;
            margin-right: .4297rem;
          }
        }
      }
    }
    // 学霸同款课程
    .same-course-container {
      width: 100%;
      .img-con {
        width: 100%;
        min-height: 7.8125rem;
        margin-top: .7813rem;
        position: relative;
        img {
          width: 100%;
        }
      }
      .btns-con {
        width: 23.4375rem;
        display: flex;
        align-items: center;
        padding: .3125rem .0781rem .0781rem .625rem;
        padding-left: .4688rem;
        flex-wrap: wrap;
      }

      .sticky-fixed {
        width: 23.4375rem;
        .btns-con {
          background: #ffebd3;
        }
      }
    }
    // 校友卡福利，右侧直播
    .fuli-block {
      width: 23.4375rem;
      height: 13.8672rem;
      padding: .7813rem .6836rem .4297rem .8984rem;
      background: linear-gradient(180deg, #ffe8cb 0%, #ffffff 100%);
      border-radius: .1953rem;
      display: flex;
      .left {
        width: 10.9375rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: .0977rem;
        .item-img {
          width: 100%;
          height: 2.9297rem;
          &:nth-child(2) {
            cursor: pointer;
          }
          &:last-child {
            height: 6.0156rem;
          }
        }
      }
      .live-lesson-con {
        width: 10.7813rem;
        height: 12.6563rem;
        background-image: url(https://tmp.btclass.cn/yunyin/o_1hck99s9q2oj1bon1jvkoksr25g.png);
        background-size: cover;
        padding: .3906rem 0 .3906rem .8984rem;
        overflow: hidden;
        .scroll-con {
          width: 100%;
          height: 100%;
          overflow: auto;
        }
        .lesson-item-con {
          display: flex;
          justify-content: center;
          width: 9.4922rem;
          height: 2.8516rem;
          margin-bottom: .1563rem;
          .detail-btn {
            position: absolute;
            bottom: .3711rem;
            right: .3711rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .num-con {
            font-size: .3906rem;
            font-weight: 400;
            color: #511700;
            line-height: .5469rem;
            display: flex;
            align-items: center;
            .avators {
              display: inline-block;
              padding-left: .2344rem;
              .avator-item {
                width: .4297rem;
                height: .4297rem;
                border-radius: 50%;
                margin-left: -0.2344rem;
              }
            }
            .num {
              opacity: 0.4;
              margin-left: .0391rem;
              flex-shrink: 0;
            }
          }
          .teacher-info {
            display: flex;
            align-items: center;
            .teacher-tag {
              display: inline-block;
              padding: 0 .1563rem;
              height: .6836rem;
              line-height: .6836rem;
              background: #ffe3df;
              border-radius: .1563rem .1563rem .1563rem 0rem;
              font-size: .3906rem;
              color: #ff5353;
              line-height: .6836rem;
              margin-left: .2734rem;
            }
          }

          .lesson-item-content {
            display: flex;
            justify-content: center;
          }
          .lesson-item {
            width: 100%;
            height: 100%;
            background-image: url(https://tmp.btclass.cn/yunyin/o_1hcm0cu2e1g6dol31n1i12l815c6a.png);
            background-size: 100% 99.9%;
            position: relative;
            display: flex;
            align-items: center;
            .lesson-item-content {
              padding: .2344rem;
            }
            .living-tag {
              width: 1.25rem;
              height: .3906rem;
              background: linear-gradient(90deg, #fb494a 0%, #fb494a 100%);
              border-radius: .1172rem 0rem .1172rem 0rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              left: 0;
              top: 0;
              color: #fff;
              z-index: 2;
              font-size: .2734rem;
              .lottie {
                width: .1758rem;
                height: .1953rem;
              }
            }
            .time-tag {
              width: 1.7578rem;
              font-size: .2734rem;
              height: .3906rem;
              background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
              border-radius: .1172rem 0rem .1172rem 0rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              left: 0;
              top: 0;
              color: #fff;
              z-index: 2;
            }
            .teacher-img {
              width: 2.207rem;
              height: 2.207rem;
              flex-shrink: 0;
              position: relative;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .lesson-info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 0rem .0781rem 0 .1758rem;
              width: 100%;
              .lesson-title {
                display: inline-block;
                max-width: 4.6484rem;
                font-size: .3516rem;
                font-weight: 600;
                color: #363636;
                line-height: .4883rem;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-wrap: normal;
                margin-bottom: .3125rem;
              }

              .teacher-info {
                font-size: .2734rem;
                color: #5a5a5a;
                line-height: .3906rem;
                .teacher-tag {
                  font-size: .2344rem;
                  height: .3711rem;
                  line-height: .3711rem;
                  background: #ffefed;
                  border-radius: .0781rem .0781rem .0781rem 0rem;
                  padding: 0 .0586rem;
                  margin-left: .1758rem;
                }
              }

              .time-span {
                font-size: .2734rem;
                color: #4e9afb;
                line-height: .3516rem;
              }
              .num-con {
                font-size: .2344rem;
                line-height: .3125rem;
                .icon {
                  font-size: .2734rem;
                }
              }
              .s-btn {
                width: 1.6797rem;
                height: .625rem;
                line-height: .625rem;
                font-size: .3125rem;
                font-weight: 600;
                border-radius: .3711rem;
                color: #ffffff;
                background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
                &.is-living {
                  background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
                }
                &.is-finished {
                  background: #eeeeee;
                  color: #bbbbbb;
                }
                &.is-reserved {
                  background: #fff;
                  color: #4b8fff;
                  border: .0195rem solid #4b8fff;
                }
              }
            }
          }
        }
      }
    }
    .miaosha-container {
      width: 23.4375rem;
      height: 13.1836rem;
      background-image: url('https://tmp.btclass.cn/yunyin/o_1hcm53dou1fc41fev18qh1buu9qdi.png');
      background-size: cover;
      padding-top: 2.4023rem;
      margin-top: -2.1484rem;
      .times-tab {
        display: flex;
        padding: 0 .3906rem;
        border-radius: 0 .3906rem 0;
        overflow-x: auto;
        .item {
          width: 3.125rem;
          height: 3.0078rem;
          position: relative;
          border-radius: .3906rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: .5469rem;
          color: #fff;
          font-weight: 500;
          line-height: .7813rem;
          flex-shrink: 0;
          .time {
            margin-top: .0781rem;
            margin-bottom: .2344rem;
          }
          .status {
            display: flex;
            align-items: center;
            line-height: .5469rem;
            font-size: .3906rem;
            .clock {
              height: .4102rem;
              width: .4102rem;
              margin-right: .0781rem;
            }
          }
          &.finished {
            opacity: 0.6;
          }
          &.is-active {
            opacity: 1;
            background: #ffe7c8;
            color: #bd4b4f;
            &::after {
              display: none;
            }
          }
          &.inProgress {
            .status {
              color: #ff3b0f;
            }
            .progress-img {
              width: 2.2656rem;
              height: .625rem;
            }
            .status-shandian {
              width: .3906rem;
              height: .5078rem;
              margin-right: .0781rem;
            }
          }
        }
        .split {
          width: .0195rem;
          height: .8984rem;
          background-color: rgba(255, 255, 255, 0.3);
          margin: 0 .1953rem;
          flex-shrink: 0;
          margin-top: 1.1133rem;
        }
      }
      .miaosha-course-detail {
        padding: .7813rem;
        display: flex;
        position: relative;
        .course-img {
          width: 10.8984rem;
          height: 6.1328rem;
          border-radius: .1563rem;
          margin-right: .3906rem;
        }
        .course-info-con {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .course-title {
            height: 1.5625rem;
            font-size: .5469rem;
            font-weight: 600;
            color: #363636;
            line-height: .7813rem;
            .to-span {
              color: #aa0922;
              cursor: pointer;
            }
          }
          .count-con {
            font-size: .4688rem;
            font-weight: 600;
            color: #b84524;
            display: flex;
            align-items: center;
            .count-down-con {
              display: flex;
              align-items: center;
              margin-left: .2148rem;
              span {
                display: inline-block;
                width: .7227rem;
                height: .8398rem;
                background: linear-gradient(138deg, #b84524 0%, #dd7b4a 100%);
                border-radius: .0781rem;
                font-size: .4688rem;
                font-weight: 600;
                color: #ffffff;
                line-height: .6445rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 .1172rem;
              }
            }
          }
          .pepole-count {
            height: .9375rem;
            font-size: .4688rem;
            font-weight: 500;
            color: #5a5a5a;
            line-height: .9375rem;
            margin-bottom: .3125rem;
            .avators {
              display: inline-block;
              padding-left: .625rem;
              .avator-item {
                width: .9375rem;
                height: .9375rem;
                border-radius: 50%;
                margin-left: -0.625rem;
              }
            }
          }
          .operate-btn {
            width: 5.957rem;
            height: 2.6367rem;
            position: absolute;
            bottom: 0;
            right: .3516rem;
          }
        }
      }
    }

    // 优惠券版块相关样式
    .coupons-con {
      width: 100%;
      height: 10.1758rem;
      position: relative;
      .coupons-content {
        width: 100%;
        height: 100%;
        padding: 0 1.4844rem;
        position: absolute;
        left: 0;
        top: 0;
        padding-top: 3.5547rem;
      }
      .coupon-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .coupon-item {
          width: 4.8828rem;
          height: 3.4375rem;
          background-image: url('./images/coupon-bg.png');
          background-size: 100% 99.9%;
          position: relative;
          text-align: center;
          .usbale-icon {
            width: 1.3867rem;
            height: .918rem;
            background-image: url('./images/reveive.png');
            background-size: 100% 99.9%;
            position: absolute;
            top: 0;
            right: 0;
          }
          .rate-price {
            height: 1.5234rem;
            line-height: 1.5234rem;
            font-size: 1.0938rem;
            font-weight: 600;
            color: #fde8b2;
            margin-top: .2344rem;
          }
          .price-threshold {
            width: 100%;
            height: .5469rem;
            line-height: .5469rem;
            font-size: .3906rem;
            color: #fde8b2;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: .3125rem;
          }
        }
      }
      .operation-btn {
        width: 13.2813rem;
        height: 1.7578rem;
        position: absolute;
        font-size: .625rem;
        font-weight: 600;
        color: #ffffff;
        left: 50%;
        transform: translateX(-50%);
        bottom: .6836rem;
        text-align: center;
        line-height: 1.5625rem;
        cursor: pointer;
      }
      img {
        width: 100%;
      }
    }

    .zixun-con {
      cursor: pointer;
      height: .8594rem;
      font-size: .3125rem;
      font-weight: 600;
      color: #ffffff;
      line-height: .8594rem;
      .menu-zixun-icon {
        display: inline-block;
        width: .4102rem;
        height: .4297rem;
      }
      .zixun-img {
        width: 114%;
        margin-left: -7%;
      }
      padding: .1953rem auto;
    }

    .btns-con {
      .btn-item {
        display: inline-block;
        line-height: .8984rem;
        height: .8984rem;
        background: linear-gradient(140deg, #ffd0aa 0%, #fbdfb2 100%);
        border-radius: .1563rem;
        font-size: .3906rem;
        font-weight: 600;
        color: #aa0922;
        padding: 0 .5859rem;
        margin-right: .3125rem;
        margin-bottom: .1563rem;

        cursor: pointer;
        &.active,
        &:hover {
          background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
          color: #fff0da;
        }
      }
    }
    .block-item {
      .has-m-h {
        min-height: 5.8594rem;
      }
      margin-bottom: 1.5625rem;
      &.grank-item {
        margin-bottom: 0;
      }
      .title-con {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: .8593rem;
        .title-content {
          height: 1.5039rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .title {
            background: linear-gradient(180deg, #ffffff 0%, #ffeca4 100%);
            background-clip: text;
            // -webkit-background-clip: text;
            color: transparent;
            font-size: .9375rem;
            font-weight: bold;
            margin: 0 .4688rem;
            font-family: AlibabaPuHuiTi;
          }
          .icon {
            display: inline-block;
            background-size: cover;
            width: 1.9531rem;
            height: 2.0117rem;
            &.left-icon1 {
              background-image: url('./images/title-icon-left1.png');
            }
            &.right-icon1 {
              background-image: url('./images/title-icon-right1.png');
            }
            &.left-icon2 {
              width: 1.25rem;
              height: 1.4063rem;
              background-image: url('./images/title-icon-left2.png');
            }
            &.right-icon2 {
              width: 1.25rem;
              height: 1.4063rem;
              background-image: url('./images/title-icon-right2.png');
            }
            &.left-icon3 {
              width: 1.1719rem;
              height: 1.5039rem;
              background-image: url('https://app-cdn.btclass.cn/new-prefix/turtle/FshvKxoIAkk4PEdoMfRRzrMHNINx.png');
            }
            &.right-icon3 {
              width: 1.1719rem;
              height: 1.5039rem;
              background-image: url('https://app-cdn.btclass.cn/new-prefix/turtle/Fv4mGRC-qPTv_V9xl_15XoJJ6sqJ.png');
            }
            &.left-icon-10-0 {
              width: 1.4843rem;
              height: 1.4843rem;
              background-image: url('https://app-cdn.btclass.cn/turtle/10-pc-hongbao-left.png');
            }
            &.right-icon-10-0 {
              width: 1.4843rem;
              height: 1.4843rem;
              background-image: url('https://app-cdn.btclass.cn/turtle/10-pc-hongbao-right.png');
            }
            &.left-icon-10-1 {
              width: 1.4843rem;
              height: 1.4843rem;
              background-image: url('https://app-cdn.btclass.cn/turtle/10-icon-left1.png');
            }
            &.right-icon-10-1 {
              width: 1.4843rem;
              height: 1.4843rem;
              background-image: url('https://app-cdn.btclass.cn/turtle/10-icon-right2.png');
            }
          }
        }

        .s-title {
          font-size: .5469rem;
          color: #ffdeb9;
          line-height: .7813rem;
        }
        .s-title-tip {
          font-size: .3906rem;
          font-weight: 400;
          color: #ffdeb9;
          line-height: .5469rem;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // 侧边栏样式
    .page-aside-menu {
      position: fixed;
      left: 1.0742rem;
      z-index: 10;
      width: 2.2656rem;
      box-shadow: 0rem 0rem .1953rem 0rem rgba(0, 0, 0, 0.05);
      top: 5.4688rem;
      background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
      border-radius: .0781rem;
      overflow: visible !important;
      padding: .0781rem 0;

      .seven-th {
        display: block;
        width: 2.2656rem;
        height: 1.3281rem;
        position: absolute;
        top: -1.5234rem;
      }

      .el-scrollbar__wrap {
        overflow: visible;
        margin: 0 !important;
      }
      .menu-content {
        background: #fff1df;
        border-radius: .0781rem;
        padding: .1953rem 0;
        box-shadow: 0rem .0781rem .3125rem 0rem rgba(0, 0, 0, 0.1);
      }
      .menus {
        width: 2.1094rem;
        text-align: center;
        margin: 0 auto;
        a {
          display: block;
          width: 100%;

          height: .625rem;
          font-size: .2734rem;
          font-weight: 400;
          color: #c7102c;
          line-height: .625rem;

          &:hover,
          &.active {
            // color: #fff;
            background: #f5cc99;
          }
        }
      }
    }
    // 见面礼版块样式
    .welcome-gift {
      display: flex;
      justify-content: space-around;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      .item {
        width: 7.4219rem;
        height: 5.0781rem;
        margin-bottom: .2734rem;
        img {
          width: 100%;
          height: 100%;
        }
        position: relative;
        .btn {
          width: 2.6172rem;
          cursor: pointer;
          height: .9766rem;
          background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
          border-radius: .6445rem;
          font-size: .4688rem;
          font-weight: 600;
          color: #ffffff;
          position: absolute;
          bottom: 1.6602rem;
          left: .4688rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .left {
        .item {
          width: 15.625rem;
          height: 10.5469rem;
          .btn {
            font-size: .7031rem;
            width: 4.2578rem;
            height: 1.5625rem;
            border-radius: 1.0352rem;
            left: .7813rem;
            bottom: 1.4844rem;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    // 抽奖板块单图
    .lottery-con {
      height: 8.5156rem;
      width: 100%;
      margin-top: -0.4688rem;
    }
    .plan-con {
      width: 100%;
      height: 7.5586rem;
      margin-top: .5859rem;
    }
    .gift-box {
      display: flex;
      flex-direction: column;
      .top-bg {
        width: 100%;
        height: 5.8594rem;
        margin-bottom: .3906rem;
      }
      .b-bg {
        width: 100%;
      }

      // .bottom-imgs {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   .item-img {
      //     width: 11.5234rem;
      //     height: 4.6875rem;
      //   }
      // }
    }

    .normal-course-con {
      padding: .625rem .7031rem .4688rem .7031rem;
      background: linear-gradient(180deg, #ffe8cb 0%, #ffffff 100%);
      border-radius: .3125rem;
      .more-con {
        padding-bottom: .4688rem;
      }
    }
    .shandian {
      position: absolute;
      width: 1.3477rem;
      height: 1.8359rem;
      background-image: url('./images/shandian.png');
      background-size: cover;
      right: .8984rem;
      top: -0.5859rem;
    }

    .cont-down-content {
      display: flex;
      align-items: center;
      .day {
        margin-right: .1172rem;
      }
      .number {
        width: .7227rem;
        height: .8398rem;
        line-height: .8398rem;
        background: #000000;
        border-radius: .0781rem;
        font-size: .4688rem;
        font-weight: 600;
        color: #ffffff;
        padding: 0 .0977rem;
        margin: 0 .1172rem;
      }
      .maohao {
        font-size: .4688rem;
        font-weight: 600;
        color: #000000;
        line-height: .6445rem;
      }
    }
    // 双十一普通课样式
    .normal-course-con-s {
      &.normal-course-con {
        padding: 0;
        padding-bottom: 2.3438rem;
      }

      position: relative;
      width: 23.4375rem;
      .top-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 2.1484rem;
        background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
        border-radius: .3125rem .3125rem 0rem 0rem;
        font-size: .4688rem;
        font-weight: 600;
        padding: .3906rem .4688rem 0 .4688rem;

        color: #ffffff;
        .progress-out-con {
          display: flex;
          width: 100%;
          align-items: center;
          .time-span {
            font-size: .3906rem;
            font-weight: 600;
            color: #ffffff;
            line-height: .5469rem;
            width: 2.3438rem;
            margin: .3906rem;
          }
        }
      }
      .btns-con {
        width: 23.4375rem;
        display: flex;
        align-items: center;
        padding: .3125rem .0781rem .0781rem .625rem;
        padding-left: .4688rem;
        flex-wrap: wrap;
      }

      .sticky-fixed {
        width: 23.4375rem;
        .btns-con {
          background: #ffebd3;
        }
      }

      .course-out-con {
        overflow: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: .3906rem .7031rem;
        margin: 0 -0.1953rem -0.625rem -0.1953rem;
        .title-con {
          .title {
            display: inline-block;
            height: .7813rem;
            font-weight: 600;
            color: #fd3659;
            line-height: .7813rem;
            margin-bottom: 0 .3125rem .3125rem .3125rem;
            font-size: .5469rem;
            position: relative;
            &::after,
            &::before {
              content: '';
              display: inline-block;
              width: .7422rem;
              height: .0586rem;
              background: linear-gradient(297deg, rgba(239, 201, 158, 0) 0%, #fe3535 100%, #da9865 100%);
              border-radius: .0391rem;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
            &::before {
              left: -0.9766rem;
            }
            &::after {
              right: -0.9766rem;
            }
            &::before {
              transform: rotate(180deg);
            }
          }
        }
      }
      .bottom-img {
        height: 2.3438rem;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .course-item {
        height: 5.5469rem !important;
      }
      .course-person {
        margin-top: 0 !important;
      }
      .course-info {
        width: 5.2539rem !important;
        height: 2.4023rem !important;
      }
      .home-course-list {
        padding-left: .1563rem;
        padding-right: .1563rem;
      }
    }

    // 全返班样式书写
    .cashback-courses-con {
      height: 14.6484rem;
      background: linear-gradient(180deg, #ffe8cb 0%, #ffffff 100%);
      border-radius: .3125rem;
      position: relative;
      .count-down-con {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.4648rem;
        background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
        border-radius: .3125rem .3125rem 0rem 0rem;
        font-size: .4688rem;
        font-weight: 600;
        color: #ffffff;
      }

      .course-list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        height: 11.8164rem;
        overflow-y: auto;
        margin-top: .5469rem;

        .course-item {
          width: 10.8008rem;
          height: 3.5156rem;
          padding: .2344rem .2344rem .3125rem .2344rem;
          background: #ffffff;
          box-shadow: 0rem .0391rem .0781rem 0rem rgba(0, 0, 0, 0.08);
          margin: .1953rem;
          border-radius: .0781rem;
          display: flex;
          .course-img {
            width: 5.2734rem;
            height: 2.9688rem;
            border-radius: .0781rem;
            margin-right: .2344rem;
            cursor: pointer;
            position: relative;
            flex-shrink: 0;
            .large-img {
              width: 100%;
            }
          }
          .course-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: .1563rem 0;
            .course-title {
              height: .9766rem;
              font-size: .3516rem;
              font-weight: 600;
              color: #363636;
              line-height: .4883rem;
              cursor: pointer;
            }
            .course-price {
              display: flex;
              align-items: baseline;
              height: .7031rem;
              font-size: .5664rem;
              font-weight: 800;
              color: #ec2140;
              line-height: .7031rem;

              .unit {
                font-size: .2734rem;
                font-weight: 600;
                color: #d51230;
                line-height: .3906rem;
              }
              .price-icon {
                width: 2.0898rem;
                height: .4297rem;
                display: inline-block;
                background-image: url('./images/quanfan.png');
                background-size: 100% 99.9%;
                margin-left: .1563rem;
              }
            }
            .course-btns {
              display: inline-block;
              width: 3.9063rem;
              height: .7422rem;
              background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
              border-radius: .4492rem;
              position: relative;
              cursor: pointer;
              .zixun {
                background-image: url('./images/course-zixun.png');
                width: 2.1094rem;
                height: .7813rem;
                background-size: 100% 99.9%;
                margin-left: -0.0586rem;
                margin-top: -0.0195rem;
              }
              .buy-btn {
                display: inline-block;
                width: 1.9531rem;
                height: .7617rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                font-size: .332rem;
                font-weight: 600;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .consume-explain {
      font-size: .2734rem;
      font-weight: 500;
      color: #ffdeb9;
      line-height: .4297rem;
      margin: .5859rem 0;
      margin-bottom: 1.1719rem;
      .bold {
        font-weight: 600;
      }
      .ffc {
        color: #ffc17c;
      }
    }

    .vip-block {
      display: flex;
      justify-content: space-between;
      .left-vip {
        width: 14.4336rem;
        height: 7.5586rem;
        background: linear-gradient(180deg, #ffe8cb 0%, #ffffff 100%);
        border-radius: .3125rem;
        display: flex;
        justify-content: space-between;
        padding: 1.25rem 1.3672rem;
        .activity-vip-course-item {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 5.6641rem;
          height: 100%;

          &:hover {
            transform: translateY(-0.1563rem);
            -webkit-transform: translateY(-0.1563rem);
            -moz-transform: translateY(-0.1563rem);
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
          .picture {
            width: 100%;
            .img {
              border-radius: .0781rem;
              max-width: 100%;
            }
          }
          .course-info {
            display: flex;
            flex-direction: column;
            padding: .4688rem 0;
            .course-title {
              height: .8594rem;
              font-size: .3125rem;
              font-family: PingFangSC-Regular, sans-serif;
              font-weight: 400;
              color: rgba(0, 35, 51, 1);
              line-height: .4297rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .price-con {
              display: flex;
              justify-content: space-between;
              font-size: .3906rem;
              line-height: .3516rem;
              font-weight: 600;
              color: rgba(255, 77, 54, 1);
              vertical-align: bottom;
              margin-right: .0781rem;
              .num {
                font-size: .2344rem;
                color: #999;
              }
            }
          }
        }
      }
      .consult {
        width: 8.6133rem;
        height: 7.5586rem;
      }
    }
    .description-con {
      .description-title {
        width: 100%;
        text-align: center;
        height: .7813rem;
        font-size: .5469rem;
        color: #ffdeb9;
        line-height: .7813rem;
        margin-bottom: .4688rem;
      }
      .description-content {
        font-size: .2734rem;
        color: #ffdeb9;
        line-height: .3906rem;
        max-height: 5.8594rem;
        overflow-y: auto;
        display: block;
      }
    }
    .bottom-con {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      height: 1.7578rem;
      background: linear-gradient(180deg, #fff9e4 0%, #ffd0af 100%);
      box-shadow: 0rem 0rem .293rem 0rem rgba(237, 40, 71, 0.41);
      border-radius: .5859rem .5859rem 0rem 0rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 .5078rem;
      width: 23.4375rem;
      .bottom-item {
        width: 7.1289rem;
        height: 1.1719rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffc75b;
        box-shadow: 0rem 0rem .1758rem 0rem rgba(255, 255, 255, 0.5), inset -0.0586rem -0.2148rem .293rem 0rem rgba(255, 0, 0, 0.45), inset .1563rem .1172rem .4492rem 0rem #fff5e0,
          inset 0rem -0.1172rem .0977rem 0rem rgba(255, 181, 239, 0.38);
        border-radius: .5859rem;
        border: .0391rem solid #fbffde;
        font-size: .4688rem;
        font-weight: 600;
        color: #511700;
        cursor: pointer;
        &.btn2 {
          background: #fd343f;
          box-shadow: 0rem 0rem .1758rem 0rem rgba(255, 255, 255, 0.5), inset -0.0586rem -0.2148rem .293rem 0rem #ff82db, inset .1563rem .1172rem .4492rem 0rem #fff5b9,
            inset 0rem -0.1172rem .0977rem 0rem rgba(255, 181, 239, 0.38);
          border-radius: .5859rem;
          border: .0391rem solid #fbffde;
          color: #ffffff;
        }
      }
    }
  }
}

@keyframes funaniBlink {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 110%;
  }
}
@-webkit-keyframes funaniBlink {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 110%;
  }
}

.activity-fuli-group {
  box-shadow: 0rem .0391rem .1563rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: .1563rem;
  width: 10.7422rem;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 !important;
    .activity-diaolog-content {
      width: 10.7422rem;
      height: 100%;
      border-radius: .1563rem;
      background-color: transparent;
      position: relative;
      flex-shrink: 0;

      .close-icon {
        width: .7813rem;
        height: .7813rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: .1953rem;
        top: .1953rem;
        cursor: pointer;
        .close-icon-img {
          width: .2734rem;
          height: .2734rem;
        }
      }
      .dialog-head-con {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: .6055rem;
        padding-bottom: .7422rem;
        background-image: url(https://tmp.btclass.cn/yunyin/o_1hcrv3tr71rtsus81rgb1q6g9mi.png);
        height: 3.6328rem;
        width: 100%;
        background-size: cover;
        .c-title {
          display: inline-block;
          height: .6445rem;
          font-weight: 600;
          background: linear-gradient(180deg, #fe393c 0%, #cf171e 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: .6445rem;
          margin-bottom: 0 .3125rem .3125rem .3125rem;
          font-size: .6641rem;
          position: relative;
          margin: 0 .1953rem;
          font-family: AlibabaPuHuiTi;
          &::after,
          &::before {
            content: '';
            display: inline-block;
            width: .8984rem;
            height: .0977rem;
            background: linear-gradient(297deg, rgba(239, 201, 158, 0) 0%, #fe3535 100%, #da9865 100%);
            border-radius: .0391rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          &::before {
            left: -1.2695rem;
          }
          &::after {
            right: -1.2695rem;
          }
          &::before {
            transform: rotate(180deg);
          }
        }
        .s-title {
          font-size: .5469rem;
          font-weight: normal;
          color: #363636;
          line-height: .7422rem;
          margin-top: .1563rem;
          font-family: AlibabaPuHuiTiMedium;
        }
      }
      .group-qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: .3125rem;
        color: #363636;
        line-height: .4297rem;
        padding-top: .5273rem;
        padding-bottom: .7813rem;
        background-image: url(https://tmp.btclass.cn/yunyin/o_1hcrv3tr71qtvr4vhbns06hoih.png);
        background-size: 100% 100%;
        margin-top: -0.0293rem;

        .qr-bg {
          width: 5.5469rem;
          height: 5.7617rem;
          background-image: url(https://tmp.btclass.cn/yunyin/o_1hcmo846k1tn5qvmmicek4a0ic.png);
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;
          .qr-img {
            width: 4.2969rem;
            height: 4.2969rem;
            margin-top: .625rem;
          }
          .tips {
            height: .5273rem;
            font-size: .3906rem;
            font-weight: normal;
            color: #c46245;
            line-height: .5273rem;
            margin-top: .1563rem;
          }
          &.qr-bg-two {
            height: 7.0117rem;
            background-image: url(https://tmp.btclass.cn/yunyin/o_1hcmo846k17ev13guf2gtlcpmb.png);
            background-size: cover;
          }
        }
        .tiku-des {
          font-size: .3906rem;
          font-weight: normal;
          color: #b84524;
          line-height: .7227rem;
          margin-top: .1563rem;
        }
        .task-desc {
          font-size: .3906rem;
          font-weight: normal;
          color: #b84524;
          line-height: .7227rem;
          padding: 0 .7813rem;
          .icon {
            width: .5859rem;
            height: .5859rem;
            vertical-align: middle;
            margin-left: .0781rem;
          }
        }
        .f500 {
          font-weight: 500;
          font-family: AlibabaPuHuiTi;
        }
      }
      &.is-free-listen-dialog {
        width: 12.1094rem;
        .dialog-head-con {
          background-image: url('https://app-cdn.btclass.cn/new-prefix/turtle/FhEGxagxDEFoaKjk9ZP-H391X083.png');
        }
        .free-s-title {
          font-size: .4297rem;
          font-family: AlibabaPuHuiTiMedium;
          font-weight: normal;
          color: #363636;
          line-height: .625rem;
          text-align: center;
          margin-top: .1953rem;
          .r-txt {
            color: #f64c33;
          }
        }
        .free-dialog-con {
          .qr-img {
            width: 11.1133rem;
            height: 4.2969rem;
            margin-left: -0.4102rem;
          }
          .download-app-btn {
            width: 7.168rem;
            height: 1.0938rem;
            background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
            border-radius: .6641rem;
            font-size: .4688rem;
            font-weight: 600;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            margin-top: 1.0547rem;
            cursor: pointer;
          }
        }
      }
    }
    .dailog-img-container{
      width: 11.7188rem;
      position: relative;
      .close-hot{
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
}
