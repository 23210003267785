.bt-course-card-metas {
  padding: .3125rem .234375rem .3125rem !important;
}

.bt-course-card-footer {
  margin-top: .253906rem;

  .n-flex-row {
    display: flex;
    align-items: baseline;

    .pc-kanjia-icon {
      align-self: center;
      height: .429688rem;
      margin-bottom: .058594rem;
    }

    .bargain-price-ctx,
    .price-ctx {
      color: #ec2140;
      display: flex;
      align-items: baseline;
      font-family: 'GilroyBold4';

      .coupon-after {
        font-size: .273438rem;
        margin-right: .039063rem;
        line-height: .390625rem;
      }

      .char {
        font-size: .273438rem;
        margin-right: .058594rem;
        line-height: .390625rem;
      }

      .integer-text {
        font-size: .429688rem;
        line-height: .527344rem;
        font-weight: bolder;
      }

      .decimal-text {
        font-size: .273438rem;
        line-height: .332031rem;
        font-weight: bolder;
      }

      .limited-free {
        font-size: .273438rem;
        margin-left: .078125rem;
      }
    }

    .student-num {
      margin-left: auto;
      font-size: .234375rem;
      color: #999999;
      line-height: .527344rem;
    }
  }

  .add-discount {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: .253906rem;

    .student-vip {
      display: inline-block;
      margin-right: .078125rem;


      .student-vip-card {
        position: relative;
        border-radius: .078125rem;
        height: .429688rem;
        display: flex;
        align-items: center;
        border: .019531rem solid red;

        .vip-icon {
          height: 100%;
          display: block;
        }

        .vip-icon-absolute {
          position: absolute;
          top: -0.019531rem;
          left: -0.019531rem;
          bottom: -0.019531rem;
          height: .429688rem;
        }

        .vip-text {
          line-height: normal;
          font-size: .234375rem;
          margin: 0 .097656rem 0 .136719rem;
          white-space: nowrap;
        }
      }
    }
  }

  .limited-time {
    color: #FF6262;
    font-size: .234375rem;
    height: .429688rem;
    padding: 0 .117188rem;
    border: .019531rem solid rgba(255, 98, 98, 0.4);
    border-radius: .058594rem;
    margin-right: .078125rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }

  .coupon-type {
    color: #FF6262;
    font-size: .234375rem;
    height: .429688rem;
    padding: 0 .117188rem;
    border: .019531rem solid rgba(255, 98, 98, 0.4);
    border-radius: .058594rem;
    white-space: nowrap;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}