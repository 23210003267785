































































































.circle-share-popover {
  background: transparent !important;
  text-align: center !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  .share-list {
    width: 110px;
    font-size: 12px;
    color: #595959;
    padding: 18px 16px;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.11);
    background: #ffffff;
    border-radius: 4px;
    .shar-item {
      padding-bottom: 12px;
      border-bottom: 1px solid #efefef;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }

    .weixin-item {
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
    .a-btn {
      &:hover {
        color: #4b8fff;
      }
    }
    .weixin-qrcode {
      img {
        width: 78px;
        height: 78px;
      }
    }
  }
}
