
















































































.hot-item {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  // background: rgba(0, 0, 255, 0.492);
  .hot-con {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 255, 0.492);
    color: #fff;
  }
}
.zhanwei {
  width: 100%;
  height: 1.6rem;
}
.pointer-default {
  cursor: default !important;
  z-index: 1 !important;
}
