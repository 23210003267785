







































































::v-deep {
  .el-dialog {
    border-radius: 8px !important;
  }
  .ask-Item-reward {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0 0 20px 0;
      height: 392px;
    }
  }
}
.header-ctx {
  display: flex;
  justify-content: center;
  padding: 22px 16px 16px;
  position: relative;
  .header-ctx-title {
    font-size: 18px;
    color: #262626;
    font-weight: bolder;
  }
  .header-ctx-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
.contianer-ctx {
  height: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 46px;
  .contianer-ctx-icon {
    flex-shrink: 0;
    height: 112px;
    margin-bottom: 30px;
  }
  .contianer-ctx-text {
    color: #595959;
    font-size: 14px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    .money-icon {
      width: 17px;
      height: 16px;
    }
    .orange-text {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFA934;
    }
  }
}
.i-know-btn {
  width: 200px;
  height: 40px;
  background: #4b8fff;
  border-radius: 20px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}
