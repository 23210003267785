

































































button {
  &.disabled {
    color: #bbbbbb;
    border-color: currentColor;
  }
}
