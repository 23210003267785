











































































































































































































































































































.lesson-item-con {
  display: flex;
  justify-content: center;
  width: 267px;
  height: 90px;
  .lesson-item {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .lesson-item-content {
      display: flex;
      justify-content: center;
      padding: 10px 0;
    }
    .living-tag {
      min-width: 50px;
      height: 16px;
      background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
      border-radius: 2px 1px 2px 1px;
      padding: 0 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      z-index: 2;
      font-size: 10px;
      .icon-lottie {
        margin-right: 2px;
        margin-bottom: 2px;
      }
    }
    .time-tag {
      min-width: 50px;
      font-size: 10px;
      height: 16px;
      padding: 0 2px;
      background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
      border-radius: 2px 1px 2px 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      z-index: 2;
    }
    .teacher-img {
      width: 70px;
      height: 70px;
      flex-shrink: 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .lesson-info {
      display: flex;
      flex-direction: column;
      padding: 0px 8px 0 4px;
      width: 100%;
      .lesson-title {
        display: inline-block;
        max-width: 192px;
        font-size: 14px;
        color: #363636;
        line-height: 20px;
        font-weight: 600;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        margin-bottom: 4px;
      }

      .teacher-info {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #5a5a5a;
        height: 17px;
        margin-bottom: 12px;
        .teacher-name {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
        }
        .teacher-tag {
          font-size: 10px;
          display: inline-block;
          padding: 0 4px;
          height: 14px;
          line-height: 14px;
          background: #ffe3df;
          border-radius: 3px 3px 3px 0px;
          color: #ff5353;
          margin-left: 4px;
          margin-top: -2px;
        }
      }
      .num-con {
        font-size: 12px;
        font-weight: 400;
        color: #8e8e93;
        line-height: 17px;
        display: flex;
        align-items: center;
        .avators {
          display: inline-block;
          padding-left: 8px;
          margin-right: 4px;
          .avator-item {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            margin-left: -7px;
          }
        }
      }
      .s-btn {
        min-width: 60px;
        height: 23px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 19px;
        color: #ffffff;
        background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
        position: absolute;
        bottom: 10px;
        right: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &.is-living {
          background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
        }
        &.is-finished {
          background: #eeeeee;
          color: #bbbbbb;
        }
        &.is-reserved {
          background: #fff;
          color: #4b8fff;
          border: 1px solid #4b8fff;
        }
      }
    }
  }
}
