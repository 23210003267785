



































































































































.card-notes-item {
  background: #ffffff;
  cursor: pointer;
  .c-top {
    display: flex;
    margin-bottom: 10px;
    .c-info-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .c-info-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .c-info-name-1 {
        font-size: 14px;
        font-weight: bolder;
        color: #313131;
        line-height: 20px;
      }
      .c-info-name-2 {
        font-size: 12px;
        color: #919191;
        line-height: 17px;
      }
    }
    .c-info-btn {
      height: 25px;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-left: 12px;
      cursor: pointer;
      color: #919191;
      &:hover {
        color: #4b8fff !important;
      }
      .edit-btn-icon {
        width: 11px;
        height: 12px;
        display: block;
      }
      .edit-btn-text {
        line-height: 20px;
        margin-left: 4px;
      }
      .note-status {
        width: 20px;
        height: 20px;
        margin-right: -2px;
      }
    }
    .no-btn {
      cursor: default !important;
      &:hover {
        color: #919191 !important;
      }
    }
  }
  .c-content {
    font-size: 16px;
    color: #363636;
    line-height: 24px;
    font-weight: bolder;
    margin-bottom: 12px;
    white-space: pre-wrap;
  }
  .c-question {
    background: #f7f8f9;
    border-radius: 4px;
    height: 33px;
    width: 100%;
    display: flex;
    align-items: center;
    .c-q-icon {
      margin-left: 6px;
      width: 16px;
      height: 16px;
      background: url('~@/assets/images/learning-center/question-icon.png');
      background-size: 16px 16px;
      transform: translateY(-1px);
    }
    .c-q-text {
      margin-left: 3px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      color: #5a5a5a;
      padding-right: 16px;
    }
    &:hover {
      .c-q-icon {
        background: url('~@/assets/images/learning-center/question-icon-active.png');
        background-size: 16px 16px;
      }
      .c-q-text {
        color: #4b8fff;
      }
    }
  }
}
.m-l-auto {
  margin-left: auto !important;
}
