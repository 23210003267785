





































































































::v-deep {
  .choose-coupon {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0 0 20px 0;
    }
    .choose-coupon-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 32px;
      .back-ctx {
        display: flex;
        align-items: center;
        color: #595959;
        font-size: 16px;
        cursor: pointer;
      }
      .dialog-title {
        color: #313131;
        font-size: 18px;
        font-weight: bolder;
      }
      .submit-btn {
        width: 91px;
        height: 32px;
        border-radius: 4px;
        color: #ffffff;
        background: #4b8fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .choose-coupon-ctx {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0 20px 24px;
      height: 590px;
      overflow: hidden;
      overflow-y: auto;
      .choose-coupon-item {
        margin: 0 8px 8px;
      }
    }
  }
}
