














































































































































.live-lesson-con-activity {
  height: (637/51.2) + rem !important;
  padding-bottom: (32/51.2) + rem !important;
  &.no-cur-lesson {
    height: (254/51.2) + rem !important;
  }
}
