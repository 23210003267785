















































.dialog-code {
  width: 10.826772rem !important;
  height: 10.354331rem !important;
  background: transparent !important;
  box-shadow: none !important;
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog__body {
    padding: 0;
    background: url('https://app-cdn.btclass.cn/new-prefix/turtle/FqkNgf10KtRICTo1w4SqOVk7GY6Q.png');
    background-size: 100% 100%;
    .top-ctx {
      height: 3.208661rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 0.610236rem;
      position: relative;
      .close-btn {
        position: absolute;
        right: 0.47244rem;
        top: 0.47244rem;
        width: 0.27559rem;
        height: 0.27559rem;
        cursor: pointer;
      }
      .title-url {
        height: 0.925197rem;
        margin-bottom: 0.15748rem;
      }
      .title-text {
        display: inline-block;
        font-weight: 600;
        background: linear-gradient(180deg, #fe393c 0%, #cf171e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0 0.3125rem 0.3125rem 0.3125rem;
        font-size: 0.6641rem;
        line-height: 0.925197rem;
        position: relative;
        margin: 0 0.1953rem;
        margin-bottom: 0.19685rem;
        font-family: AlibabaPuHuiTi;
        &::after,
        &::before {
          content: '';
          display: inline-block;
          width: 0.8984rem;
          height: 0.0977rem;
          background: linear-gradient(297deg, rgba(239, 201, 158, 0) 0%, #fe3535 100%, #da9865 100%);
          border-radius: 0.0391rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &::before {
          left: -1.2695rem;
        }

        &::after {
          right: -1.2695rem;
        }

        &::before {
          transform: rotate(180deg);
        }
      }
      .subtitle {
        max-width: 8.307087rem;
        font-size: 0.472441rem;
        color: #363636;
        font-weight: bolder;
        line-height: 0.649606rem;
        text-align: center;
      }
    }
    .bottom-ctx {
      height: 7.125984rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .code-box {
        width: 5.590551rem;
        height: 5.807087rem;
        background: url(https://app-cdn.btclass.cn/new-prefix/turtle/Fvq99QJLq5gEaYencMbcbLvHPY6t.png);
        background-size: 100%;
        padding: 0.669291rem 0.669291rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .code-img {
          width: 4.291339rem;
          height: 4.291339rem;
        }
        .code-tip {
          font-size: 0.393701rem;
          line-height: 0.531496rem;
          color: #c46245;
          font-weight: bolder;
          margin-top: 0.15748rem;
        }
      }
    }
  }
}
