



















































































.tabs-page-num-box {
  height: 100%;
  position: relative;
  padding: 20px 40px 0;
  .page-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -50px;
    .page-item {
      width: 95px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #efefef;
      border-radius: 4px;
      font-size: 14px;
      color: #5a5a5a;
      margin-bottom: 16px;
      margin-right: 16px;
      cursor: pointer;
      &:hover {
        background: rgba(75, 143, 255, 0.1);
        color: #5A5A5A;
      }
    }
    .opacity-item {
      opacity: 0;
    }
    .is-active {
      background: #4b8fff !important;
      color: #ffffff !important;
    }
  }
}
.no-result {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #8e8e93;
}
