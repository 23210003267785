








































































































.ask-upload-img {
  display: flex;
  align-items: center;
  .img-item {
    width: 105px;
    height: 105px;
    position: relative;
    margin-right: 8px;
    &:hover {
      .img-close {
        display: block !important;
      }
    }
    .img-item-ctx {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .img-close {
      display: none;
      position: absolute;
      right: -5px;
      top: -5px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .fun-tools-child {
    width: 105px;
    height: 105px;
    background: #F7F8F9;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .fun-size-icon {
      width: 24px;
      height: 24px;
      margin-bottom: 8px;
    }
    .add-text {
      color: #919191;
      font-size: 12px;
    }
  }
}
