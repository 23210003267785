
























































































































.choose-circle-container {
  width: 816px;
  height: 658px;
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  .top-con {
    height: 78px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px 0 50px;
    position: relative;
    .back {
      cursor: pointer;
      font-size: 24px;
      font-weight: 600;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      color: #313131;
      line-height: 28px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .sure-btn {
      width: 68px;
      height: 32px;
      background: #4b8fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 24px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }
    .right-operate {
      display: flex;
      align-items: center;
      .primary-span {
        color: #4b8fff;
        margin-left: 5px;
      }
    }
  }
  .circle-list {
    height: 540px;
    overflow-y: auto;
    padding: 0 60px;
    .circle-item {
      display: flex;
      margin-bottom: 15px;
      border-bottom: 1px solid #efefef;
      padding: 17px 0;
      .logo {
        width: 64px;
        height: 64px;
        border-radius: 25px;
        margin-right: 16px;
        cursor: pointer;
      }
      .right {
        display: flex;
        align-items: center;
        .info-con {
          display: flex;
          flex-direction: column;
          width: 544px;
          .circle-title {
            font-size: 16px;
            font-weight: 600;
            color: #313131;
            line-height: 22px;
            margin-bottom: 8px;
            cursor: pointer;
          }
          .detail-num-info {
            font-size: 14px;
            font-weight: 400;
            color: #313131;
            line-height: 20px;
            display: flex;
            align-items: center;
            .num-info-item {
              margin-right: 16px;
              .info-label {
                color: #919191;
              }
            }
          }
          .intro {
            font-size: 14px;
            font-weight: 400;
            color: #919191;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .chose-btn {
          width: 56px;
          height: 29px;
          border-radius: 15px;
          border: 1px solid #4b8fff;
          font-weight: 600;
          font-size: 12px;
          color: #4b8fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.is-disabled {
            opacity: 0.5;
          }
          &.is-active {
            background: #4b8fff;
            border: none;
            color: #ffffff;
            opacity: 1 !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-height: 700px) {
  .choose-circle-container {
    transform: scale(0.8);
  }
}
