




















































.live-tabs-list {
  .btns-con {
    width: 23.4375rem;
    display: flex;
    align-items: center;
    padding: 0.3125rem 0.0781rem 0.0781rem 0.625rem;
    padding-left: 0.4688rem;
    flex-wrap: wrap;
    margin-bottom: calc(32 / 51.2) + rem;
  }
}
