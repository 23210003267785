
































































































































































































































::v-deep {
  .notes-drawer {
    overflow: inherit !important;
    .notes-drawer-content {
      width: 100%;
      height: 100vh !important;
      position: relative;
      display: flex;
      flex-direction: column;
      .ask-drawer-close {
        width: 14px;
        height: 82px;
        position: absolute;
        left: -14px;
        top: 52px;
        cursor: pointer;
        .ask-drawer-close-icon {
          width: 100%;
          height: 100%;
        }
      }
      .content-top {
        padding: 20px 20px 0 20px;
        .content-top-ctx {
          background: #f7f7fc;
          display: flex;
          align-items: center;
          padding: 0 20px;
          .bt-notes-tabs {
            height: 56px;
            .tab-pane-title {
              display: flex;
              align-items: center;
              .c-icon {
                width: 16px;
                height: 16px;
                margin-left: 4px;
              }
            }
            .f-w-bolder {
              font-weight: bolder;
            }
            .el-tabs__header {
              margin-bottom: 0px;
            }
            .el-tabs__nav {
              height: 56px;
            }
            .el-tabs__nav-wrap::after {
              background-color: transparent;
            }
            .el-tabs__item {
              height: 56px;
              line-height: 56px;
              font-size: 18px;
              font-weight: inherit;
              color: #595959;
              box-shadow: none !important;
            }
            .el-tabs__item.is-active {
              color: #4b8fff;
            }
            .el-tabs__active-bar {
              height: 4px !important;
              background-color: transparent !important;
              &::after {
                content: '';
                position: absolute;
                width: 40px;
                top: 0;
                bottom: 0;
                left: calc(50% - 20px);
                border-radius: 4px;
                background: #4b8fff;
              }
            }
          }
          .export-btn {
            height: 30px;
            padding: 0 14px;
            color: #919191;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #919191;
            border-radius: 20px;
            margin-left: auto;
            cursor: pointer;
          }
          .add-btn {
            height: 30px;
            padding: 0 14px;
            color: #ffffff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #4b8fff;
            border-radius: 20px;
            background: #4b8fff;
            margin-left: 16px;
            cursor: pointer;
            .add-btn-icon {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
      }
      .content-middle {
        height: 0;
        flex: 1;
        overflow: hidden;
        position: relative;
        padding-bottom: 30px;
        .content-box {
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
        }
        .no-list {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .no-list-icon {
            width: 100px;
            height: 105px;
            margin-bottom: 23px;
          }
          .no-list-text-1 {
            color: #5a5a5a;
            font-size: 16px;
            margin-bottom: 2px;
          }
          .no-list-text {
            color: #5a5a5a;
            font-size: 16px;
            margin-bottom: 24px;
          }
          .no-list-btn {
            width: 300px;
            height: 40px;
            background: #4b8fff;
            border-radius: 20px;
            font-size: 16px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .notes-list {
          // height: 100%;
          padding: 20px 48px 0;
          .notes-list-item {
            // border: 1px solid red;
            .middle-line {
              height: 10px;
              width: calc(100% + 96px);
              background: #f7f8f9;
              margin: 20px -48px;
            }
          }
        }
      }
      .content-bottom {
        flex-shrink: 0;
        .el-pagination {
          margin: 20px 0 !important;
        }
      }
    }
  }
}
