



















































































.wechat-login {
  p {
    font-size: 12px;
  }
  .qrcode-img {
    width: 220px;
    height: 220px;
  }
  .error-tip {
    color: #f56c6c;
    text-align: left;
    margin: 9px 18px 0;
  }
  .qrcode-tip {
    display: flex;
    align-items: center;
    color: #aeaeae;
    margin: 0 0 14px 18px;
    text-align: left;
    i {
      font-size: 16px;
      margin-right: 3px;
    }
  }
  .loading-img {
    margin-top: 35px;
    width: 256px;
  }
  .loading-tips {
    color: #bbbbbb;
    height: 18px;
    line-height: 18px;
    margin: 22px 0 32px;
  }
}
