



































































































































































::v-deep {
  .ask-drawer {
    overflow: inherit !important;
    .ask-drawer-content {
      width: 100%;
      height: 100vh !important;
      position: relative;
      display: flex;
      flex-direction: column;
      .ask-drawer-close {
        width: 14px;
        height: 82px;
        position: absolute;
        left: -14px;
        top: 52px;
        cursor: pointer;
        .ask-drawer-close-icon {
          width: 100%;
          height: 100%;
        }
      }
      .search-header {
        flex-shrink: 0;
        padding: 20px;
        .search-header-ctx {
          height: 56px;
          width: 100%;
          background: #f7f7fc;
          border-radius: 0px 0px 2px 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 28px;
          .header-title {
            color: #363636;
            font-size: 18px;
            font-weight: bolder;
          }
          .header-btn {
            width: 92px;
            height: 32px;
            border-radius: 20px;
            background: #4b8fff;
            color: #ffffff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .header-btn-icon {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
      }
      .search-list {
        flex: 1;
        height: 0;
        overflow: hidden;
        overflow-y: auto;
        padding-bottom: 30px;
        position: relative;
        .no-list {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .no-list-icon {
            width: 100px;
            height: 105px;
            margin-bottom: 23px;
          }
          .no-list-text {
            color: #5a5a5a;
            font-size: 16px;
            margin-bottom: 40px;
          }
          .no-list-btn {
            width: 300px;
            height: 40px;
            background: #4b8fff;
            border-radius: 20px;
            font-size: 16px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .ask-list {
          // height: 100%;
          padding: 0 48px;
          .ask-list-item {
            // border: 1px solid red;
            .middle-line {
              height: 10px;
              width: calc(100% + 96px);
              background: #f7f8f9;
              margin: 20px -48px;
            }
          }
        }
      }
      .search-footer {
        flex-shrink: 0;
        .el-pagination {
          margin: 20px 0 !important;
        }
      }
    }
  }
}
