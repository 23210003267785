













































































































































































































































































.circle-home-container {
  display: flex;
  flex-direction: column;
  .circle-out-con {
    display: grid;
    grid-template-columns: 195px 660px 300px;
    grid-auto-rows: min-content;
    grid-column-gap: 10px;

    .circle-content {
      border-radius: 8px;
      margin-bottom: 24px;
      .carousel-con {
        height: 120px;
        border: 8px;
        margin-bottom: 10px;
      }
      .recommend-circles {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: 289px;
        padding: 24px;
        margin-bottom: 10px;
        border-radius: 8px;
        .recommend-title-con {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .recommend-title {
            font-size: 18px;
            font-weight: 600;
            color: #363636;
            line-height: 28px;
          }
          .more-circle-span {
            font-size: 14px;
            font-weight: 400;
            color: #919191;
            line-height: 20px;
            cursor: pointer;
          }
        }
        .circle-cards-con {
          display: grid;
          grid-template-columns: repeat(4, 144px);
          grid-column-gap: 12px;
          margin: 16px auto;
        }
      }
      .no-dynamic-con {
        background-color: #fff;
        width: 100%;
        height: 437px;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .no-img {
          width: 87px;
          height: 102px;
          margin-bottom: 23px;
        }
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        line-height: 22px;
      }
    }
    .channel-nav {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
    .right-side {
      margin-bottom: 24px;
    }
    // .right-side {
    //   position: -webkit-sticky;
    //   position: sticky;
    //   top: 0;
    // }
  }
}
.error-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 898px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #595959;
  line-height: 22px;
  text-align: center;
  margin-bottom: 24px;
  .no-img {
    width: 166px;
    height: 111px;
    margin-bottom: 24px;
  }
  .go-circle-home {
    width: 300px;
    height: 40px;
    background: #4b8fff;
    border-radius: 20px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
  }
}
