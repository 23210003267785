


































































// 侧边栏样式
.act-aside-con {
  .page-aside-menu {
    position: fixed;
    left: 1.0742rem;
    z-index: 10;
    width: 2.2656rem;
    box-shadow: 0rem 0rem 0.1953rem 0rem rgba(0, 0, 0, 0.05);
    top: 5.4688rem;
    background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
    border-radius: 0.0781rem;
    overflow: visible !important;
    padding: 0.0781rem 0;

    .seven-th {
      display: block;
      width: 2.2656rem;
      height: 1.3281rem;
      position: absolute;
      top: -1.5234rem;
    }

    .el-scrollbar__wrap {
      overflow: visible;
      margin: 0 !important;
    }
    .menu-content {
      background: #fff1df;
      border-radius: 0.0781rem;
      padding: 0.1953rem 0;
      box-shadow: 0rem 0.0781rem 0.3125rem 0rem rgba(0, 0, 0, 0.1);
    }
    .menus {
      width: 2.1094rem;
      text-align: center;
      margin: 0 auto;
      a {
        display: block;
        width: 100%;

        height: 0.625rem;
        font-size: 0.2734rem;
        font-weight: 400;
        color: #c7102c;
        line-height: 0.625rem;

        &:hover,
        &.active {
          // color: #fff;
          background: #f5cc99;
        }
      }
    }
  }
  ::v-deep .el-scrollbar__wrap {
    overflow: visible;
    margin: 0 !important;
  }
}
