













































































.more-teacer-container {
  width: 100%;
  background-color: #fff;
  .content {
    width: 22.6563rem;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 0.9766rem;
  }
  .page-title {
    font-size: 0.3906rem;
    // font-weight: 600;
    height: 1.5625rem;
    line-height: 1.5625rem;
  }
  .teacher-list {
    min-height: 9.7656rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 设置列宽度 */
    grid-gap: 0.3906rem;
    .teacher-item {
      position: relative;
      text-align: center;
      z-index: 1;
      border-radius: 0.0781rem;
      border: 0.0195rem solid #e4ecf3;
      overflow: hidden;
      background-color: #fff;
      &:hover {
        transform: translateY(-0.1172rem);
        box-shadow: 0 0.5078rem 0.7813rem -0.4688rem rgba(0, 36, 100, 0.3);
        transition: all 0.3s ease;
      }
      .teacher-top {
        position: relative;
        z-index: 1;
        border-top-left-radius: 0.0781rem;
        border-top-right-radius: 0.0781rem;
        background-color: #f5f8fa;
        padding: 0.3906rem 0.3125rem;
        .teacher-img {
          .avatar-lg {
            border-radius: 50%;
            width: 2.3438rem;
            height: 2.3438rem;
            border: 0.0781rem solid #fff;
            display: inline-block;
          }
        }
        .item-title {
          height: 0.4297rem;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
        }
        .item-position {
          color: #c1c1c1;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
        }
      }
      .teacher-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.3125rem;
        height: 1.7578rem;
        .item-about {
          line-height: 0.4883rem;
          color: #919191;
          opacity: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      h3 {
        margin-top: 0.3906rem;
        margin-bottom: 0.1953rem;
        font-size: 0.3516rem;
      }
    }
  }
}
