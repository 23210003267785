


















































































































































.input-label {
  width: 25%;
  float: left;
  line-height: 34px;
}
.input-wrap {
  width: 75%;
  float: left;
}
.sms-code-wrap {
  width: 40%;
  float: left;
}
.send-smscode-btn {
  width: 30%;
  float: right;
  height: 32px;
}

.is-hidden {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
.phone-number-wrap {
  font-size: 14px;

  a {
    display: inline-block;
    margin-left: 10px;
  }
}
.submit-btn {
  display: inline-block;
  outline: none;
  padding: 8px 52px;
  background-color: #4b8fff;
  color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  width: 85%;
  margin-top: 15px;

  &.disabled {
    background-color: #bbbbbb;
  }
}
.send-smscode-btn {
  outline: none;
  background-color: white;
  font-size: 12px;
  color: #4b8fff;
  border: 1px solid #4b8fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -webkit-border-radius: 4px;
}
