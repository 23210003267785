





































































































































































































































































































.dynamic-comment-container {
  .in-comment-con {
    display: flex;
    align-items: flex-start;
    padding-bottom: 24px;
    width: 100%;
    .avator {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 8px;
      margin-top: -4px;
      flex-shrink: 0;
      cursor: pointer;
    }
    ::v-deep {
      .el-input__inner {
        border: none;
        background: #f7f8f9;
        border-radius: 4px;
      }
    }
    .comment-btn {
      margin-left: 16px;
      &:hover {
        background-color: #4b8fff;
      }
      &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .con-title {
    margin-bottom: 16px;
    font-weight: 600;
    color: #313131;
    font-size: 16px;
  }
  .all-remment-con {
    .no-comment {
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #8e8e93;
      line-height: 20px;
    }
  }

  .more-btn {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #bbbbbb;
    line-height: 20px;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.pointer {
      cursor: pointer;
      color: #4b8fff;
    }
  }
  ::v-deep {
    .el-textarea__inner {
      background: #f7f8f9;
      border-radius: 4px;
      border: none;
      &::placeholder {
        color: #bbbbbb;
      }
    }
  }

  .is-last {
    &::after {
      display: none;
    }
  }
}
