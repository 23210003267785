




























































.circle-start-dialog-container {
  margin-top: 0 !important;
  width: 732px !important;
  margin-bottom: 0 !important;
  border-radius: 16px !important;

  .el-dialog__header {
    display: none;
  }
  .el-dialog__footer {
    height: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    border-radius: 16px !important;
    background-color: transparent !important;
  }
  .content {
    .head-con {
      text-align: center;
      padding-top: 24px;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #313131;
        line-height: 28px;
      }
      .tip {
        font-size: 16px;
        font-weight: 400;
        color: #616161;
        line-height: 22px;
        margin-top: 12px;
      }
    }
    .circle-list {
      height: 472px;
      overflow-y: auto;
      display: grid;
      grid-template-columns: repeat(4, 144px);
      grid-gap: 16px 16px;
      margin-top: 20px;
      padding-left: 7px;
      padding: 7px 0 10px 55px;
    }
  }

  .sure-btn {
    width: 400px;
    height: 48px;
    background: #4b8fff;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    &.is-disable {
      opacity: 0.49;
      pointer-events: none;
    }
  }
}
.circle-start-dialog-out-con {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media only screen and (max-height: 700px) {
  .circle-start-dialog-out-con {
    overflow: hidden !important;
    display: flex;
    .circle-start-dialog-container {
      transform: scale(0.7);
    }
  }
}
