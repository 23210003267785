






























































span.color-8a9499 {
  color: #8a9499 !important;
  span,
  a {
    color: #8a9499 !important;
  }

  a:hover {
    color: #1e73ff !important;
  }
}

span.default-style {
  &:hover {
    color: #313131 !important;
  }
}
