


















































































































































































































































































































@import './CourseList.scss';

.bt-course-card-footer {
  // border: 1px solid red;
}

.icon-person {
  margin-right: 2px;
  color: #8a9499;
  opacity: 0.6;
}

.teacher {
  height: 16px;
  line-height: 16px;
}

.learn-status {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #ff5151;
  top: 4px;
  right: 4px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0 4px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  border-radius: 2px;
  .lottie {
    margin-right: 2px;
    margin-bottom: 1px;
  }
}
.tag-new {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  width: 41px;
  height: auto;
}
.course-person {
  border: 1px solid red;
}
.vip-icon-absolute {
  top: -1px !important;
  left:  -1px !important;
  bottom:  -1px !important;
  height: calc(100% + 2px) !important;
  img {
    height: 100%;
    display: block;
  }
}
.course-img-live {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  .seq {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 6px;
    height: 18px;
    background: rgba(0, 46, 122, 0.55);
    border-radius: .078125rem 0px .078125rem 0px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    &.is-explaining {
      background: linear-gradient(314deg, rgba(255, 155, 155, 0.58) 0%, #ff6262 100%);
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .hot-num {
    padding-top: 10px;
    background-image: url('https://tmp.btclass.cn/yunyin/o_1hajri0mp1gim9sr1a5e10os14g2a.png');
    background-size: cover;
    width: 100%;
    height: 49px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    font-family: JinBuTi;
    .dot {
      font-size: 14px;
    }
    ::v-deep {
      .ani-content {
        font-size: 18px;
      }
    }
    img {
      width: 72px;
      height: 30px;
      margin-right: 3px;
    }
  }
}
