















































































.site-page {
  position: relative;
  padding-bottom: 96px;
}
.site-con {
  background-color: #fff;
  overflow: hidden;
  padding: 0 32px 24px 32px;
  border-radius: 4px;
  border: 1px solid #e4ecf3;
}
.site-item {
  overflow: hidden;
}
.flex-wrap-start {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.site-link {
  margin-right: 14px;
  margin-top: 16px;
  line-height: 20px;
  font-weight: 400;
}
.sub-title {
  margin-top: 12px;
  display: block;
  color: #3e3e3e;
  font-weight: 500;
}
.site-title {
  font-size: 18px;
  font-weight: 500;
  color: #3e3e3e;
  line-height: 32px;
  position: relative;
  padding-left: 16px;
  margin-top: 24px;
  display: block;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    height: 18px;
    width: 4px;
    background: #019eed;
  }
}
a {
  color: #595959;
  &:hover {
    color: #019eed;
  }
}
.is-link {
  color: #3b639f;
  position: relative;
}
.is-link + .is-link {
  padding-left: 14px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 1px;
    height: 14px;
    background-color: #cecece;
  }
}
.site-footer {
  padding: 10px 0;
  text-align: center;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: #8c8c8c;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  p {
    line-height: 20px;
  }
  .inline-block-box {
    display: inline-flex;
    color: #8c8c8c;
    img {
      margin-right: 2px;
      height: 20px;
      width: 20px;
    }
  }
}
