




























































































































































































































































































.icon-lottie {
  margin: 0 2px;
  position: relative;
  ::v-deepsvg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
.lesson-item-content {
  cursor: pointer;
}

.detail-btn-mini {
  bottom: calc(8 / 51.2) + rem !important;
}
.card-teacher-name {
  display: inline-block;
  max-width: calc(116 / 51.2) + rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-teacher-name-mini {
  display: inline-block;
  max-width: calc(80 / 51.2) + rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.s-btn {
  width: 86px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 19px;
  color: #ffffff;
  background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
  border: none !important;
  &.is-living {
    background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%) !important;
  }
  &.is-finished {
    background: #eeeeee !important;
    color: #bbbbbb !important;
  }
  &.is-reserved {
    background: #fff !important;
    color: #4b8fff !important;
    border: 1px solid #4b8fff !important;
  }
}
