
































































































































































































































































































.lesson-item-con {
  display: flex;
  justify-content: center;
  width: 8.3398rem;
  height: 2.8516rem;

  .detail-btn {
    position: absolute;
    bottom: .3711rem;
    right: .3711rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .num-con {
    font-size: .3906rem;
    font-weight: 400;
    color: #511700;
    line-height: .5469rem;
    display: flex;
    align-items: center;

    .avators {
      display: inline-block;
      padding-left: .2344rem;

      .avator-item {
        width: .4297rem;
        height: .4297rem;
        border-radius: 50%;
        margin-left: -0.2344rem;
      }
    }


    .icon {
      font-size: .2344rem;
    }

    .num {
      opacity: 0.4;
      margin-left: .0391rem;
      flex-shrink: 0;
    }
  }

  .teacher-info {
    display: flex;
    align-items: center;

    .teacher-tag {
      display: inline-block;
      padding: 0 .1563rem;
      height: .6836rem;
      line-height: .6836rem;
      background: #ffe3df;
      border-radius: .1563rem .1563rem .1563rem 0rem;
      font-size: .3906rem;
      color: #ff5353;
      line-height: .6836rem;
      margin-left: .2734rem;
    }
  }

  .lesson-item-content {
    display: flex;
    justify-content: center;
  }

  .lesson-item {
    width: 100%;
    height: 100%;
    background-image: url(https://tmp.btclass.cn/yunyin/o_1hcm0cu2e1g6dol31n1i12l815c6a.png);
    background-size: 100% 99.9%;
    position: relative;
    display: flex;
    align-items: center;

    .lesson-item-content {
      padding: .2344rem;
    }

    .living-tag {
      width: 1.25rem;
      height: .3906rem;
      background: linear-gradient(90deg, #fb494a 0%, #fb494a 100%);
      border-radius: .1172rem 0rem .1172rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      z-index: 2;
      font-size: .2734rem;

      .lottie {
        width: .1758rem;
        height: .1953rem;
      }
    }

    .time-tag {
      width: 1.7578rem;
      font-size: .2734rem;
      height: .3906rem;
      background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
      border-radius: .1172rem 0rem .1172rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      z-index: 2;
    }

    .teacher-img {
      width: 2.207rem;
      height: 2.207rem;
      flex-shrink: 0;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .lesson-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0rem .0781rem 0 .1758rem;
      width: 100%;

      .lesson-title {
        display: inline-block;
        max-width: 4.6484rem;
        font-size: .3516rem;
        font-weight: 600;
        color: #363636;
        line-height: .4883rem;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        margin-bottom: .3125rem;
      }

      .teacher-info {
        font-size: .2734rem;
        color: #5a5a5a;
        line-height: .3906rem;

        .teacher-tag {
          font-size: .2344rem;
          height: .3711rem;
          line-height: .3711rem;
          background: #ffefed;
          border-radius: .0781rem .0781rem .0781rem 0rem;
          padding: 0 .0586rem;
          margin-left: .1758rem;
        }
      }

      .time-span {
        font-size: .2734rem;
        color: #4e9afb;
        line-height: .3516rem;
      }

      .num-con {
        font-size: .2344rem;
        line-height: .3125rem;

        .icon {
          font-size: .2344rem;
        }
      }

      .s-btn {
        width: 1.6797rem;
        height: .625rem;
        line-height: .625rem;
        font-size: .3125rem;
        font-weight: 600;
        border-radius: .3711rem;
        color: #ffffff;
        background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);

        &.is-living {
          background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
        }

        &.is-finished {
          background: #eeeeee;
          color: #bbbbbb;
        }

        &.is-reserved {
          background: #fff;
          color: #4b8fff;
          border: .0195rem solid #4b8fff;
        }
      }
    }
  }
}
