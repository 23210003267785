





































.page-container {
  margin: 30px auto;
  width: 95%;
  max-width: 1100px;
  border-radius: 4px;
  border: 1px solid #e4ecf3;
  background-color: #fff;
  .page-title {
    border-bottom: 1px solid #f5f5f5;
    height: 60px;
    line-height: 60px;
    padding-left: 20px;
    margin: 0;
  }
  .page-content {
    padding: 15px;
  }
}
.err-area {
  width: 100%;
  min-height: calc(100vh - 400px) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
