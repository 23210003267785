































































































.recommend-ask {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 16px 1px 16px;
  position: relative;
  .header-ctx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .header-title {
      color: #313131;
      font-size: 16px;
      font-weight: bolder;
    }
    .header-more {
      color: #919191;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .list-ctx {
    .list-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      cursor: pointer;
      .left-index {
        flex-shrink: 0;
        font-size: 14px;
        line-height: 17px;
        padding-top: 2px;
        padding-right: 7px;
        color: #616161;
      }
      .right-ctx {
        flex: 1;
        width: 0;
        .right-ctx-title {
          color: #313131;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .right-ctx-time {
          display: flex;
          align-items: center;
          height: 17px;
          line-height: 17px;
          .time {
            color: #919191;
            font-size: 12px;
            margin-right: 14px;
          }
          .comments-ctx {
            font-size: 12px;
            color: #919191;
            display: flex;
            align-items: center;
            .icon-ctx {
              height: 100%;
              display: flex;
              align-items: center;
              margin-right: 2px;
              margin-top: -2px;
              img {
                width: 13px;
                height: 13px;
              }
            }
            .num-text {
            }
          }
        }
      }
    }
    .list-item:nth-child(1) {
      .left-index {
        color: #b93030;
      }
    }
    .list-item:nth-child(2) {
      .left-index {
        color: #f15455;
      }
    }
    .list-item:nth-child(3) {
      .left-index {
        color: #ffa117;
      }
    }
  }
}
