






















































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__nav-scroll {
    padding-left: 40px;
    // display: flex;
    // justify-content: center;
  }
  .el-tabs__item {
    height: 54px;
    line-height: 54px;
    font-size: 16px;
    color: #969696;
    padding: 0 40px;
  }
  .el-tabs__item:hover,
  .el-tabs__active-bar,
  .el-tabs__item.is-active {
    color: #4b8fff;
  }
  .el-tabs__item.is-active {
    font-weight: 600 !important;
  }
  .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #e5e5e5;
  }
}
.book-errata-detail-container {
  min-height: 100vh;
  width: 100%;
  min-width: 1175px;
  padding: 0 40px;
  // min-width: 1175px;
  // max-width: 1440px;
  // margin: 30px auto;
  // display: flex;
  // position: relative;
  // border: 1px solid red;
  .book-errata-detail-ctx {
    min-height: 100vh;
    width: 100%;
    max-width: 1440px;
    margin: 30px auto;
    display: flex;
    position: relative;
    // border: 1px solid red;
  }
  .book-close {
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .book-xiajia-icon {
      width: 113px;
      margin-bottom: 24px;
      display: block;
    }
    .book-xiajia-text {
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      margin-bottom: 24px;
    }
    .book-xiajia-btn {
      width: 300px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #4b8fff;
      border-radius: 20px;
      color: #ffffff;
      font-size: 16px;
    }
  }
  .container-left {
    height: 100%;
    display: flex;
    .menu-1 {
      flex-shrink: 0;
      width: 397px;
      margin-right: 10px;
      background: #ffffff;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      .menu-book-title {
        padding: 23px 40px 11px;
        display: flex;
        align-items: center;
        .back-icon {
          width: 25px;
          min-width: 25px;
          display: block;
          margin-right: 4px;
          cursor: pointer;
        }
        .book-title {
          width: 0;
          flex: 1;
          color: #333333;
          font-size: 18px;
          font-weight: bolder;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: default;
        }
      }
      /*修改滚动条样式*/
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 2px;
        background: #efefef !important;
        margin-right: 4px !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #c7c7c7;
        border-radius: 2px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #c7c7c7;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
      .tabs-content {
        margin-top: 1px;
        height: 0;
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
      }
    }
    .menu-2 {
      flex-shrink: 0;
      width: 0px;
      background: #ffffff;
      transition: all 0.2s;
      border-radius: 6px 0 0 6px;
    }
    .show-menu-2 {
      width: 135px;
    }
  }
  .container-right {
    width: 0;
    flex: 1;
    position: relative;
    background: #ffffff;
    border-radius: 6px;
    padding: 27px 40px 0;
    display: flex;
    flex-direction: column;
    .errata-info-header {
      font-size: 16px;
      color: #5a5a5a;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .errata-info-card {
      padding: 24px 0 8px;
      .header-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        .header-row-1 {
          font-size: 14px;
          color: #363636;
        }
        .header-row-2 {
          font-size: 14px;
          color: #8e8e93;
        }
      }
      .info-content {
        font-size: 16px;
        color: #5a5a5a;
        word-break: break-all;
        margin-bottom: 14px;
        white-space: pre-wrap;
      }
      .info-picture {
        .info-picture-item {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
    .footer-content {
      margin-top: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 0 40px;
      .pre-btn,
      .next-btn {
        width: 300px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #4b8fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #4b8fff;
      }
      .middle-line {
        width: 16px;
      }
    }
    .no-result-icon {
      width: 131px;
      display: block;
      margin-bottom: 22px;
    }
    .no-result-text {
      font-size: 14px;
      color: #666666;
      line-height: 20px;
    }
  }
  .has-chapter-page {
    border-radius: 0 6px 6px 0 !important;
  }
}
// @media (max-width: 1400px) {
//   .book-errata-detail-container {
//     max-width: 1200px !important;
//   }
// }
.list-no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}
.is-read-card {
  .header-row-1,
  .is-read {
    color: #bbbbbb !important;
  }
}
.read-btn {
  font-size: 14px;
  color: #4b8fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  .read-icon {
    width: 16px;
    height: 16px;
    display: block;
    margin-left: 4px;
  }
  .read-loading {
    font-size: 16px;
    margin-left: 4px;
  }
}
.tool-btn {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}
