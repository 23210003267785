














































































































































.course-list-container {
  background: linear-gradient(180deg, #ffe8cb 0%, #ffffff 100%);
  border-radius: 0.3125rem;
  padding: 0.3125rem 0;

  .btns-con {
    width: 100%;
    border-radius: 0.3125rem;
    display: flex;
    align-items: center;
    padding: 0.3125rem 0.0781rem 0.0781rem 0.625rem;
    padding-left: 0.4688rem;
    flex-wrap: wrap;
    z-index: 2;
    border: 0 0 0.3125rem 0.3125rem;

    .btn-item {
      display: inline-block;
      line-height: 0.8984rem;
      height: 0.8984rem;
      background: linear-gradient(140deg, #ffd0aa 0%, #fbdfb2 100%);
      border-radius: 0.1563rem;
      font-size: 0.3906rem;
      font-weight: 600;
      color: #aa0922;
      padding: 0 0.5859rem;
      margin-right: 0.3125rem;
      margin-bottom: 0.1563rem;
      cursor: pointer;

      &.active,
      &:hover {
        background: linear-gradient(270deg, #fb316c 0%, #fe362e 100%);
        color: #fff0da;
      }
    }

    &.is-fixed {
      background: #ffebd3;
      border-radius: 0;
    }
  }

  .course-list-block-con {
    .course-list-title-con {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.3125rem;

      .title {
        display: inline-block;
        height: 0.7813rem;
        font-weight: 600;
        color: #fd3659;
        line-height: 0.7813rem;
        margin-bottom: 0 0.3125rem 0.3125rem 0.3125rem;
        font-size: 0.5469rem;
        position: relative;

        &::after,
        &::before {
          content: '';
          display: inline-block;
          width: 0.7422rem;
          height: 0.0586rem;
          background: linear-gradient(297deg, rgba(239, 201, 158, 0) 0%, #fe3535 100%, #da9865 100%);
          border-radius: 0.0391rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &::before {
          left: -0.9766rem;
        }

        &::after {
          right: -0.9766rem;
        }

        &::before {
          transform: rotate(180deg);
        }
      }
    }

    .course-list-inner-con {
      display: flex;
      padding: 0 0.2734rem;
      flex-wrap: wrap;

      ::v-deep {
        .course-item {
          overflow: hidden;
          position: relative;
          z-index: 1;
          margin-bottom: 0.5859rem;
          -webkit-border-radius: 0.0781rem;
          -moz-border-radius: 0.0781rem;
          border-radius: 0.0781rem;
          box-shadow: 0rem 0.1172rem 0.2344rem 0rem rgba(198, 198, 198, 0.3);
          background-color: #fff;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;

          &.loading {
            background: url('https://app-cdn.btclass.cn/new-prefix/turtle/Fip8-uJTxJnVOYStzKwZRP81cKkc.png') 0 0 no-repeat;
            background-size: 100% 99.9%;
          }

          &:hover {
            transform: translateY(-0.1563rem);
            -webkit-transform: translateY(-0.1563rem);
            -moz-transform: translateY(-0.1563rem);
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }

          &.course-default {
            background-color: #f5f5f5;
            padding: 0.9766rem 0.3906rem 1.0742rem;
            border: 0.0195rem dashed #e1e1e1;
            color: #e1e1e1;
            text-align: center;
            font-size: 0.4688rem;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            &:hover {
              transform: translateY(0);
              -webkit-transform: translateY(0);
              -moz-transform: translateY(0);
              -o-transform: translateY(0);
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
            }

            & > i {
              font-size: 1.4063rem;
              display: inline-block;
              margin-bottom: 0.3516rem;
            }
          }

          .course-img {
            position: relative;
            border-top-left-radius: 0.0781rem;
            border-top-right-radius: 0.0781rem;
            width: 100%;

            .img-responsive {
              width: 100%;
              padding: 0;
            }
          }

          .title {
            margin-top: 0.1953rem;
            padding: 0 0.1953rem;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: normal;
          }

          .course-info {
            height: 3.0078rem;

            .metas {
              position: relative;
              padding: 0.3125rem 0.3125rem 0.3906rem 0.3125rem;
              color: #c1c1c1;

              .course-title {
                height: 0.8594rem;
                font-size: 0.3125rem;
                font-family: PingFangSC-Regular, sans-serif;
                font-weight: 400;
                color: rgba(0, 35, 51, 1);
                line-height: 0.4297rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .course-person {
                margin-top: 0.1758rem;
                width: 100%;
                height: 0.2734rem;

                .teacher {
                  font-size: 0.2344rem;
                  font-family: PingFangSC-Regular, sans-serif;
                  font-weight: 400;
                  color: rgba(138, 148, 153, 1);
                  vertical-align: top;
                }

                .num {
                  font-size: 0.2344rem;
                  line-height: 0.2344rem;
                  vertical-align: middle;
                  color: rgba(138, 148, 153, 1);
                }
              }

              .course-prices {
                margin-top: 0.3125rem;
                position: relative;
                vertical-align: bottom;

                &.is-vip {
                  .is-vip-price {
                    display: inline-block;
                  }
                }

                .member-role {
                  display: inline-block;
                  width: 1.25rem;
                  height: 0.4688rem;
                  margin-left: -0.3125rem;
                  margin-right: 0.1563rem;
                  vertical-align: bottom;
                }

                .course-price {
                  font-size: 0.3906rem;
                  line-height: 0.3516rem;
                  font-family: STHeitiSC-Medium, sans-serif;
                  font-weight: 500;
                  color: rgba(255, 77, 54, 1);
                  vertical-align: bottom;
                  margin-right: 0.0781rem;

                  .sales-zk {
                    font-weight: 500;
                    font-size: 0.3711rem;
                    font-family: PingFangSC-Regular;
                  }

                  &.ml0 {
                    margin-left: 0 !important;
                  }
                }

                .course-origin-price {
                  line-height: 0.3516rem;
                  font-family: STHeitiSC-Medium, sans-serif;
                  font-weight: 500;
                  vertical-align: bottom;
                  color: rgba(255, 77, 54, 1);
                  font-size: 0.3906rem;

                  &.del {
                    font-size: 0.2734rem;
                    color: rgba(138, 148, 153, 1);
                    text-decoration: line-through;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
