


































::v-deep {
  .add-reward {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0 0 20px 0;
    }
    .header-ctx {
      font-size: 18px;
      color: #262626;
      padding: 20px 0 16px;
      font-weight: bolder;
      text-align: center;
      position: relative;
      .close-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }
    }
    .content-ctx {
      font-size: 16px;
      color: #262626;
      padding: 60px 0 16px;
      font-weight: bolder;
      text-align: center;
    }
    .dialog-ctx {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 40px 20px;
      .submit-btn-1 {
        width: 160px;
        height: 40px;
        color: #ffffff;
        font-size: 14px;
        background: #cccccc;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 0;
        cursor: pointer;
      }
      .submit-btn-2 {
        width: 160px;
        height: 40px;
        color: #ffffff;
        font-size: 14px;
        background: #4b8fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 0;
        cursor: pointer;
      }
    }
  }
}
