body {
//   /*修改滚动条样式*/
//   ::-webkit-scrollbar {
//     width: 4px;
//     height: 4px;
//   }

//   ::-webkit-scrollbar-track {
//     border-radius: 2px;
//     background: #efefef !important;
//     margin-right: 4px !important;
//   }

//   ::-webkit-scrollbar-thumb {
//     background: #c7c7c7;
//     border-radius: 2px;
//   }

//   ::-webkit-scrollbar-thumb:hover {
//     background: #c7c7c7;
//   }

//   ::-webkit-scrollbar-corner {
//     background: transparent;
//   }
}
.bt--btn {
  height: 36px;
  padding: 0px 18px;
  font-size: 16px;
  color: #ffffff;
  background: #4B8FFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  &:active {
    transform: scale(0.98);
  }
}

.click--btn {
  cursor: pointer;
  user-select: none;

  &:active {
    transform: scale(0.98);
  }
}

.display-column-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}