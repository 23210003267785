
























































































.ask-main {
  margin: 0 auto;
}
.flex-router-view {
  // border: 1px solid yellow;
}
.open-flex {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  .flex-router-view {
    flex: 1;
  }
}
