[v-cloak] {
  display: none !important;
}

.pc-container {
  width: 25.390625rem;
  margin-right: auto;
  margin-left: auto;
}

.pc-home .fade-enter-active,
.pc-home .fade-leave-active {
  transition: opacity 0.5s;
}

.pc-home .fade-enter,
.pc-home .fade-leave-to {
  opacity: 0;
}

.pc-home .no-border {
  border: none;
}

.pc-home .home-menu {
  position: fixed;
  right: .390625rem;
  z-index: 9999;
  border-radius: 0.078125rem;
  overflow: hidden;
  border: 0.019531rem solid #e3e3e3;
  box-shadow: 0 0 0.195313rem 0 rgba(0, 0, 0, 0.05);
  bottom: 8.007813rem;
}

.pc-home .home-menu .bottom-mask {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.742188rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.53) 25%, rgba(255, 255, 255, 0.75) 43%, #ffffff 100%);
}

.pc-home .home-menu .mask-replace {
  display: block;
  height: 0.742188rem;
}

.pc-home .home-menu .menus {
  width: 1.953125rem;
  height: 5.859375rem;
  text-align: center;
}

.pc-home .home-menu .menus a {
  background: #fff;
  display: block;
  width: 100%;
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular, sans-serif;
  font-weight: 400;
  color: #616161;
  line-height: 0.625rem;
}

.pc-home .home-menu .menus a:hover {
  color: #616161;
  background: rgba(30,115,255, 0.1);
}

.pc-home .home-menu .menus a.active {
  color: #fff;
  background: #1e73ff;
}

.pc-home .home-recommend .course-area {
  padding-top: 1.5625rem;
  padding-bottom: 0.976563rem;
  background: #fafafa;
}

.pc-home .home-recommend .course-area .pc-container .head {
  position: relative;
  font-family: PingFangSC-Medium;
}

.pc-home .home-recommend .course-area .pc-container .head h5 {
  font-size: 0.585938rem;
  line-height: 0.585938rem;
  font-weight: 500;
  color: #002333;
  margin: 0 0 0.3125rem 0;
}

.pc-home .home-recommend .course-area .pc-container .head .open {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.273438rem;
  line-height: 0.585938rem;
  font-weight: 400;
  color: #8a9499;
}

.pc-home .home-recommend .course-area .pc-container .head .open:hover {
  color: #4b8fff;
}

.pc-home .home-recommend .course-list {
  position: relative;
}

.pc-home .home-recommend .course-list .el-carousel__arrow--left {
  display: none !important;
}

.pc-home .home-recommend .course-list .el-carousel__arrow--right {
  display: none !important;
}

.pc-home .home-recommend .course-list .el-carousel__container {
  height: 7.03125rem;
}

.pc-home .home-recommend .course-list .arrow-left,
.pc-home .home-recommend .course-list .arrow-right {
  position: absolute;
  top: 50%;
  margin: -0.605469rem 0 0 0;
  padding: 0;
  border: none;
  outline: 0;
  border-radius: 0 !important;
  width: 0.644531rem !important;
  height: 1.210938rem !important;
}

.pc-home .home-recommend .course-list .arrow-left.ccc,
.pc-home .home-recommend .course-list .arrow-right.ccc {
  opacity: 0.5;
}

.pc-home .home-recommend .course-list .arrow-left {
  left: -1.5625rem;
  background: url("../../../assets/images/home/icons.png");
  background-repeat: no-repeat;
  background-position: -1.347656rem 0;
  background-size: cover;
}

.pc-home .home-recommend .course-list .arrow-left:hover {
  background-position: -2.03125rem 0;
}

.pc-home .home-recommend .course-list .arrow-left.ccc:hover {
  background-position: -1.347656rem 0;
  opacity: 0.5;
}

.pc-home .home-recommend .course-list .arrow-right {
  right: -1.5625rem;
  background: url("../../../assets/images/home/icons.png");
  background-repeat: no-repeat;
  background-position: -0.683594rem 0;
  background-size: cover;
}

.pc-home .home-recommend .course-list .arrow-right:hover {
  background-position: 0 0;
}

.pc-home .home-recommend .course-list .arrow-right.ccc:hover {
  background-position: -0.664063rem 0;
  opacity: 0.5;
}

.pc-home .home-course-open .course-area {
  padding-top: 1.5625rem;
  padding-bottom: 1.171875rem;
  background: #fff;
  position: relative;
}

.pc-home .home-course-open .course-area .pc-container .head {
  position: relative;
  font-family: PingFangSC-Medium;
}

.pc-home .home-course-open .course-area .pc-container .head h5 {
  font-size: 0.585938rem;
  line-height: 0.585938rem;
  font-weight: 500;
  color: #002333;
  margin: 0 0 0.3125rem 0;
}

.pc-home .home-course-open .course-area .pc-container .head .open {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.273438rem;
  line-height: 0.585938rem;
  font-weight: 400;
  color: #8a9499;
}

.pc-home .home-course-open .course-area .pc-container .head .open:hover {
  color: #4b8fff;
}

.pc-home .home-course-model.course-area {
  padding-top: 1.5625rem;
}

.pc-home .home-course-model.course-area.fff {
  padding-bottom: 0.976563rem;
  background: #fff !important;
}

.pc-home .home-course-model.course-area.fafafa {
  padding-bottom: 0.976563rem;
  background: #fafafa !important;
}

.pc-home .home-course-model.course-area .pc-container .head {
  position: relative;
  font-family: PingFangSC-Medium;
}

.pc-home .home-course-model.course-area .pc-container .head h5 {
  font-size: 0.585938rem;
  line-height: 0.585938rem;
  font-weight: 500;
  color: #002333;
  margin: 0 0 0.585938rem 0;
}

.pc-home .home-course-model.course-area .pc-container .head .open {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.273438rem;
  line-height: 0.585938rem;
  font-weight: 400;
  color: #8a9499;
}

.pc-home .home-course-model.course-area .pc-container .head .open:hover {
  color: #4b8fff;
}

.pc-home .home-course-model.course-area .el-menu {
  border: none !important;
  margin-bottom: 0.78125rem !important;
  background: unset !important;
}

.pc-home .home-course-model.course-area .el-menu .el-menu-item {
  padding: 0.195313rem 0 !important;
  font-size: 0.390625rem !important;
  height: unset !important;
  font-family: PingFangSC-Medium;
  font-weight: 500 !important;
  line-height: 0.390625rem !important;
  margin-right: 0.976563rem !important;
}

.pc-home .home-course-model.course-area .el-menu .el-menu-item.is-active,
.pc-home .home-course-model.course-area .el-menu .el-menu-item:hover {
  border-bottom: 0.058594rem solid #1e73ff !important;
  background: unset !important;
}

.pc-home .home-category {
  background: #fff;
  padding: 0 0.195313rem;
}

.pc-home .home-category .br {
  display: none;
}

.pc-home .home-category .h-row {
  text-align: center;
  width: 100%;
}

.pc-home .home-category .h-row .h-col {
  display: inline-block;
  margin-left: 0.195313rem;
  margin-top: 0.507813rem;
  width: calc((100% - 0.996094rem) / 6);
  padding-bottom: calc((100% - 0.78125rem) / 6 * 1.3);
  position: relative;
}

.pc-home .home-category .h-row .h-col:first-child {
  margin-left: 0;
}

.pc-home .home-category .h-row .h-col .area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fafafa;
}

.pc-home .home-category .h-row .h-col .area .area-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pc-home .home-category .h-row .h-col .area .area-panel.loading .icon-loading {
  width: 2.539063rem;
  height: 2.734375rem;
}

.pc-home .home-category .h-row .h-col .area .area-panel .top {
  margin-top: 0.839844rem;
}

.pc-home .home-category .h-row .h-col .area .area-panel .top .top-icon {
  width: 3.330078rem;
}

.pc-home .home-category .h-row .h-col .area .area-panel .bottom {
  font-size: 0.46875rem;
  line-height: 0.46875rem;
  font-family: STSongti-SC-Black, sans-serif;
  font-weight: 900;
  color: #002333;
}

.pc-home .home-category .h-row .h-col .area .area-panel .bottom .name {
  position: absolute;
  bottom: 20%;
  left: 50%;
  left: 0;
  text-align: center;
  width: 100%;
}

.pc-home .home-category .h-row .h-col .area .area-panel .bottom .name::before {
  content: "";
  width: 0.585938rem;
  height: 0.039063rem;
  margin-right: 0.332031rem;
  margin-bottom: 0.136719rem;
  display: inline-block;
  background: #8a9499;
}

.pc-home .home-category .h-row .h-col .area .area-panel .bottom .name::after {
  content: "";
  width: 0.585938rem;
  height: 0.039063rem;
  margin-left: 0.332031rem;
  margin-bottom: 0.136719rem;
  display: inline-block;
  background: #8a9499;
}

.pc-home .home-category .h-row .h-col .area .hover-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fafafa;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .top {
  font-size: 0.46875rem;
  line-height: 0.46875rem;
  font-family: STSongti-SC-Black, sans-serif;
  font-weight: 900;
  color: #002333;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .top .name {
  position: absolute;
  top: 10%;
  left: 50%;
  left: 0;
  text-align: center;
  width: 100%;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .top .name::before {
  content: "";
  width: 0.585938rem;
  height: 0.039063rem;
  margin-right: 0.332031rem;
  margin-bottom: 0.136719rem;
  display: inline-block;
  background: #8a9499;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .top .name::after {
  content: "";
  width: 0.585938rem;
  height: 0.039063rem;
  margin-left: 0.332031rem;
  margin-bottom: 0.136719rem;
  display: inline-block;
  background: #8a9499;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .bottom {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 30%;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .bottom .menu {
  padding: 0 0.3125rem;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .bottom .menu.flex {
  display: flex;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .bottom .menu > .menu-flex {
  margin: 0 auto;
  flex: 1;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .bottom .menu .menu-flex + .menu-flex {
  margin-left: 0.15625rem;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .bottom .menu a {
  padding: 0 0.078125rem;
  font-size: 0.351563rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #002333;
  line-height: 0.703125rem;
  display: block;
  width: 100%;
  max-width: 2.929688rem;
  height: 0.703125rem;
  border: 0.019531rem solid #002333;
  margin: 0 auto 0.195313rem auto;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.pc-home .home-category .h-row .h-col .area .hover-menu .bottom .menu a:hover {
  background: #002333;
  color: white;
}

.pc-home .home-grade-rank-con {
  margin: 0.976563rem auto;
}

.pc-home .home-live-lesson-con {
  background-size: 100% 99.9%;
  display: flex;
  flex-direction: column;
}

.pc-home .home-live-lesson-con .lesson-block {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pc-home .home-live-lesson-con .lesson-block.cur-lesson-block {
  margin-bottom: 1.679688rem;
  min-height: 7.1875rem;
}

.pc-home .home-live-lesson-con .lesson-block .lesson-block-title {
  height: 0.820313rem;
  font-size: 0.585938rem;
  color: #363636;
  font-weight: 500;
  line-height: 0.820313rem;
  margin-bottom: 0.390625rem;
}

.pc-home .home-live-lesson-con .lesson-block .swiper-btn {
  width: 0.9375rem;
  height: 0.9375rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background-size: 100% 99.9%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/home/arow.png");
  cursor: pointer;
}

.pc-home .home-live-lesson-con .lesson-block .swiper-btn:hover {
  background-image: url("../../../assets/images/home/arow-hover.png");
}

.pc-home .home-live-lesson-con .lesson-block .swiper-btn.swiper-left-btn {
  left: -0.292969rem;
}

.pc-home .home-live-lesson-con .lesson-block .swiper-btn.swiper-right-btn {
  right: -0.292969rem;
  transform: translateY(-50%) rotate(180deg);
}

.pc-home .home-live-lesson-con .lesson-list-con {
  position: relative;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-list-content {
  width: 25.625rem;
  height: 3.710938rem;
  padding-left: 0.117188rem;
  margin-left: -0.117188rem;
  overflow: hidden;
}

.pc-home .home-live-lesson-con .lesson-list-con .swiper-container {
  width: 25.390625rem;
  height: 3.515625rem;
  overflow: visible;
}

.pc-home .home-live-lesson-con .lesson-list-con .swiper-slide {
  width: 8.300781rem;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item {
  width: 8.300781rem;
  height: 3.496094rem;
  background: #ffffff;
  box-shadow: 0 0.097656rem 0.195313rem 0 rgba(198, 198, 198, 0.3);
  border-radius: 0.078125rem;
  border: 0.019531rem solid rgba(151, 151, 151, 0.2);
  position: relative;
  display: flex;
  align-items: center;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-item-content {
  display: flex;
  justify-content: center;
  padding: 0.46875rem 0.371094rem 0.46875rem 0.3125rem;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .living-tag {
  width: 1.953125rem;
  height: 0.585938rem;
  background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
  border-radius: 0.078125rem 0 0.214844rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  z-index: 2;
  font-size: 0.351563rem;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .teacher-img {
  width: 2.519531rem;
  height: 2.519531rem;
  flex-shrink: 0;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .teacher-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0.234375rem;
  width: 100%;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info .lesson-title {
  display: inline-block;
  max-width: 4.84375rem;
  height: 0.976563rem;
  font-size: 0.351563rem;
  color: #363636;
  line-height: 0.488281rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
  margin-bottom: 0.15625rem;
  font-weight: 400;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info .teacher-info {
  display: flex;
  align-items: center;
  font-size: 0.3125rem;
  color: #5a5a5a;
  line-height: 0.429688rem;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info .teacher-info .teacher-tag {
  display: inline-block;
  font-weight: 400;
  color: #ff5353;
  text-shadow: 0.019531rem 0.039063rem 0.195313rem rgba(0, 0, 0, 0.05);
  font-size: 0.273438rem;
  height: 0.429688rem;
  line-height: 0.429688rem;
  background: #ffefed;
  border-radius: 0.078125rem 0.078125rem 0.078125rem 0;
  padding: 0 0.058594rem;
  margin-left: 0.175781rem;
  white-space: nowrap;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info .time-span {
  height: 0.46875rem;
  font-size: 0.3125rem;
  color: #8e8e93;
  line-height: 0.46875rem;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info .s-btn {
  position: absolute;
  bottom: 0.46875rem;
  right: 0.371094rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.757813rem;
  height: 0.664063rem;
  border: 0.019531rem solid #ffffff;
  font-size: 0.3125rem;
  font-weight: 500;
  border-radius: 0.332031rem;
  color: #ffffff;
  background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info .s-btn.is-living {
  background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info .s-btn.is-finished {
  background: #eeeeee;
  color: #bbbbbb;
}

.pc-home .home-live-lesson-con .lesson-list-con .lesson-item .lesson-info .s-btn.is-reserved {
  background: transparent;
  color: #4b8fff;
  border: 0.019531rem solid #4b8fff;
}

.pc-home .home-live-lesson-con .cur-lesson-list {
  width: 25.390625rem;
  height: 7.1875rem;
  position: relative;
}

.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn {
  width: 0.644531rem;
  height: 1.210938rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  border: none;
}

.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn.arrow-left {
  left: -1.5625rem;
  background: url("../../../assets/images/home/icons.png");
  background-repeat: no-repeat;
  background-position: -1.347656rem 0;
  background-size: cover;
}

.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn.arrow-left:hover {
  background-position: -2.03125rem 0;
}

.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn.arrow-left.ccc,
.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn.arrow-left.ccc:hover {
  background-position: -1.347656rem 0;
  opacity: 0.5;
}

.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn.arrow-right {
  right: -1.5625rem;
  background: url("../../../assets/images/home/icons.png");
  background-repeat: no-repeat;
  background-position: -0.683594rem 0;
  background-size: cover;
}

.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn.arrow-right:hover {
  background-position: 0.019531rem 0;
}

.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn.arrow-right.ccc,
.pc-home .home-live-lesson-con .cur-lesson-list .arrow-btn.arrow-right.ccc:hover {
  background-position: -0.664063rem 0;
  opacity: 0.5;
}

.pc-home .home-live-lesson-con .cur-lesson-list .el-carousel--horizontal {
  width: 25.78125rem;
  margin-left: -0.195313rem;
}

.pc-home .home-live-lesson-con .cur-lesson-list .el-carousel--horizontal .el-carousel__container {
  height: 7.1875rem;
}

.pc-home .home-live-lesson-con .cur-lesson-list .el-carousel--horizontal .el-carousel__container .el-carousel__item {
  width: 25.390625rem;
  margin-left: 0.195313rem;
  overflow: visible;
}

.pc-home .home-live-lesson-con .cur-lesson-list .el-carousel__indicators {
  margin-top: 0.15625rem;
}

.pc-home .home-live-lesson-con .cur-lesson-list .el-carousel__indicators .el-carousel__button {
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 50%;
  background: #4b8fff;
  opacity: 0.31;
}

.pc-home .home-live-lesson-con .cur-lesson-list .el-carousel__indicators .el-carousel__indicator.is-active .el-carousel__button {
  background: #4b8fff;
  opacity: 1;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large {
  width: 100%;
  height: 7.1875rem;
  background: linear-gradient(270deg, rgba(117, 173, 254, 0.13) 0%, rgba(213, 230, 255, 0.1) 29%, #ffffff 100%);
  box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
  border-radius: 0.234375rem;
  position: relative;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .lesson-item-content {
  display: flex;
  justify-content: center;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-img {
  width: 12.792969rem;
  height: 7.1875rem;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.234375rem;
  overflow: hidden;
  position: relative;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-img .learn-status {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.15625rem;
  position: absolute;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-img .learn-status .living-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.519531rem;
  height: 0.976563rem;
  background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
  border-radius: 0.214844rem 0.058594rem 0.214844rem 0.058594rem;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.507813rem;
  color: #ffffff;
  transform: translate(-50%, -50%);
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-img .img-responsive {
  height: 100%;
  width: 100%;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.625rem 0.46875rem;
  position: relative;
  width: 100%;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con::after {
  content: "";
  display: inline-block;
  width: 4.84375rem;
  height: 3.222656rem;
  background-image: url("../../../assets/images/home/course-r-bg.png");
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: 0.46875rem;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .course-title {
  font-size: 0.546875rem;
  font-weight: 500;
  color: #333333;
  height: 1.601563rem;
  line-height: 0.78125rem;
  max-width: 11.660156rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .bottom-info .teacher-info {
  display: flex;
  align-items: center;
  height: 0.722656rem;
  font-size: 0.507813rem;
  color: #565656;
  line-height: 0.722656rem;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .bottom-info .teacher-info .teacher-tag {
  display: inline-block;
  padding: 0 0.15625rem;
  height: 0.722656rem;
  background: #ffe3df;
  box-shadow: 0.019531rem 0.039063rem 0.195313rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0;
  font-size: 0.429688rem;
  font-weight: 400;
  color: #ff5353;
  line-height: 0.722656rem;
  text-shadow: 0.019531rem 0.039063rem 0.195313rem rgba(0, 0, 0, 0.05);
  margin-left: 0.15625rem;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .bottom-info .num-con {
  font-size: 0.46875rem;
  font-weight: 400;
  color: #511700;
  line-height: 0.644531rem;
  opacity: 0.4;
  margin: 0.46875rem 0 0.390625rem 0;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .bottom-info .num-con .num {
  margin-left: 0.097656rem;
  flex-shrink: 0;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .bottom-info .time-span {
  font-size: 0.46875rem;
  color: #565656;
  line-height: 0.644531rem;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .bottom-info .time-span .el-icon-time {
  margin-right: 0.078125rem;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .large-btn {
  height: 1.035156rem;
  padding: 0 0.976563rem;
  border-radius: 0.644531rem;
  font-size: 0.46875rem;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.839844rem;
  bottom: 0.625rem;
  z-index: 1;
  background: linear-gradient(90deg, #4997fa 0%, #78afff 100%);
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .large-btn.is-living {
  background: linear-gradient(90deg, #ff6b57 0%, #ff7c4e 100%);
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .large-btn.is-finished {
  background: #eeeeee;
  color: #bbbbbb;
}

.pc-home .home-live-lesson-con .cur-lesson-list .lesson-item-large .course-info-con .large-btn.is-reserved {
  background: transparent;
  color: #4b8fff;
  border: 0.039063rem solid #4b8fff;
}

.pc-home .home-live-lesson-con.no-cur-lesson .lesson-list-con {
  margin-top: 0.3125rem;
}

.pc-home .home-carousel {
  min-width: 23.4375rem;
  height: 7.03125rem !important;
}

.pc-home .home-carousel .swiper-pagination-bullet {
  font-size: 0;
  margin: 0 0.195313rem;
  width: 0.292969rem;
  height: 0.292969rem;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: unset;
  border: 0.039063rem solid #fff;
  opacity: 1;
}

.pc-home .home-carousel .swiper-pagination-bullet:hover {
  cursor: pointer;
  background: #fff;
  opacity: 1;
}

.pc-home .home-carousel .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}

.pc-home .home-carousel.loading {
  width: 100%;
  height: 7.03125rem !important;
  text-align: center;
}

.pc-home .home-carousel.loading .icon-loading {
  width: 5.859375rem;
  height: 5.859375rem;
}

.pc-home .home-carousel .container {
  width: 22.65625rem;
}

.pc-home .home-carousel .img-responsive {
  height: 7.03125rem !important;
  margin: 0 auto;
}

.pc-home .home-bt-teacher.course-area {
  padding-top: 1.5625rem;
}

.pc-home .home-bt-teacher.course-area.fff {
  padding-bottom: 1.269531rem;
  background: #fff !important;
}

.pc-home .home-bt-teacher.course-area.fafafa {
  padding-bottom: 0.976563rem;
  background: #fafafa !important;
}

.pc-home .home-bt-teacher.course-area .pc-container .head {
  position: relative;
  font-family: PingFangSC-Medium;
}

.pc-home .home-bt-teacher.course-area .pc-container .head h5 {
  margin: 0 0 0.78125rem 0;
  font-size: 0.585938rem;
  line-height: 0.585938rem;
  font-weight: 500;
  color: #002333;
}

.pc-home .home-bt-teacher.course-area .pc-container .head .open {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.273438rem;
  line-height: 0.585938rem;
  font-weight: 400;
  color: #8a9499;
}

.pc-home .home-bt-teacher.course-area .pc-container .head .open:hover {
  color: #4b8fff;
}

.pc-home .home-bt-teacher.course-area .teachers {
  text-align: left;
  padding-bottom: 0.292969rem;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher {
  padding: 0.195313rem;
  position: relative;
  margin-left: 0.371094rem;
  display: inline-block;
  width: 4.765625rem;
  height: 6.015625rem;
  background: white;
  box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
  border-radius: 0.039063rem;
  text-align: center;
  vertical-align: top;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher.first {
  margin-left: 0;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .top-info .head-img {
  margin-top: 0.195313rem;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .top-info .head-img img {
  width: 2.34375rem;
  height: 2.34375rem;
  background: #e9eff2;
  border-radius: 50%;
  border: 0.019531rem solid #e6e6e6;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .top-info .name {
  position: relative;
  font-size: 0.390625rem;
  font-family: STSongti-SC-Bold;
  font-weight: bold;
  color: #002333;
  line-height: 0.390625rem;
  display: block;
  text-align: center;
  padding: 0.429688rem 0 0.390625rem 0;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .top-info .title {
  display: inline-block;
  margin: 0 auto;
  line-height: 0.390625rem;
  text-align: left;
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .hover-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0.390625rem;
  text-align: left;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .hover-menu .name {
  font-size: 0.46875rem;
  font-family: STSongti-SC-Bold;
  font-weight: bold;
  color: #002333;
  line-height: 0.390625rem;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .hover-menu .info {
  margin-top: 0.664063rem;
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.390625rem;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .hover-menu .open {
  position: absolute;
  bottom: 0.390625rem;
  right: 0.390625rem;
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  line-height: 0.273438rem;
  color: #1e73ff;
  padding: 0.136719rem 0.234375rem 0.117188rem 0.234375rem;
  border: 0.019531rem solid #4b8fff;
  vertical-align: middle;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .hover-menu .open:hover {
  color: #4b8fff;
}

.pc-home .home-bt-teacher.course-area .teachers .link-teacher .hover-menu .open img {
  margin-top: -0.019531rem;
  width: 0.292969rem;
  height: 0.273438rem;
  vertical-align: top;
}

.pc-home .home-bt-community.course-area {
  padding: 0;
  overflow: hidden;
}

.pc-home .home-bt-community.course-area.pb80 {
  background: #fff;
  padding-bottom: 1.5625rem;
}

.pc-home .home-bt-community.course-area .pc-container .head {
  position: relative;
  font-family: PingFangSC-Medium;
}

.pc-home .home-bt-community.course-area .pc-container .head h5 {
  margin: 0 0 0.78125rem 0;
  font-size: 0.585938rem;
  line-height: 0.585938rem;
  font-weight: 500;
  color: #002333;
}

.pc-home .home-bt-community.course-area .pc-container .head .open {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.273438rem;
  line-height: 0.585938rem;
  font-weight: 400;
  color: #8a9499;
}

.pc-home .home-bt-community.course-area .pc-container .head .open:hover {
  color: #4b8fff;
}

.pc-home .home-bt-community.course-area .pc-container.top-left,
.pc-home .home-bt-community.course-area .pc-container.top-right,
.pc-home .home-bt-community.course-area .pc-container.bottom-left,
.pc-home .home-bt-community.course-area .pc-container.bottom-right {
  width: 50%;
  float: left;
}

.pc-home .home-bt-community.course-area .pc-container.top-left .content,
.pc-home .home-bt-community.course-area .pc-container.bottom-left .content {
  width: 12.695313rem;
  float: right;
}

.pc-home .home-bt-community.course-area .pc-container.top-right .content,
.pc-home .home-bt-community.course-area .pc-container.bottom-right .content {
  width: 12.695313rem;
  float: left;
}

.pc-home .home-bt-community.course-area .pc-container.top-left {
  display: inline-block;
  height: 10.3125rem;
  background: #fafafa;
}

.pc-home .home-bt-community.course-area .pc-container.top-right {
  display: inline-block;
  height: 10.3125rem;
  background: #fff;
}

.pc-home .home-bt-community.course-area .pc-container.top-right .area {
  margin: 2.929688rem 0 0 1.5625rem;
}

.pc-home .home-bt-community.course-area .pc-container.bottom-left {
  height: 8.945313rem;
  background: #fff;
}

.pc-home .home-bt-community.course-area .pc-container.bottom-left .area {
  margin: 1.5625rem 0 0 0;
}

.pc-home .home-bt-community.course-area .pc-container.bottom-right {
  height: 8.945313rem;
  background: #fafafa;
}

.pc-home .home-bt-community.course-area .pc-container.bottom-right .area {
  margin: 1.5625rem 0 0 1.5625rem;
}

.pc-home .home-bt-community.course-area .pc-container .head h5 {
  margin: 1.5625rem 0 0.78125rem 0;
}

.pc-home .home-open-course-model.course-list .open-course-li {
  height: 5.742188rem;
  overflow: hidden;
  padding-top: 0.195313rem;
}

.pc-home .home-open-course-model.course-list .course-item {
  position: relative;
  z-index: 1;
  margin-bottom: 0.390625rem;
  -webkit-border-radius: 0.078125rem;
  -moz-border-radius: 0.078125rem;
  border-radius: 0.078125rem;
  box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pc-home .home-open-course-model.course-list .course-item.loading {
  background: url("../../../assets/images/home/free-course-default.png") 0 0 no-repeat;
  background-size: cover;
}

.pc-home .home-open-course-model.course-list .course-item:hover {
  transform: translateY(-0.117188rem);
  -webkit-transform: translateY(-0.117188rem);
  -moz-transform: translateY(-0.117188rem);
}

.pc-home .home-open-course-model.course-list .course-item.course-default {
  background-color: #f5f5f5;
  padding: 0.976563rem 0.390625rem 1.074219rem;
  border: 0.019531rem dashed #e1e1e1;
  color: #e1e1e1;
  text-align: center;
  font-size: 0.46875rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.pc-home .home-open-course-model.course-list .course-item.course-default:hover {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.pc-home .home-open-course-model.course-list .course-item .course-img {
  width: 100%;
}

.pc-home .home-open-course-model.course-list .course-item .course-img .img-responsive {
  border-top-left-radius: 0.078125rem;
  border-top-right-radius: 0.078125rem;
  width: 100%;
  padding: 0;
}

.pc-home .home-open-course-model.course-list .course-item .course-img .learn-status {
  background: url("../../../assets/images/home/learn-status.png") 0 0 no-repeat;
  text-align: center;
  background-size: cover;
  z-index: 99;
}

.pc-home .home-open-course-model.course-list .course-item .course-img .learn-status .status {
  font-size: 0.234375rem;
  font-family: PingFangSC-Regular, sans-serif;
  font-weight: 400;
  color: white;
}

.pc-home .home-open-course-model.course-list .course-item .course-info {
  height: 1.777344rem;
}

.pc-home .home-open-course-model.course-list .course-item .course-info .metas {
  padding: 0.253906rem 0.273438rem 0.292969rem;
  color: #c1c1c1;
  overflow: hidden;
  width: 100%;
  padding: 0.253906rem 0.273438rem 0.292969rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pc-home .home-open-course-model.course-list .course-item .course-info .metas .left-title {
  color: #002333;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
  margin: 0;
  font-size: 0.292969rem;
  line-height: 0.410156rem;
}

.pc-home .home-open-course-model.course-list .course-item .course-info .metas .start-time {
  font-size: 0.3125rem;
  font-family: PingFangSC-Regular, sans-serif;
  font-weight: 400;
  color: #002333;
  vertical-align: middle;
}

.pc-home .home-open-course-model.course-list .course-item .course-info .metas .num {
  font-size: 0.234375rem;
  height: 0.3125rem;
  line-height: 0.3125rem;
  color: #8a9499;
  text-align: right;
}

.pc-home .home-list-model .fade-enter {
  opacity: 1;
}

@keyframes fadeAnimate {
  0% {
    opacity: 1;
  }

  30%, 70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pc-home .home-list-model .fade {
  animation: fadeAnimate 0.5s infinite;
}

.pc-home .home-list-model .bt-center {
  text-align: center;
}

.pc-home .home-list-model .bt-center .bt-more {
  display: inline-block;
  width: 7.8125rem;
  height: 0.9375rem;
  background: white;
  margin: 0 auto;
  font-size: 0.3125rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #4b8fff;
  line-height: 0.898438rem;
  vertical-align: middle;
  padding: 0;
}

.pc-home .home-list-model .bt-center .bt-more img {
  margin-left: 0.097656rem;
  width: 0.253906rem;
  height: 0.273438rem;
  vertical-align: middle;
}

.pc-home .home-course-list .course-item {
  overflow: hidden;
  height: 6.328125rem;
  position: relative;
  z-index: 1;
  margin-bottom: 0.585938rem;
  -webkit-border-radius: 0.078125rem;
  -moz-border-radius: 0.078125rem;
  border-radius: 0.078125rem;
  box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pc-home .home-course-list .course-item.loading {
  background: url("../../../assets/images/home/course-default.png") 0 0 no-repeat;
  background-size: cover;
}

.pc-home .home-course-list .course-item:hover {
  transform: translateY(-0.15625rem);
  -webkit-transform: translateY(-0.15625rem);
  -moz-transform: translateY(-0.15625rem);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pc-home .home-course-list .course-item.course-default {
  background-color: #f5f5f5;
  padding: 0.976563rem 0.390625rem 1.074219rem;
  border: 0.019531rem dashed #e1e1e1;
  color: #e1e1e1;
  text-align: center;
  font-size: 0.46875rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.pc-home .home-course-list .course-item.course-default:hover {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.pc-home .home-course-list .course-item.course-default > i {
  font-size: 1.40625rem;
  display: inline-block;
  margin-bottom: 0.351563rem;
}

.pc-home .home-course-list .course-item .course-img {
  position: relative;
  border-top-left-radius: 0.078125rem;
  border-top-right-radius: 0.078125rem;
  width: 100%;
}

.pc-home .home-course-list .course-item .course-img .img-responsive {
  width: 100%;
  padding: 0;
}

.pc-home .home-course-list .course-item .title {
  margin-top: 0.195313rem;
  padding: 0 0.195313rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.pc-home .home-course-list .course-item .course-info {
  width: 6.015625rem;
  height: 3.007813rem;
}

.pc-home .home-course-list .course-item .course-info .metas {
  position: relative;
  padding: 0.3125rem 0.3125rem 0.390625rem 0.3125rem;
  color: #c1c1c1;
}

.pc-home .home-course-list .course-item .course-info .metas .course-title {
  height: 0.859375rem;
  font-size: 0.3125rem;
  font-family: PingFangSC-Regular, sans-serif;
  font-weight: 400;
  color: #002333;
  line-height: 0.429688rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pc-home .home-course-list .course-item .course-info .metas .course-person {
  margin-top: 0.175781rem;
  width: 100%;
  height: 0.273438rem;
}

.pc-home .home-course-list .course-item .course-info .metas .course-person .teacher {
  font-size: 0.234375rem;
  font-family: PingFangSC-Regular, sans-serif;
  font-weight: 400;
  color: #8a9499;
  vertical-align: top;
}

.pc-home .home-course-list .course-item .course-info .metas .course-person .num {
  font-size: 0.234375rem;
  line-height: 0.234375rem;
  vertical-align: middle;
  color: #8a9499;
}

.pc-home .home-course-list .course-item .course-info .metas .course-prices {
  margin-top: 0.3125rem;
  position: relative;
  vertical-align: bottom;
}

.pc-home .home-course-list .course-item .course-info .metas .course-prices.is-vip .is-vip-price {
  display: inline-block;
}

.pc-home .home-course-list .course-item .course-info .metas .course-prices .member-role {
  display: inline-block;
  width: 1.25rem;
  height: 0.46875rem;
  margin-left: -0.3125rem;
  margin-right: 0.15625rem;
  vertical-align: bottom;
}

.pc-home .home-course-list .course-item .course-info .metas .course-prices .course-price {
  font-size: 0.390625rem;
  line-height: 0.351563rem;
  font-family: STHeitiSC-Medium, sans-serif;
  font-weight: 500;
  color: #ff4d36;
  vertical-align: bottom;
  margin-right: 0.078125rem;
}

.pc-home .home-course-list .course-item .course-info .metas .course-prices .course-price .sales-zk {
  font-weight: 500;
  font-size: 0.371094rem;
  font-family: PingFangSC-Regular;
}

.pc-home .home-course-list .course-item .course-info .metas .course-prices .course-price.ml0 {
  margin-left: 0 !important;
}

.pc-home .home-course-list .course-item .course-info .metas .course-prices .course-origin-price {
  line-height: 0.351563rem;
  font-family: STHeitiSC-Medium, sans-serif;
  font-weight: 500;
  vertical-align: bottom;
  color: #ff4d36;
  font-size: 0.390625rem;
}

.pc-home .home-course-list .course-item .course-info .metas .course-prices .course-origin-price.del {
  font-size: 0.273438rem;
  color: #8a9499;
  text-decoration: line-through;
}

.pc-home .home-bt-student-plan.bt-model {
  overflow: hidden;
  width: 11.132813rem;
  padding: 0.390625rem;
  background: white;
  box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
}

.pc-home .home-bt-student-plan.bt-model .bt-head {
  overflow: hidden;
  padding-bottom: 0.390625rem;
}

.pc-home .home-bt-student-plan.bt-model .bt-head .bt-title {
  font-size: 0.351563rem;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #002333;
  line-height: 0.351563rem;
}

.pc-home .home-bt-student-plan.bt-model .bt-head .bt-open {
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.351563rem;
  float: right;
}

.pc-home .home-bt-student-plan.bt-model .bt-head .bt-open:hover {
  color: #1e73ff;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list {
  margin-bottom: 0.390625rem;
  height: 1.171875rem;
  overflow: hidden;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list:last-child {
  margin-bottom: 0;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .left {
  float: left;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .left img {
  width: 1.171875rem;
  height: 1.171875rem;
  border-radius: 50%;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .right {
  margin-left: 1.367188rem;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .right .title {
  margin-bottom: 0.15625rem;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .right .title .text {
  overflow: hidden;
  font-size: 0.3125rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #002333;
  line-height: 0.429688rem;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .right .title .text .info {
  display: inline-block;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .right .title .star {
  display: inline-block;
  margin: 0 0 0 0.292969rem;
  line-height: 0.429688rem;
  vertical-align: top;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .right .title .time {
  float: right;
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.429688rem;
}

.pc-home .home-bt-student-plan.bt-model .bt-body .list .right .content {
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 1;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.pc-home .home-bt-student-comment.bt-model {
  overflow: hidden;
  width: 11.132813rem;
  padding: 0.390625rem;
  background: white;
  box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
}

.pc-home .home-bt-student-comment.bt-model .bt-head {
  overflow: hidden;
  padding-bottom: 0.390625rem;
}

.pc-home .home-bt-student-comment.bt-model .bt-head .bt-title {
  font-size: 0.351563rem;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #002333;
  line-height: 0.351563rem;
}

.pc-home .home-bt-student-comment.bt-model .bt-head .bt-open {
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.351563rem;
  float: right;
}

.pc-home .home-bt-student-comment.bt-model .bt-head .bt-open:hover {
  color: #1e73ff;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list {
  margin-bottom: 0.390625rem;
  height: 1.171875rem;
  overflow: hidden;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list:last-child {
  margin-bottom: 0;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .left {
  float: left;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .left img {
  width: 1.171875rem;
  height: 1.171875rem;
  border-radius: 50%;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .right {
  margin-left: 1.367188rem;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .right .title {
  margin-bottom: 0.15625rem;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .right .title .text {
  overflow: hidden;
  font-size: 0.3125rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #002333;
  line-height: 0.429688rem;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .right .title .text .info {
  display: inline-block;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .right .title .star {
  display: inline-block;
  margin: 0 0 0 0.292969rem;
  line-height: 0.429688rem;
  vertical-align: top;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .right .title .time {
  float: right;
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.429688rem;
}

.pc-home .home-bt-student-comment.bt-model .bt-body .list .right .content {
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.273438rem;
  width: 100%;
}

.pc-home .home-bt-latest-info.bt-model {
  overflow: hidden;
  width: 11.132813rem;
  padding: 0.390625rem;
  background: white;
  box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
}

.pc-home .home-bt-latest-info.bt-model .bt-head {
  overflow: hidden;
  padding-bottom: 0.390625rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-head .bt-title {
  font-size: 0.351563rem;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #002333;
  line-height: 0.351563rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-head .bt-open {
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.351563rem;
  float: right;
}

.pc-home .home-bt-latest-info.bt-model .bt-head .bt-open:hover {
  color: #4b8fff;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .top {
  margin: 0 0 0.488281rem 0;
  height: 1.953125rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .top .left {
  float: left;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .top .left img {
  width: 2.929688rem;
  height: 1.953125rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .top .right {
  height: 1.953125rem;
  position: relative;
  margin: 0 0 0 3.125rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .top .right .title {
  margin-bottom: 0.195313rem;
  font-size: 0.3125rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 0.429688rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .top .right .content {
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #999999;
  line-height: 0.390625rem;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .top .right .time {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.234375rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #999999;
  line-height: 0.332031rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .bottom .list {
  position: relative;
  margin-bottom: 0.332031rem;
  height: 0.398437rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .bottom .list:last-child {
  margin-bottom: 0;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .bottom .list::before {
  content: "";
  width: 0.078125rem;
  height: 0.234375rem;
  display: inline-block;
  vertical-align: top;
  margin-top: 0.019531rem;
  background: url("../../../assets/images/home/icon-left.png") 0 0 no-repeat;
  background-size: cover;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .bottom .list .title {
  display: inline-block;
  font-size: 0.3125rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 0.273438rem;
  vertical-align: top;
  margin: 0 0 0 0.195313rem;
}

.pc-home .home-bt-latest-info.bt-model .bt-body .bottom .list .time {
  float: right;
  font-size: 0.234375rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #999999;
  line-height: 0.273438rem;
  vertical-align: top;
  margin-top: 0.039063rem;
}

.pc-home .home-bt-group-hot.bt-model {
  overflow: hidden;
  width: 11.132813rem;
  padding: 0.390625rem;
  background: white;
  box-shadow: 0 0.117188rem 0.234375rem 0 rgba(198, 198, 198, 0.3);
}

.pc-home .home-bt-group-hot.bt-model .bt-head {
  overflow: hidden;
  padding-bottom: 0.390625rem;
}

.pc-home .home-bt-group-hot.bt-model .bt-head .bt-title {
  font-size: 0.351563rem;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #002333;
  line-height: 0.351563rem;
}

.pc-home .home-bt-group-hot.bt-model .bt-head .bt-open {
  font-size: 0.273438rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.351563rem;
  float: right;
}

.pc-home .home-bt-group-hot.bt-model .bt-head .bt-open:hover {
  color: #4b8fff;
}

.pc-home .home-bt-group-hot.bt-model .bt-body {
  display: -webkit-flex;
  flex-wrap: wrap;
}

.pc-home .home-bt-group-hot.bt-model .bt-body .list {
  width: 50%;
  margin-bottom: 0.390625rem;
  display: inline-block;
  height: 1.171875rem;
}

.pc-home .home-bt-group-hot.bt-model .bt-body .list:last-child,
.pc-home .home-bt-group-hot.bt-model .bt-body .list:nth-child(5) {
  margin-bottom: 0;
}

.pc-home .home-bt-group-hot.bt-model .bt-body .list .list-left {
  float: left;
}

.pc-home .home-bt-group-hot.bt-model .bt-body .list .list-left img {
  width: 1.171875rem;
  height: 1.171875rem;
}

.pc-home .home-bt-group-hot.bt-model .bt-body .list .list-right .list-title {
  margin: 0 0 0.195313rem 1.367188rem;
  font-size: 0.3125rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #002333;
  line-height: 0.429688rem;
}

.pc-home .home-bt-group-hot.bt-model .bt-body .list .list-right .list-num {
  margin: 0 0 0 1.367188rem;
  font-size: 0.234375rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #8a9499;
  line-height: 0.234375rem;
}

.pc-home .home-friend-link {
  background: #fafafa;
  color: #002333;
}

.pc-home .home-friend-link .title {
  padding-top: 0.78125rem;
  font-size: 0.585938rem;
  font-weight: 500;
}

.pc-home .home-friend-link .link-list {
  padding: 0.78125rem 0.78125rem 1.5625rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.3125rem;
}

.pc-home .home-friend-link .link-list .link-item {
  text-align: center;
}

.pc-home .home-friend-link .link-list .link-item .icon {
  font-size: 1.171875rem;
  margin-bottom: 0.195313rem;
}

.pc-home .ad-image-module {
  background: #fff;
  padding: 1.738281rem 0 1.328125rem;
}

.pc-home .ad-image-module .pc-container {
  display: flex;
  justify-content: space-between;
}

.pc-home .ad-image-module .pc-container .image-item {
  display: inline-block;
  position: relative;
}

.pc-home .ad-image-module .pc-container .image-item .tips {
  font-size: 0.351563rem;
  font-weight: 500;
  color: #ffe6bd;
  line-height: 0.46875rem;
  position: absolute;
  left: 1.230469rem;
  top: 2.1875rem;
}

.pc-home .ad-image-module .pc-container img {
  width: 12.304688rem;
  height: 3.886719rem;
  display: inline-block;
}

/* IE10 and IE11 */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .pc-home .home-course-list .course-item .course-info .metas .course-title,
  .pc-home .home-bt-latest-info.bt-model .bt-body .top .right .content {
    white-space: nowrap;
  }
}

/* edge */

@supports (-ms-ime-align: auto) {
  .pc-home .home-course-list .course-item .course-info .metas .course-title,
  .pc-home .home-bt-latest-info.bt-model .bt-body .top .right .content {
    white-space: nowrap;
  }
}

/*# sourceMappingURL=home.css.map */