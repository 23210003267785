




















































































.channel-con {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  min-height: 140px;
  margin-bottom: 16px;
  .title {
    line-height: 65px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #363636;
    border-bottom: 1px solid #efefef;
  }
  .content {
    overflow: auto;
    padding: 10px 0 24px 0;
    max-height: 700px;

    .channel-item {
      height: 58px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 58px;
      text-align: center;
      font-weight: 600;
      color: #5a5a5a;
      font-size: 16px;
      padding: 0 8px;
      cursor: pointer;
      &:hover {
        color: #4b8fff;
        background: #f9f9f9;
      }
      &.is-active {
        color: #4b8fff;
        background: rgba(75, 143, 255, 0.1);
      }
    }
    .more-channel {
      height: 58px;
      line-height: 58px;
      font-size: 16px;
      color: #5a5a5a;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #4b8fff;
        background: #f9f9f9;
      }
    }
  }
  @media only screen and (max-height: 700px) {
    .content {
      max-height: 450px;
    }
  }
  /*修改滚动条样式*/
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 2px;
    background: #efefef !important;
    margin-right: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #4b8fff;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4b8fff;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
