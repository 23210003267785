























































































.hot-storied {
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #313131;
  line-height: 22px;
  width: 300px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 10px;
  position: relative;
  &.is-fixed {
    position: fixed;
    top: 0;
  }
  .title {
    margin-bottom: 16px;
  }
  .list-content {
    .item-con {
      display: flex;
      margin-bottom: 16px;
      .index-img {
        height: 20px;
        width: 20px;
      }
      .item-info {
        display: flex;
        flex-direction: column;
        .s-title {
          max-width: 242px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #313131;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          margin-bottom: 4px;
          &:hover {
            color: #4b8fff;
            cursor: pointer;
          }
        }
        .tip {
          font-size: 12px;
          font-weight: 400;
          color: #919191;
          line-height: 17px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
