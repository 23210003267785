




























































































































.receive-dialog {
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  background: transparent !important;
  box-shadow: none !important;

  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  .receive-dialog-content {
    width: 300px;
    height: 156px;
    background: linear-gradient(134deg, #fff9f3 0%, #ffe3c1 100%);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363636;
    line-height: 22px;
    .sure-btn {
      width: 172px !important;
      height: 52px;
      margin-top: 18px;
    }
  }
}
