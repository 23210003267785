

































.s-title {
  text-align: center;
  font-size: 0.4297rem !important;
}
