






























































































::-webkit-scrollbar {
  width: 10px;
  background-color: #eee;
}

/*定义滚动条轨道：内阴影+圆角*/
::-webkit-scrollbar-track {
  background-color: #f7b4c9;
}

/*定义滑块，即滚动条里面可以拖动的那个*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ff6262;
}
