



























































































.learn-status {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #ff5151;
  top: 4px;
  right: 4px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0 4px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  border-radius: 2px;
  .lottie {
    margin-right: 2px;
    margin-bottom: 1px;
  }
}
.tag-new {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  width: 41px;
  height: auto;
}

.icon-eye {
  margin-right: 2px;
  color: #8a9499;
  opacity: 0.6;
}
.recent-time {
  margin-bottom: 24px;
}
