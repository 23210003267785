.qiyu-container-wrap {
  position: fixed;
  right: .703125rem;
  bottom: 1.289063rem;
  cursor: pointer;
  z-index: 10;
  width: 1.328125rem;
  background-color: #fff;
  border-radius: .078125rem;
  border: .019531rem solid #e3e3e3;
  padding: 0rem .058594rem;
  text-align: center;
  border-radius: .078125rem;
  box-shadow: 0 0 .195313rem 0 rgba(0, 0, 0, 0.05);
}

.qiyu-container-wrap .unread-num {
  background: #ff6262;
  font-size: .273438rem;
  color: #ffffff;
  width: .390625rem;
  height: .390625rem;
  line-height: .390625rem;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  position: absolute;
  right: .273438rem;
  top: .273438rem;
}

.qiyu-container-wrap.active .es-icon {
  animation: highlight 0.8s ease 0.2s infinite alternate;
}

.qiyu-container-wrap .qr-code-wrap {
  position: absolute;
  right: 1.269531rem;
  top: -1.933594rem;
  height: 4.609375rem;
  width: 3.984375rem;
  font-size: .234375rem;
  font-weight: 400;
  color: #fff;
  padding: .625rem 0 0;
  background: url('https://tmp.btclass.cn/yunyin/popup-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-right: .097656rem;
}

.qiyu-container-wrap .account-des {
  font-size: .273438rem;
  color: #313131;
  line-height: .390625rem;
  font-weight: bolder;
}

.qiyu-container-wrap .account-des .m-b-4 {
  margin-bottom: .078125rem;
}

.qiyu-container-wrap .qr-code-img {
  height: 2.34375rem;
  width: 2.34375rem;
  margin-bottom: .15625rem;
  border-radius: .058594rem;
}

.qiyu-container-wrap .dialog-des {
  font-size: .234375rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: .332031rem;
}

.qiyu-container-wrap .active-des {
  color: #8E8E93;
}

.qiyu-container-wrap .qiyu-container:hover .active-des {
  color: #1e73ff;
}

.qiyu-container-wrap .service-icon {
  height: .625rem;
  width: 100%;
  margin-bottom: .078125rem;
}

.qiyu-container-wrap .account-icon {
  width: 100%;
  height: .625rem;
}

.qiyu-container-wrap .hover-ctx {
  font-size: .234375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: .625rem;
  background: #4B8FFF;
  border-radius: .078125rem;
  color: #ffffff;
  line-height: .332031rem;
}

.qiyu-container-wrap .qiyu-item {
  padding: .273438rem 0;
  display: block;
  position: relative;
}

.qiyu-container-wrap .qiyu-item:not(:last-child) {
  border-bottom: .019531rem solid #e3e3e3;
}

@keyframes highlight {
  0% {
    color: #616161;
  }

  100% {
    color: #1e73ff;
  }
}

.animation-back-top {
  animation: showtopanimiate 0.5s linear;
}

@keyframes showtopanimiate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}