

































































































































































.course-area {
  background-color: #fff !important;
  .el-carousel__item {
    margin-right: 2px;
  }
}
