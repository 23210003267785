

























































































































































































.edit-long-text-container {
  width: 1175px;
  height: 898px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  position: relative;
  .title {
    height: 76px;
    display: flex;
    align-items: center;
    padding-left: 47px;
    font-size: 20px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid #efefef;
    .block-icon {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .content {
    padding-top: 30px;
    padding-bottom: 64px;
    background: #fff;
    .input-content {
      width: 917px;
      background: #f7f8f9;
      border-radius: 8px;
      margin-left: -70px;
      ::v-deep {
        .el-textarea__inner {
          background: #f7f8f9;
          border: none;
          padding: 24px;
        }
        .el-input__count {
          background-color: transparent;
        }
      }
    }
    ::v-deep .el-form-item__label {
      padding-right: 0;
      font-size: 14px;
      font-weight: 400;
      color: #313131;
      line-height: 22px;
      line-height: 32px;
    }
    .lable-span {
      font-size: 14px;
      color: #919191;
      line-height: 22px;
      margin-bottom: 12px;
      line-height: 32px;
    }
    .circle-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .circle-item {
        display: flex;
        align-items: center;
        padding: 0 8px;
        height: 32px;
        background: #f7f8f9;
        border-radius: 16px;
        margin-right: 16px;
        color: #5a5a5a;

        .circle-icon {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
        .close-icon {
          font-size: 16px;
          margin-left: 8px;
          color: #bfbfc2;
          cursor: pointer;
          &:hover{
            color: #4b8fff;
          }
        }
        &.add-circle-item {
          cursor: pointer;
          color: #616161;
          .icon {
            font-size: 20px;
          }
        }
      }
    }
  }
  .footer-con {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 64px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -2px 4px 0px rgba(0, 21, 41, 0.08);
    padding: 0 120px;
    display: flex;
    justify-content: center;
    .f-content {
      width: 1175px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .length-num {
        font-size: 12px;
        font-size: 14px;
        color: #bfbfbf;
        line-height: 20px;
        .more-than {
          color: #f56c6c;
        }
      }
      .r-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .cancle-btn {
          margin-right: 16px;
        }
      }
    }
  }
  .upload-ctx-con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      width: 32px;
      height: 32px;
      margin-bottom: 12px;
      color: #8e8e93;
    }
    .tips-span {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #8e8e93;
      line-height: 21px;
    }
  }
}
