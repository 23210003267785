






























































.lottie {
  overflow: hidden;
  margin: 0 auto;
}
