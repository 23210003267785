





































































































































































































































.circle-detail-container {
  min-height: 800px;
  .content-con {
    display: grid;
    grid-template-columns: 865px 300px;
    grid-column-gap: 10px;
  }
  .detail-content {
    .circle-detail-info {
      padding-top: 139px;
      background-color: #fff;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      margin-bottom: 10px;
      .bg-con {
        width: 100%;
        height: 160px;
        position: absolute;
        top: 0;
      }
      .info-con {
        min-height: 100px;
        padding: 0 48px 24px 48px;
        position: relative;
        z-index: 2;
        .top-info-con {
          height: 100px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .left {
            display: flex;
            align-items: flex-start;
            .logo {
              width: 100px;
              height: 100px;
              border-radius: 38px;
              object-fit: cover;
            }
            .info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 20px;
              padding-top: 33px;
              .circle-title {
                font-size: 18px;
                font-weight: 600;
                color: #313131;
                line-height: 25px;
                margin-bottom: 5px;
              }
              .detail-num-info {
                font-size: 14px;
                font-weight: 400;
                color: #313131;
                line-height: 20px;
                display: flex;
                align-items: center;
                .num-info-item {
                  margin-right: 16px;
                }
                .info-label {
                  color: #919191;
                }
              }
            }
          }
          .join-circle-btn {
            margin-top: 33px;
            width: 100px;
            height: 29px;
            background: #4b8fff;
            border-radius: 15px;
            font-size: 12px;
            font-weight: 600;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .icon {
              font-size: 16px;
            }
            &.is-entered {
              background: #cccccc;
            }
          }
        }
        .intro {
          font-size: 14px;
          font-weight: 400;
          color: #919191;
          line-height: 20px;
          margin-top: 10px;
        }
      }
    }

    .no-list-con {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 469px;
      background: #ffffff;
      border-radius: 8px;
      .no-img {
        width: 87px;
        height: 102px;
        margin-bottom: 32px;
      }
      font-size: 16px;
      color: #595959;
      line-height: 22px;
      text-align: center;
      .go-btn {
        width: 300px;
        height: 40px;
        background: #4b8fff;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        margin-top: 15px;
        cursor: pointer;
      }
    }
  }
  // .side-right {
  //   position: -webkit-sticky;
  //   position: sticky;
  //   top: 0;
  // }
  .error-con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 898px;
    background: #ffffff;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-weight: 400;
    color: #595959;
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;
    .no-img {
      width: 166px;
      height: 111px;
      margin-bottom: 24px;
    }
    .go-circle-home {
      width: 300px;
      height: 40px;
      background: #4b8fff;
      border-radius: 20px;
      line-height: 40px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      margin-top: 15px;
      cursor: pointer;
    }
  }
}
