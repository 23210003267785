







































































































































::v-deep {
  .add-notes {
    // margin-top: calc(40vh - 150px) !important;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0 0 20px 0;
    }
    .header-ctx {
      font-size: 18px;
      color: #262626;
      padding: 20px 0 16px;
      font-weight: bolder;
      text-align: center;
      position: relative;
      .close-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }
    }
    .dialog-ctx {
      padding: 0 32px;
      .input-box {
        .el-textarea__inner {
          resize: none;
        }
      }
      .note-scope {
        font-size: 14px;
        color: #313131;
        display: flex;
        align-items: center;
        margin-top: 16px;
        .el-radio {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          .el-radio__input {
            line-height: 0;
          }
          .el-radio__label {
            color: #595959;
            font-weight: 400;
          }
          &.is-checked {
            .el-radio__label {
              color: #4b8fff;
              font-weight: bolder;
            }
            .el-radio__inner {
              background: #ffffff;
              &::after {
                width: 6px;
                height: 6px;
                background: #4b8fff;
              }
            }
          }
        }
      }
      .note-tips-text {
        font-size: 14px;
        color: #919191;
        line-height: 20px;
        margin-top: 16px;
      }
      .submit-btn {
        width: 200px;
        height: 40px;
        color: #ffffff;
        font-size: 14px;
        // background: #4b8fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto 0;
        cursor: pointer;
      }
    }
  }
}
