






























input::-webkit-input-placeholder {
  // 谷歌
  color: #bfbfbf !important;
}

input:-moz-placeholder {
  //火狐
  color: #bfbfbf !important;
}

input::-moz-placeholder {
  color: #bfbfbf;
}
