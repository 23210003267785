/* element */
.el-button--primary {
  border-color: #4b8fff;
  background-color: #4b8fff;
}

.el-upload-list--picture .el-upload-list__item-thumbnail {
  width: 124px !important;
}

.el-tabs__content {
  overflow: visible;
}

.el-icon-search {
  cursor: pointer;
}

.el-popover {
  padding: 1px;
}

.es-header .el-submenu__title {
  background-color: #fff !important;
  border-bottom: none !important;
}

.es-header .el-submenu__title i {
  display: none;
}

.es-header .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.bt-notification .el-notification__title {
  font-size: 14px;
  font-weight: 500;
  color: #002333;
}

.bt-notification .el-notification__content {
  font-size: 12px;
  color: #555;
}

.bt-notification .el-icon-close {
  padding: 12px;
  margin-top: -18px;
}

.el-message-box {
  max-width: 100%;
}

.el-zoom-in-right-enter-active,
.el-zoom-in-right-leave-active {
  width: 260px !important;
  opacity: 1;
  /*transform: scale(1, 1) translate(100%, 0);*/
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: right;
}

.el-zoom-in-right-enter,
.el-zoom-in-right-leave-active {
  width: 0 !important;
  opacity: 0;
  /*transform: scale(.45, 1) translate(0, 0);*/
}

.el-message {
  z-index: 9999 !important;
}

.tiku-confirm-redo-cancel {
  float: right;
  margin-left: 10px;
}