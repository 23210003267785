


































































































































.pin-carousel {
  width: 100%;
  padding-top: 12px;
  .action-bar {
    display: flex;
    align-items: center;
    height: 32px;
    background: #f7f8f9;
    border-radius: 8px 8px 0px 0px;
    padding-left: 16px;

    .action-item {
      margin-right: 24px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      .icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        object-fit: cover;
        margin-right: 4px;
      }
      // .big-preview-img {
      //   position: absolute;
      //   left: 0;
      //   width: 100px;
      //   height: 100%;
      //   ::v-deep {
      //     .el-image__preview {
      //       opacity: 0;
      //     }
      //   }
      // }

      // &:hover {
      //   color: #4b8fff;
      // }
    }
  }
  .carousel-body {
    width: 100%;
    position: relative;
    .section-img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0);
      transform-origin: center center;
    }
    .toggle-area {
      width: 120px;
      height: 100%;
      position: absolute;
      &.prev {
        left: 0;
        cursor: url(https://img.t.sinajs.cn/t6/style/images/common/pic_prev.cur), auto;
      }
      &.next {
        right: 0;
        cursor: url(https://img.t.sinajs.cn/t6/style/images/common/pic_next.cur), auto;
      }
    }
  }
  .nav-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;

    .nav-img {
      width: 141px;
      height: 141px;
      margin-right: 8px;
      border-radius: 8px;
      margin-top: 8px;
      cursor: pointer;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &.is-preview {
    .nav-list {
      margin-top: 16px;
      max-width: 100%;
      .nav-img {
        width: 52px;
        height: 52px;
        margin-right: 10px;
        border-radius: 4px;
        opacity: 0.59;
        position: relative;
        &:hover {
          opacity: 1;
          &::after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background: rgba(89, 89, 89, 0.59);
            border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        &.is-active {
          border: 2px solid #ffa934;
          opacity: 1;
        }
      }
    }
  }
}
