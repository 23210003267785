



























































































































































































































































.card-ask-answer {
  display: flex;
  margin-bottom: 16px;
  position: relative;
  border-bottom: 1px solid #efefef;
  .answer-item-left {
    flex-shrink: 0;
    width: 40px;
    .user-avatar-ctx {
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .v-icon {
        position: absolute;
        width: 14px;
        height: 14px;
        right: 0;
        bottom: 0;
      }
    }
  }
  .answer-item-right {
    flex: 1;
    width: 0;
    padding-left: 8px;
    .answer-user {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      .answer-user-name {
        color: #313131;
        font-size: 14px;
        margin-right: 4px;
      }
      .teacher-tag {
        display: flex;
        align-items: center;
        .left-icon {
          flex-shrink: 0;
          width: 21px;
          height: 28px;
          z-index: 99;
        }
        .right-text {
          height: 18px;
          display: flex;
          align-items: center;
          padding-right: 8px;
          padding-left: 16px;
          font-size: 12px;
          color: #ffffff;
          background: linear-gradient(90deg, #ffa002 0%, #ff6701 100%);
          border-radius: 0px 100px 100px 0px;
          margin: 6px -1px 4px -12px;
        }
      }
      .v-teacher {
        width: 100px;
        height: 30px;
      }
    }
    .answer-info {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .answer-info-time {
        font-size: 12px;
        color: #919191;
        line-height: 17px;
        margin-right: 12px;
      }
      .answer-info-num {
        font-size: 12px;
        color: #919191;
        line-height: 17px;
      }
    }
    .answer-content {
      color: #313131;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
      word-break: break-all;
    }
    .teacher-comment {
      border-left: 4px solid #e8e8e8;
      display: flex;
      margin-bottom: 16px;
      .t-comment-avatar-ctx {
        position: relative;
        height: 32px;
        padding-left: 8px;
        .t-comment-avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        .v-icon {
          position: absolute;
          width: 12px;
          height: 12px;
          right: 0;
          bottom: 0;
        }
      }
      .t-info {
        flex: 1;
        width: 0;
        padding-left: 12px;
        .t-name {
          color: #313131;
          font-size: 12px;
          line-height: 17px;
        }
        .t-time {
          font-size: 12px;
          color: #919191;
          line-height: 17px;
          margin-bottom: 8px;
        }
        .t-content {
          font-size: 12px;
          color: #313131;
          word-break: break-all;
          line-height: 17px;
        }
      }
    }
    .answer-tips {
      height: 32px;
      display: flex;
      align-items: center;
      background: rgba(75, 143, 255, 0.1);
      padding: 0 16px;
      font-size: 14px;
      color: #595959;
      border-radius: 4px;
      margin-bottom: 16px;
      .go-ask {
        color: #4b8fff;
        cursor: pointer;
      }
    }
    .comment-list {
      background: #f7f8f9;
      border-radius: 4px;
      padding: 20px 20px 0;
      margin-bottom: 16px;
      .comment-item {
        display: flex;
        .comment-item-left {
          .comment-avatar-ctx {
            position: relative;
            .comment-avatar {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              margin-top: 4px;
            }
            .v-icon {
              position: absolute;
              width: 14px;
              height: 14px;
              right: 0;
              bottom: 0;
            }
          }
        }
        .comment-item-right {
          flex: 1;
          width: 0;
          padding-left: 12px;
          .comment-item-right-1 {
            color: #313131;
            font-size: 14px;
            display: flex;
            align-items: center;
            img {
              width: 28px;
              height: 14px;
              margin-left: 4px;
            }
            margin-bottom: 2px;
          }
          .comment-item-right-2 {
            font-size: 12px;
            line-height: 17px;
            color: #919191;
            margin-bottom: 8px;
          }
          .comment-item-right-3 {
            font-size: 14px;
            color: #313131;
            line-height: 20px;
            margin-bottom: 12px;
            word-break: keep-all;
          }
        }
      }
    }
    .answer-footer {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .c-tools-item {
        display: flex;
        align-items: center;
        margin-right: 80px;
        cursor: pointer;
        .c-tools-icon {
          width: 24px;
          height: 24px;
          display: block;
          margin-right: 2px;
        }
        .c-tools-text {
          flex-shrink: 0;
          position: relative;
          .c-tools-count {
            position: absolute;
            top: 0;
            width: 80px;
            right: -80px;
          }
        }
      }
    }
  }
  .is-adopted {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 32px;
    background: #4b8fff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    font-weight: bolder;
  }
  .no-adopted {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 32px;
    background: rgba(51, 199, 78, 0.1);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #33c74e;
    font-size: 14px;
    font-weight: bolder;
  }
}
.teacher-answer {
  .answer-item-left {
    padding-top: 5px;
  }
}
.c-images {
  display: flex;
  margin-bottom: 16px;
  .c-images-item {
    width: 60px;
    height: 60px;
    margin-right: 8px;
    .c-images-box {
      width: 60px;
      height: 60px;
      display: block;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}
