











































































.login-container {
  border-radius: 4px;
  background: #ffffff;
  width: 375px;
  min-height: 300px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;

  &.mobile-view {
    width: 310px;
    height: 330px;
  }

  h1 {
    font-size: 20px;
    margin: 38px 0 16px;
    height: 28px;
    line-height: 28px;
    color: #363636;
  }
  .tip-icon {
    width: 79px;
    position: absolute;
    right: 42px;
    top: 11px;
    cursor: pointer;
  }
  .corner-icon {
    width: 55px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .login-way {
    margin-top: 28px;
    font-size: 14px;
    color: #5a5a5a;
    overflow: hidden;
    display: flex;
    .custom-btn-wrap {
      width: 50%;
      cursor: pointer;
    }
    .custom-btn {
      height: 30px;
      font-weight: 500;
      line-height: 20px;
      padding-bottom: 8px;
      display: inline-block;
      &.active {
        border-bottom: 2px solid #4b8fff;
      }
    }
  }
}
