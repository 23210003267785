








































@font-face {
  font-family: 'AlibabaPuHuiTi';
  src: url('../../../../../assets/fonts/AlibabaPuHuiTi-2-105-Heavy.otf');
  font-weight: normal;
  font-style: normal;
}
.act-dialog-container {
  box-shadow: 0rem 0.0391rem 0.1563rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.1563rem;
  width: 10.7422rem;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  background: transparent !important;
  box-shadow: none !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 !important;
  }
  .act-diaolog-con {
    width: 10.7422rem;
    height: 100%;
    border-radius: 0.1563rem;
    background-color: transparent;
    position: relative;
    flex-shrink: 0;
    .close-icon {
      width: 0.7813rem;
      height: 0.7813rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0.1953rem;
      top: 0.1953rem;
      .close-icon-img {
        width: 0.2734rem;
        height: 0.2734rem;
        cursor: pointer;
      }
    }
    .dialog-head-con {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0.6055rem;
      padding-bottom: 0.7422rem;
      background-image: url(https://tmp.btclass.cn/yunyin/o_1hcrv3tr71rtsus81rgb1q6g9mi.png);
      height: 3.6328rem;
      width: 100%;
      background-size: cover;
      .c-title {
        display: inline-block;
        height: 0.6445rem;
        font-weight: 600;
        background: linear-gradient(180deg, #fe393c 0%, #cf171e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 0.6445rem;
        margin-bottom: 0 0.3125rem 0.3125rem 0.3125rem;
        font-size: 0.6641rem;
        position: relative;
        margin: 0 0.1953rem;
        font-family: AlibabaPuHuiTi;
        &::after,
        &::before {
          content: '';
          display: inline-block;
          width: 0.8984rem;
          height: 0.0977rem;
          background: linear-gradient(297deg, rgba(239, 201, 158, 0) 0%, #fe3535 100%, #da9865 100%);
          border-radius: 0.0391rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          left: -1.2695rem;
        }
        &::after {
          right: -1.2695rem;
        }
        &::before {
          transform: rotate(180deg);
        }
      }
      .s-title {
        font-size: 0.5469rem;
        font-weight: normal;
        color: #363636;
        line-height: 0.7422rem;
        margin-top: 0.1563rem;
        font-family: AlibabaPuHuiTi;
      }
    }
    .act-dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 0.3125rem;
      color: #363636;
      line-height: 0.4297rem;
      padding-top: 0.5273rem;
      padding-bottom: 0.7813rem;
      background-image: url(https://tmp.btclass.cn/yunyin/o_1hcrv3tr71qtvr4vhbns06hoih.png);
      background-size: 100% 100%;
      margin-top: -0.0293rem;
      .qr-img {
        width: 4.2969rem;
        height: 4.2969rem;
        margin-top: 0.3125rem;
        object-fit: cover;
      }
      .qr-tip {
        height: 0.5273rem;
        font-size: 0.3906rem;
        font-weight: normal;
        color: #c46245;
        line-height: 0.5273rem;
        margin-top: 0.3125rem;
      }
    }
  }
}
