@font-face {
  font-family: "es-icon";
  src: url("../fonts/es-icon.eot?t=1459854189"); /* IE9*/
  src: url("../fonts/es-icon.eot?t=1459854189#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/es-icon.woff?t=1459854189") format("woff"),
    /* chrome, firefox */ url("../fonts/es-icon.ttf?t=1459854189") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/ url("../fonts/es-icon.svg?t=1459854189#es-icon") format("svg"); /* iOS 4.1- */
  font-display: swap;
}

@font-face {
  font-family: "iconfont"; /* Project id 2594854 */
  src: url('//at.alicdn.com/t/c/font_2594854_hzte9s0w0l6.woff2?t=1701846688645') format('woff2'),
       url('//at.alicdn.com/t/c/font_2594854_hzte9s0w0l6.woff?t=1701846688645') format('woff'),
       url('//at.alicdn.com/t/c/font_2594854_hzte9s0w0l6.ttf?t=1701846688645') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  vertical-align: -2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.es-icon-v2--new-user:before{
  content:'\e6ad'
}
.es-icon-v2-user:before {
  content: "\e63c";
}

.es-icon-v2-thumb:before {
  content: "\e63b";
}

.es-icon-v2-wallet:before {
  content: "\e63a";
}

.es-icon-v2-power:before {
  content: "\e639";
}

.es-icon-v2-dashboard:before {
  content: "\e637";
}

.es-icon-v2-wodexuexi:before {
  content: "\e635";
}

.es-icon-v2-wodexiaoxi:before {
  content: "\e634";
}

.es-icon {
  font-family: "es-icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.es-icon-qrcode:before {
  content: "\e615";
}
.es-icon-time:before {
  content: "\e69d";
}
.es-icon-videocam:before {
  content: "\e678";
}
.es-icon-anonymous-iconfont:before {
  content: "\e666";
}
.es-icon-wenhao:before {
  content: "\e671";
}
.es-icon-dot:before {
  content: "\e68a";
}
.es-icon-close01:before {
  content: "\e68e";
}
.es-icon-view:before {
  content: "\e6a4";
}
.es-icon-icon_close_circle:before {
  content: "\e687";
}
.es-icon-focus:before {
  content: "\e69e";
}
.es-icon-angledoubleright:before {
  content: "\e67d";
}
.es-icon-angledoubleleft:before {
  content: "\e67e";
}
.es-icon-playcircleoutline:before {
  content: "\e66d";
}
.es-icon-portrait:before {
  content: "\e65e";
}
.es-icon-accesstime:before {
  content: "\e658";
}
.es-icon-accountcircle:before {
  content: "\e668";
}
.es-icon-addbox:before {
  content: "\e65f";
}
.es-icon-checkbox:before {
  content: "\e660";
}
.es-icon-explore:before {
  content: "\e67f";
}
.es-icon-forum:before {
  content: "\e662";
}
.es-icon-history:before {
  content: "\e670";
}
.es-icon-removecircle:before {
  content: "\e66c";
}
.es-icon-thumbup:before {
  content: "\e659";
}
.es-icon-reply:before {
  content: "\e65a";
}
.es-icon-textsms:before {
  content: "\e657";
}
.es-icon-alarmon:before {
  content: "\e672";
}
.es-icon-assignment:before {
  content: "\e679";
}
.es-icon-assignmentind:before {
  content: "\e661";
}
.es-icon-keyboardarrowdown:before {
  content: "\e663";
}
.es-icon-keyboardarrowup:before {
  content: "\e664";
}
.es-icon-language:before {
  content: "\e65b";
}
.es-icon-link:before {
  content: "\e690";
}
.es-icon-livehelp:before {
  content: "\e680";
}
.es-icon-locallibrary:before {
  content: "\e66e";
}
.es-icon-loyalty:before {
  content: "\e65c";
}
.es-icon-mylibrarybooks:before {
  content: "\e67a";
}
.es-icon-mylocation:before {
  content: "\e669";
}
.es-icon-phone:before {
  content: "\e676";
}
.es-icon-permcontactcal:before {
  content: "\e66f";
}
.es-icon-publish:before {
  content: "\e66a";
}
.es-icon-removeredeye:before {
  content: "\e67b";
}
.es-icon-remove:before {
  content: "\e667";
}
.es-icon-addcircle:before {
  content: "\e66b";
}
.es-icon-search:before {
  content: "\e600";
}
.es-icon-mail:before {
  content: "\e601";
}
.es-icon-personadd:before {
  content: "\e602";
}
.es-icon-person:before {
  content: "\e604";
}
.es-icon-arrowdropdown:before {
  content: "\e603";
}
.es-icon-drafts:before {
  content: "\e605";
}
.es-icon-notifications:before {
  content: "\e606";
}
.es-icon-notificationsoff:before {
  content: "\e607";
}
.es-icon-notificationson:before {
  content: "\e608";
}
.es-icon-book:before {
  content: "\e609";
}
.es-icon-bookmarkoutline:before {
  content: "\e60a";
}
.es-icon-bookmark:before {
  content: "\e60b";
}
.es-icon-graphicclass:before {
  content: "\e60e";
}
.es-icon-description:before {
  content: "\e60f";
}
.es-icon-phone1:before {
  content: "\e610";
}
.es-icon-swaphoriz:before {
  content: "\e611";
}
.es-icon-audioclass:before {
  content: "\e60d";
}
.es-icon-flashclass:before {
  content: "\e612";
}
.es-icon-pptclass:before {
  content: "\e613";
}
.es-icon-videoclass:before {
  content: "\e614";
}
.es-icon-crown:before {
  content: "\e616";
}
.es-icon-exit:before {
  content: "\e617";
}
.es-icon-arrowback:before {
  content: "\e618";
}
.es-icon-arrowdropup:before {
  content: "\e619";
}
.es-icon-arrowforward:before {
  content: "\e61a";
}
.es-icon-chevronleft:before {
  content: "\e61b";
}
.es-icon-chevronright:before {
  content: "\e61c";
}
.es-icon-refresh:before {
  content: "\e61d";
}
.es-icon-menu:before {
  content: "\e61e";
}
.es-icon-android:before {
  content: "\e61f";
}
.es-icon-apple:before {
  content: "\e620";
}
.es-icon-comment:before {
  content: "\e621";
}
.es-icon-favoriteoutline:before {
  content: "\e622";
}
.es-icon-favorite:before {
  content: "\e623";
}
.es-icon-flag:before {
  content: "\e624";
}
.es-icon-help:before {
  content: "\e625";
}
.es-icon-home:before {
  content: "\e626";
}
.es-icon-importexport:before {
  content: "\e627";
}
.es-icon-lock:before {
  content: "\e628";
}
.es-icon-noteadd:before {
  content: "\e629";
}
.es-icon-power:before {
  content: "\e62a";
}
.es-icon-setting:before {
  content: "\e62b";
}
.es-icon-share:before {
  content: "\e62c";
}
.es-icon-starhalf:before {
  content: "\e62d";
}
.es-icon-staroutline:before {
  content: "\e62e";
}
.es-icon-star:before {
  content: "\e62f";
}
.es-icon-studydone:before {
  content: "\e630";
}
.es-icon-study:before {
  content: "\e631";
}
.es-icon-toc:before {
  content: "\e632";
}
.es-icon-calendar:before {
  content: "\e633";
}
.es-icon-contentcopy:before {
  content: "\e634";
}
.es-icon-delete:before {
  content: "\e635";
}
.es-icon-done:before {
  content: "\e636";
}
.es-icon-edit:before {
  content: "\e637";
}
.es-icon-filedownload:before {
  content: "\e638";
}
.es-icon-fileupdate:before {
  content: "\e639";
}
.es-icon-help1:before {
  content: "\e63a";
}
.es-icon-infooutline:before {
  content: "\e63b";
}
.es-icon-info:before {
  content: "\e63c";
}
.es-icon-send:before {
  content: "\e63d";
}
.es-icon-today:before {
  content: "\e63e";
}
.es-icon-viewlist:before {
  content: "\e63f";
}
.es-icon-viewmodule:before {
  content: "\e640";
}
.es-icon-visibilityoff:before {
  content: "\e641";
}
.es-icon-visibility:before {
  content: "\e642";
}
.es-icon-administrator:before {
  content: "\e643";
}
.es-icon-qq:before {
  content: "\e644";
}
.es-icon-qzone:before {
  content: "\e645";
}
.es-icon-renren:before {
  content: "\e646";
}
.es-icon-weibo:before {
  content: "\e65d";
}
.es-icon-weixin:before {
  content: "\e647";
}
.es-icon-done1:before {
  content: "\e649";
}
.es-icon-undone:before {
  content: "\e64a";
}
.es-icon-whatshot:before {
  content: "\e64b";
}
.es-icon-people:before {
  content: "\e64d";
}
.es-icon-school:before {
  content: "\e64c";
}
.es-icon-groupadd:before {
  content: "\e64e";
}
.es-icon-recentactors:before {
  content: "\e64f";
}
.es-icon-doneall:before {
  content: "\e650";
}
.es-icon-findinpage:before {
  content: "\e651";
}
.es-icon-headset:before {
  content: "\e652";
}
.es-icon-landscape:before {
  content: "\e653";
}
.es-icon-assignment1:before {
  content: "\e654";
}
.es-icon-accountwallet:before {
  content: "\e655";
}
.es-icon-dashboard:before {
  content: "\e656";
}
.es-icon-web:before {
  content: "\e67c";
}
.es-icon-check:before {
  content: "\e60c";
}
.es-icon-doing:before {
  content: "\e648";
}
.es-icon-icattachfileblack24px:before {
  content: "\e681";
}
.es-icon-morehoriz:before {
  content: "\e665";
}
.es-icon-introduction1:before {
  content: "\e673";
}
.es-icon-introduction2:before {
  content: "\e674";
}
.es-icon-introduction3:before {
  content: "\e675";
}
.es-icon-videoplay:before {
  content: "\e677";
}
.es-icon-chatcircle:before {
  content: "\e682";
}
.es-icon-locationcircle:before {
  content: "\e683";
}
.es-icon-warning:before {
  content: "\e685";
}
.es-icon-eventnote:before {
  content: "\e686";
}
.es-icon-localplay:before {
  content: "\e684";
}
.es-icon-money:before {
  content: "\e688";
}
.es-icon-lock1:before {
  content: "\e689";
}
.es-icon-iccheckcircleblack24px:before {
  content: "\e68b";
}
.es-icon-viewcomfy:before {
  content: "\e68c";
}
.es-icon-loading:before {
  content: "\e68d";
}
.es-icon-calendarok:before {
  content: "\e6a5";
}
.es-icon-trophy:before {
  content: "\e691";
}
.es-icon-arrowdropleft:before {
  content: "\e68f";
}
.es-icon-hd:before {
  content: "\e6bb";
}
.es-icon-activity:before {
  content: "\e692";
}
.es-icon-column:before {
  content: "\e693";
}
.es-icon-answer:before {
  content: "\e694";
}
.es-icon-comment1:before {
  content: "\e695";
}
.es-icon-exam:before {
  content: "\e696";
}
.es-icon-topic:before {
  content: "\e697";
}
.es-icon-target:before {
  content: "\e698";
}
.es-icon-homework:before {
  content: "\e699";
}
.es-icon-speed:before {
  content: "\e69a";
}
.es-icon-lesson:before {
  content: "\e69b";
}
.es-icon-addcircle1:before {
  content: "\e69c";
}
.es-icon-friends:before {
  content: "\e69f";
}
.es-icon-assessment:before {
  content: "\e6a0";
}
.es-icon-assignment2:before {
  content: "\e6a1";
}
.es-icon-analysis:before {
  content: "\e6a2";
}
.es-icon-download:before {
  content: "\e6a3";
}
.es-icon-change:before {
  content: "\e6bc";
}
.es-icon-icmorevertblack24px:before {
  content: "\e6a6";
}
.es-icon-data-order:before {
  content: "\e6a7";
}
.es-icon-zanting:before {
  content: "\e6a8";
}
.es-icon-huikanshimiao:before {
  content: "\e6a9";
}
.es-icon-quanping:before {
  content: "\e6aa";
}
.es-icon-bofang:before {
  content: "\e6ac";
}
.es-icon-tuichuquanping:before {
  content: "\e6ad";
}
.es-icon-lubogongkaike:before {
  content: "\e6b0";
}
.es-icon-putongkecheng:before {
  content: "\e6b1";
}
.es-icon-zhibokecheng:before {
  content: "\e6b2";
}
.es-icon-zhibokecheng2:before {
  content: "\e6b3";
}
.es-icon-ting:before {
  content: "\e6b8";
}
.es-icon-sousuo:before {
  content: "\e6b4";
}
.es-icon-yulan:before {
  content: "\e6b5";
}
.es-icon-xinxi:before {
  content: "\e6b6";
}
.es-icon-cuowu:before {
  content: "\e6b7";
}
.es-icon-yidong:before {
  content: "\e6b9";
}
.es-icon-huakuai:before {
  content: "\e6ba";
}
.es-icon-iconvolumelow:before {
  content: "\e6bd";
}
.es-icon-iconvolumehigh:before {
  content: "\e6be";
}
.es-icon-player-volume-mute:before {
  content: "\e6bf";
}
.es-icon-forward5:before {
  content: "\e6ab";
}
.es-icon-forward10:before {
  content: "\e6ae";
}
.es-icon-replay5:before {
  content: "\e6af";
}
.es-icon-replay10:before {
  content: "\e6c0";
}
.es-icon-weike:before {
  content: "\e6b8";
}
.es-icon-course:before {
  content: "\e6c1";
}
.es-icon-v2-service:before {
  content: "\e6c7";
}
