































































































































// 优惠券版块相关样式
.coupons-con {
  height: 10.1758rem;
  position: relative;
  .coupons-content {
    width: 100%;
    height: 100%;
    padding: 0 1.4844rem;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 3.5547rem;
  }
  .coupon-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .coupon-item {
      width: 4.8828rem;
      height: 3.4375rem;
      background-image: url('https://app-cdn.btclass.cn/new-prefix/turtle/FrvzsqWKTg1-WzJg21Z_rom34MbV.png');
      background-size: 100% 99.9%;
      position: relative;
      text-align: center;
      .usbale-icon {
        width: 1.3867rem;
        height: 0.918rem;
        background-image: url('https://app-cdn.btclass.cn/new-prefix/turtle/FmjJX6lSKy1xNBOdM5x53eH97fGO.png');
        background-size: 100% 99.9%;
        position: absolute;
        top: 0;
        right: 0;
      }
      .rate-price {
        height: 1.5234rem;
        line-height: 1.5234rem;
        font-size: 1.0938rem;
        font-weight: 600;
        color: #fde8b2;
        margin-top: 0.2344rem;
      }
      .price-threshold {
        width: 100%;
        height: 0.5469rem;
        line-height: 0.5469rem;
        font-size: 0.3906rem;
        color: #fde8b2;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0.3125rem;
      }
    }
  }
  .operation-btn {
    width: 13.2813rem;
    height: 1.7578rem;
    position: absolute;
    font-size: 0.625rem;
    font-weight: 600;
    color: #ffffff;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.6836rem;
    text-align: center;
    line-height: 1.5625rem;
    cursor: pointer;
  }
  img {
    width: 100%;
  }
}
.receive-dialog {
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  background: transparent !important;
  box-shadow: none !important;

  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  .receive-dialog-content {
    width: 5.8594rem;
    height: 3.0469rem;
    background: linear-gradient(134deg, #fff9f3 0%, #ffe3c1 100%);
    box-shadow: 0rem 0.0391rem 0.1563rem 0rem rgba(0, 0, 0, 0.1);
    border-radius: 0.1563rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.3125rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363636;
    line-height: 0.4297rem;
    .sure-btn {
      width: 3.3594rem !important;
      height: 1.0156rem;
      margin-top: 0.3516rem;
    }
  }
}
