










































































































































.subtitle-text {
  font-size: 14px;
  line-height: 24px;
  color: #919191;
  .btn-text {
    color: #4b8fff;
    cursor: pointer;
  }
}
