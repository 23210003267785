





















































































::v-deep {
  .create-discussion {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0 32px 20px 32px;
    }
    .header-ctx {
      font-size: 18px;
      color: #262626;
      padding: 20px 0 16px;
      font-weight: bolder;
      text-align: center;
      position: relative;
      .close-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }
    }
    .form-title {
      font-size: 14px;
      color: #313131;
      line-height: 20px;
      margin-bottom: 12px;
      .red-text {
        color: #ff6262;
      }
    }
    .input-textarea {
      margin-bottom: 15px;
      .el-textarea__inner {
        resize: none;
      }
    }
    .submit-btn {
      width: 200px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.m-b-16 {
  margin-bottom: 16px;
}
