.course-detail-header {
  position: relative;
}

.course-detail-header .es-section {
  position: relative;
  padding: 0;
  margin-bottom: 0;
}

.course-detail-header .setting {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0 3px;
  display: block;
}

.course-detail-header .setting+.setting {
  right: 35px;
}

.course-detail-header .course-img {
  position: relative;
  float: left;
  background-color: #fff;
}

@media (max-width: 767px) {
  .course-detail-header .course-img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.course-detail-header .course-img .course-img-cover {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.course-detail-header .course-img .course-img-cover:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.course-detail-header .course-img .course-img-cover .es-icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

.course-detail-header .course-img .status-mask {
  max-width: 100%;
  height: auto;
  z-index: 88;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.course-detail-header .course-img .status-mask img {
  visibility: hidden;
}

.course-detail-header .course-img .status-mask .text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  font-size: 22px;
  font-weight: 600;
  color: #FFFFFF;
}

.course-detail-header .course-img .status-mask,
.course-detail-header .course-img .img-responsive {
  width: 100%;
}

@media (max-width: 767px) {

  .course-detail-header .course-img .status-mask,
  .course-detail-header .course-img .img-responsive {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.course-detail-header .course-img .tags {
  position: absolute;
  top: 0;
  left: 10px;
}

.course-detail-header .course-img .tags span {
  margin-right: 5px;
}

.course-detail-header .course-img .tag-serialing,
.course-detail-header .course-img .tag-live,
.course-detail-header .course-img .tag-finished {
  display: inline-block;
  width: 40px;
  height: 50px;
}

.course-detail-header .course-img .tag-serialing {
  background: url("../images/course/tag_serialing.png") no-repeat;
  background-image: -webkit-image-set(url("../images/course/tag_serialing.png") 1x, url("../images/course/tag_serialing@2x.png") 2x);
}

.course-detail-header .course-img .tag-live {
  background: url("../images/course/tag_live.png") no-repeat;
  background-image: -webkit-image-set(url("../images/course/tag_live.png") 1x, url("../images/course/tag_live@2x.png") 2x);
}

.course-detail-header .course-img .tag-finished {
  background: url("../images/course/tag_finished.png") no-repeat;
  background-image: -webkit-image-set(url("../images/course/tag_finished.png") 1x, url("../images/course/tag_finished@2x.png") 2x);
}

.course-detail-header .title {
  color: #313131;
}

.course-detail-header .subtitle {
  color: #616161;
  margin-bottom: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.course-detail-header .metas {
  color: #616161;
}

.course-detail-header .metas p {
  font-size: 12px;
}

.course-detail-header .metas p label {
  width: 40px;
  margin-bottom: 0;
  font-weight: normal;
}

.course-detail-header .metas p span {
  display: inline-block;
}

.course-detail-header .expiry-date {
  margin-bottom: 0;
}

.course-detail-header .discount-price .price {
  font-size: 24px;
  color: #313131;
  margin-right: 10px;
}

.course-detail-header .discount-price .price i {
  font-size: 14px;
  font-style: normal;
}

.course-detail-header .discount-price .discount {
  position: relative;
  margin-left: 12px;
  padding: 0 15px 0 15px;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  vertical-align: super;
}

.course-detail-header .discount-price .discount:before {
  top: 0;
  left: -12px;
  border-width: 12px 12px 12px 0;
}

.course-detail-header .discount-price .discount:after {
  top: 10px;
  left: 1px;
}

.course-detail-header .activity {
  margin-bottom: 0;
}

.course-detail-header .activity .name {
  color: #313131;
  margin-right: 10px;
}

.course-detail-header .activity i {
  margin-right: 5px;
}

.course-detail-header .course-operation {
  padding: 16px 15px;
  background-color: #fafafa;
  color: #919191;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
}

@media (max-width: 991px) {
  .course-detail-header .course-operation {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: transparent;
  }
}

.course-detail-header .course-operation .student-num {
  text-align: center;
  font-size: 22px;
  padding: 20px 0;
}

.course-detail-header .course-operation .student-num i {
  margin-right: 10px;
  font-size: 28px;
}

.course-detail-header .course-data {
  list-style-type: none;
  padding: 10px 0;
}

.course-detail-header .course-data>li {
  float: left;
  width: 33.33%;
  margin: 10px 0;
  color: #919191;
  text-align: center;
  line-height: 30px;
}

@media (max-width: 1199px) {
  .course-detail-header .course-data>li {
    margin: 5px 0;
    line-height: 20px;
  }
}

.course-detail-header .course-data>li:first-child {
  border: none;
}

.course-detail-header .course-data>li .dropdown-toggle,
.course-detail-header .course-data>li>a {
  color: #919191;
}

.course-detail-header .course-data>li .dropdown-toggle:hover,
.course-detail-header .course-data>li>a:hover {
  color: #1e73ff;
}

.course-detail-header .course-data>li p {
  margin-bottom: 0;
}

.course-detail-header .course-data>li i {
  font-size: 26px;
}

.course-detail-header .course-data.active>li {
  width: 33.33%;
}

.course-detail-header.before .course-img {
  width: 480px;
  margin: 15px;
}

@media (max-width: 1199px) {
  .course-detail-header.before .course-img {
    width: 380px;
    margin: 10px;
  }
}

@media (max-width: 991px) {
  .course-detail-header.before .course-img {
    width: 340px;
  }
}

@media (max-width: 767px) {
  .course-detail-header.before .course-img {
    float: none;
    width: 100%;
    margin: 0;
  }
}

.course-detail-header.before .course-info {
  float: left;
  width: 390px;
  width: 388px\9;
  padding: 15px 20px 15px 5px;
  background-color: #fff;
}

@media (max-width: 1199px) {
  .course-detail-header.before .course-info {
    width: 350px;
    width: 348px\9;
    padding: 8px 10px;
  }

  .course-detail-header.before .course-info .title {
    margin-top: 10px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .course-detail-header.before .course-info .title {
    display: block;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .course-detail-header.before .course-info {
    width: 358px;
    width: 356px\9;
    height: 212px;
    border-top-right-radius: 4px;
  }
}

@media (max-width: 767px) {
  .course-detail-header.before .course-info {
    float: none;
    width: 100%;
  }
}

.course-detail-header.before .course-operation {
  float: right;
  width: 238px;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .course-detail-header.before .course-operation {
    width: 188px;
    padding: 10px;
  }
}

@media (max-width: 991px) {
  .course-detail-header.before .course-operation {
    float: none;
    width: 100%;
    padding: 10px 10px 0 10px;
  }
}

.course-detail-header.before .course-operation .student-num {
  border-bottom: 1px solid #f1f1f1;
}

@media (max-width: 991px) {
  .course-detail-header.before .course-operation .student-num {
    float: left;
    width: 33.33%;
    border: none;
    margin-top: 10px;
  }
}

.course-detail-header.before .course-operation .buy {
  padding: 26px 0;
  text-align: center;
}

@media (max-width: 1199px) {
  .course-detail-header.before .course-operation .buy {
    padding: 12px 0;
  }
}

@media (max-width: 991px) {
  .course-detail-header.before .course-operation .buy {
    padding: 18px 0 10px 0;
    float: left;
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .course-detail-header.before .course-operation .buy {
    width: 50%;
  }
}

.course-detail-header.before .course-operation .buy .btn {
  padding: 0;
}

.course-detail-header.before .course-data {
  border-bottom: 1px solid #f5f5f5;
}

@media (max-width: 991px) {
  .course-detail-header.before .course-data {
    float: left;
    width: 33.33%;
    margin-bottom: 0;
    border: none;
  }
}

@media (max-width: 767px) {
  .course-detail-header.before .course-data {
    width: 50%;
  }
}

.course-detail-header.before .course-data>li {
  width: 50%;
}

@media (max-width: 767px) {
  .course-detail-header.before .course-data>li {
    width: 50%;
  }
}

.course-detail-header.before .course-data.active>li {
  width: 33.33%;
}

.course-detail-header.after .course-img {
  width: 300px;
  margin: 15px;
}

@media (max-width: 1199px) {
  .course-detail-header.after .course-img {
    width: 240px;
    margin: 10px;
  }
}

@media (max-width: 767px) {
  .course-detail-header.after .course-img {
    width: 100%;
    margin: 0;
  }
}

.course-detail-header.after .course-info {
  float: left;
  width: 570px;
  width: 568px\9;
  padding: 15px 25px;
  background-color: #fff;
}

@media (max-width: 1199px) {
  .course-detail-header.after .course-info {
    width: 470px;
    width: 468px\9;
    padding: 10px;
  }

  .course-detail-header.after .course-info .title {
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  .course-detail-header.after .course-info {
    width: 320px;
    width: 318px\9;
  }
}

@media (max-width: 767px) {
  .course-detail-header.after .course-info {
    width: 100%;
  }
}

.course-detail-header.after .course-operation {
  float: right;
  width: 238px;
}

@media (max-width: 1199px) {
  .course-detail-header.after .course-operation {
    width: 208px;
    padding: 10px;
  }
}

@media (max-width: 991px) {
  .course-detail-header.after .course-operation {
    width: 138px;
  }
}

@media (max-width: 767px) {
  .course-detail-header.after .course-operation {
    width: 100%;
  }
}

.course-detail-header.after .course-operation .student-num {
  padding: 15px 0;
  border-bottom: 1px solid #f5f5f5;
}

@media (max-width: 1199px) {
  .course-detail-header.after .course-operation .student-num {
    font-size: 20px;
    padding: 10px 0;
  }
}

.course-detail-header.after .course-data {
  padding: 16px 0;
  margin-bottom: 0;
}

.course-detail-header.after .course-data.two-col>li {
  width: 50%;
}

.course-detail-header.after .progress {
  height: 11px;
  margin-bottom: 0;
}

.course-detail-header.after .course-header-bottom {
  padding: 15px;
  line-height: 40px;
  color: #919191;
}

.course-detail-header.after .course-header-bottom .next-class {
  float: right;
  margin-right: 50px;
  max-width: 600px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@media (max-width: 1199px) {
  .course-detail-header.after .course-header-bottom .next-class {
    max-width: 400px;
  }
}

@media (max-width: 1199px) {
  .course-detail-header.after .course-header-bottom .next-class {
    max-width: 250px;
    margin-right: 30px;
  }
}

.course-detail-header.after .course-header-bottom .next-class>a {
  color: #919191;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.course-detail-header.after .course-header-bottom .next-class>a:hover {
  color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.course-detail-header.after .course-header-bottom .btn {
  float: right;
  padding: 0;
}

.user-avatar-list li {
  float: left;
  width: 20%;
  margin-bottom: 10px;
  text-align: center;
}

@media (max-width: 991px) and (min-width: 768px) {
  .user-avatar-list li {
    width: 10%;
  }
}

.js-user-card {
  display: inline-block;
}

.nav-btn-tab {
  position: relative;
  padding-right: 80px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .nav-btn-tab {
    padding-right: 0;
  }
}

.nav-btn-tab .btnbar {
  position: absolute;
  top: 0;
  right: 0;
}

.nav.nav-tabs>li.active>a,
.nav.nav-tabs>li:hover>a {
  border: none;
  background: none;
  color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (max-width: 767px) {

  .nav.nav-tabs>li.active>a,
  .nav.nav-tabs>li:hover>a {
    border: none;
  }
}

.nav.nav-tabs>li.active>a .text-muted,
.nav.nav-tabs>li:hover>a .text-muted {
  color: #1e73ff;
}

.nav.nav-tabs>li.active>a .badge,
.nav.nav-tabs>li:hover>a .badge {
  background-color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav.nav-tabs {
  position: relative;
  margin-bottom: 25px;
  border-color: #f5f5f5;
}

.nav.nav-tabs .highlight {
  position: absolute;
  bottom: -1px;
  border-bottom: 2px solid #1e73ff;
}

@media (max-width: 767px) {
  .nav.nav-tabs .highlight {
    display: none;
  }
}

.nav.nav-tabs>li {
  margin-bottom: 0;
}

.nav.nav-tabs>li>a {
  border: none;
  margin-right: 0;
  color: #313131;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 5px 30px 15px;
}

@media (max-width: 1199px) {
  .nav.nav-tabs>li>a {
    padding: 5px 10px 10px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .nav.nav-tabs>li>a {
    padding: 8px;
  }
}

.nav.nav-tabs>li>a:hover,
.nav.nav-tabs>li>a:focus {
  background: none;
}

.nav.nav-tabs>li>a {
  padding: 5px 20px 15px;
}

@media (max-width: 767px) {
  .course-detail-content .subject-item .media-body .title {
    margin: 5px 0;
    width: 100%;
  }
}

.course-detail-content img {
  max-width: 100%;
  height: auto;
}

.course-detail-content .subject-item.course-item .media .media-left {
  padding-right: 15px;
}

.course-detail-content .subject-item img {
  max-width: inherit;
}

.course-detail-content .subject-item .media-body .title {
  font-size: 18px;
  margin: 5px 0 20px;
  width: 65%;
}

#ccH5historyTimeBox {
  display: none;
}