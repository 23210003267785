























































































.hot-lesson-container {
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #313131;
  line-height: 22px;
  margin-bottom: 16px;
  width: 300px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding-right: 0;
  .title {
    margin-bottom: 8px;
  }
  .lesson-list {
    max-height: 270px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 16px;
    .live-lesson-con {
      height: 100%;
      overflow: hidden;
    }
  }
  .btn-con {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    .turn-btn {
      cursor: pointer;
      height: 20px;
      width: 20px;
      &:first-child {
        margin-right: 8px;
      }
      // &.disabled {
      //   pointer-events: none;
      //   opacity: 0.6;
      // }
    }
  }
  // .swiper-slide {
  //   height: 85px !important;
  // }
  /*修改滚动条样式*/
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 2px;
    background: #efefef !important;
    margin-right: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #4b8fff;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4b8fff;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
