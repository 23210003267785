













































































































.add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 32px;
  cursor: pointer;
  .icon {
    font-size: 20px;
  }
}
.circle-card-con {
  width: 144px;
  height: 197px;
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.21);
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  .normal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    .circle-logo {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 23px;
      border: 1px solid #ffffff;
    }
    .circle-title {
      max-width: 124px;
      font-size: 16px;
      font-weight: 600;
      color: #363636;
      line-height: 25px;
      margin-top: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .dynamic-num {
      font-size: 14px;
      color: #8e8e93;
      line-height: 20px;
      margin-top: 8px;
    }
    .add-btn {
      margin-top: 12px;
      border-radius: 16px;
      border: 1px solid #4b8fff;
      font-size: 16px;
      font-weight: 600;
      color: #4b8fff;
      text-align: center;
      &.is-entered {
        background: #cccccc;
        border-radius: 16px;
        color: #fff !important;
        border: none !important;
      }
    }
  }

  &:hover {
    .mask-content {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 16px;
      .wrapper {
        max-height: 120px;
        display: flex;
        color: #fff;
        cursor: pointer;
      }
      .add-btn {
        border-radius: 16px;
        border: none;
        font-size: 16px;
        font-weight: 600;
        color: #4b8fff;
        background-color: #fff;
        margin-top: 13px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 16px;
        &.is-entered {
          color: #4b8fff;
          border: 1px solid #4b8fff;
          background: #cccccc;
          border-radius: 16px;
          color: #fff !important;
          border: none !important;
        }
      }
    }
  }
}
