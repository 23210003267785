


























































.item-errata-info {
  padding: 40px 0;
  .add-info {
    padding: 0 48px;
    .default-title {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
      font-weight: bolder;
      display: flex;
      align-items: center;
      color: #363636;
      .default-title-tips {
        margin-left: auto;
        font-size: 16px;
        color: #8e8e93;
        font-weight: normal;
      }
    }
    .book-info {
      margin-bottom: 24px;
      display: flex;
      .book-picture-box {
        width: 57px;
        height: 80px;
        position: relative;
        .book-picture-shadow {
          position: absolute;
          left: 0;
          right: 0;
          top: 12px;
          bottom: 0;
          box-shadow: 0px 2px 8px 0px rgba(0, 30, 117, 0.09);
        }
        .book-picture-img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 99;
        }
        .book-picture {
          width: 100%;
        }
        .book-type {
          position: absolute;
          top: 0;
          left: 0;
          width: 32px;
        }
      }
      .show-shadow {
        box-shadow: 0px 2px 8px 0px rgba(0, 30, 117, 0.09);
      }
      .book-title {
        margin-left: 12px;
        padding-top: 13px;
        font-size: 16px;
        color: #5a5a5a;
        font-weight: bolder;
      }
    }
    .page-num {
      font-size: 16px;
      color: #5a5a5a;
      margin-bottom: 24px;
    }
    .question-content {
      background: #f7f8f9;
      border-radius: 8px;
      padding: 20px;
      color: #5a5a5a;
      font-size: 16px;
      margin-bottom: 24px;
      white-space: pre-wrap;
    }
    .errata-pictures {
      margin-bottom: 24px;
      display: flex;
      .info-picture-item {
        width: 105px;
        height: 105px;
        margin-right: 8px;
        border-radius: 4px;
      }
    }
  }
  .reply-info {
    border-top: 1px solid #e5e5e5;
    padding: 40px 48px 0;
    .reply-header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .header-left {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #5a5a5a;
        font-weight: bolder;
        .header-icon {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
      }
      .header-right {
        margin-left: auto;
        font-size: 16px;
        color: #8e8e93;
        font-weight: normal;
      }
    }
    .reply-content {
      background: #f7f8f9;
      border-radius: 8px;
      padding: 20px;
      color: #5a5a5a;
      font-size: 16px;
      margin-bottom: 24px;
      white-space: pre-wrap;
    }
  }
}
