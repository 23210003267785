




















































































































































































































































































































.comment-item-con {
  margin-bottom: 15px;
  position: relative;
  &::after {
    content: '';
    height: 1px;
    display: inline-block;
    width: calc(100% - 48px);
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #efefef;
  }

  .cur-comment {
    margin-top: 16px;
    .right-con {
      margin-bottom: 16px;
    }
  }
  .replay-comment {
    padding-left: 48px;
    padding-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
    .avatar {
      height: 32px;
      width: 32px;
    }
  }
  .replay-num-span {
    font-size: 14px;
    font-weight: 400;
    color: #4b8fff;
    line-height: 20px;
    margin-left: 88px;
    padding-bottom: 17px;
    cursor: pointer;
  }
  .replay-comment-in {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: -4px;
    ::v-deep {
      .el-input__inner {
        border: none;
        background: #f7f8f9;
        border-radius: 4px;
      }
    }
    .comment-btn {
      margin-left: 16px;
      &:hover {
        background-color: #4b8fff;
      }
      &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  &.is-vialot {
    .o-btn {
      opacity: 0.7;
      pointer-events: none;
    }
  }
  .comment-line {
    display: flex;

    .avatar {
      // height: 40px;
      // width: 40px;
      // border-radius: 50%;
      margin-right: 8px;
    }
    .right-con {
      display: flex;
      flex-direction: column;
      width: 100%;
      .name-tag {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .name {
          font-size: 14px;
          font-weight: 600;
          color: #313131;
          line-height: 20px;
          margin-right: 4px;
          cursor: pointer;
        }
      }
      .comment-content {
        font-size: 14px;
        color: #313131;
        line-height: 20px;
        .replay-con {
        }
      }
      .oprera-con {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #616161;
        line-height: 20px;
        margin-top: 8px;
        .right-oprea {
          display: flex;
          align-items: center;
        }
      }
      .o-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-right: 30px;
        line-height: 20px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: #4b8fff;
        }
      }
      .like-btn {
        color: #919191;
        .icon {
          width: 21px;
          height: 20px;
          margin-right: 3px;
        }
        &.is-active {
          color: #4b8fff;
        }
      }
      .replay-btn {
        color: #616161;
      }
    }
    .del-btn,
    .complain-btn {
      display: none !important;
    }
    &:hover {
      .del-btn,
      .complain-btn {
        display: block !important;
      }
    }
  }
}
