.panel-default {
  padding: 0 15px;
  border-color: #e4ecf3;
}

@media (max-width: 767px) {
  .panel-default {
    padding: 0 10px;
  }
}

.panel-default>.panel-heading {
  position: relative;
  font-size: 16px;
  padding: 15px 0;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
}

@media (max-width: 767px) {
  .panel-default>.panel-heading {
    padding: 10px 0;
  }
}

.panel-default>.panel-heading .panel-title {
  color: #313131;
}

.panel-default>.panel-heading .panel-title>i {
  display: none;
}

.panel-default>.panel-heading .more {
  position: absolute;
  top: 13px;
  right: 0;
  display: block;
  color: #919191;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (max-width: 767px) {
  .panel-default>.panel-heading .more {
    top: 8px;
  }
}

.panel-default>.panel-heading .more:hover {
  color: #616161;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.panel-default>.panel-heading .panel-bar {
  position: absolute;
  top: 7px;
  right: 0;
  display: block;
}

.panel-default>.panel-footer {
  padding: 15px 0;
  background: none;
}

.panel-default>.panel-body {
  position: relative;
  padding: 15px 0;
}

.panel-primary>.panel-heading {
  background-color: #1e73ff;
  color: #fff;
}

.panel-primary>.panel-body {
  background: #fafafa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.panel-gray {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.panel-gray>.panel-heading {
  background-color: #f5f5f5;
  color: #919191;
}

.panel-gray>.panel-body {
  color: #919191;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.panel-page {
  padding: 45px 50px 50px;
  min-height: 500px;
}

@media (max-width: 767px) {
  .panel-page {
    padding: 15px;
    min-height: 300px;
  }
}

.panel-page .panel-heading {
  background: transparent;
  border-bottom: none;
  margin: 0 0 30px 0;
  padding: 0;
}

.panel-page .panel-heading h2 {
  font-size: 25px;
  margin-top: 0;
}

.media-list .media {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 15px;
}

.media-list .media:last-child {
  border-bottom: none;
}

.media-list .media-body .bullet {
  padding: 0 3px;
  font-size: 75%;
  color: #ccc;
  line-height: 1.4;
}

.media-list .empty-item {
  text-align: center;
  color: #999;
}

.media-body {
  word-break: break-all;
}

.media-left {
  padding-right: 15px;
}

.media .media-object-small img {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.media-default .media-body>.title {
  margin-bottom: 10px;
}

.media-default .media-body>.content {
  color: #919191;
}

.media.media-number-o .media-body {
  color: #919191;
}

.media.media-number,
.media.media-number-o {
  padding-bottom: 5px;
}

.media.media-number .media-left,
.media.media-number-o .media-left {
  position: relative;
  color: #919191;
}

.media.media-number .media-left .num,
.media.media-number-o .media-left .num {
  display: block;
  width: 20px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  border: 1px solid #919191;
  border-radius: 50%;
}

.media.media-number:last-child,
.media.media-number-o:last-child {
  border-bottom: none;
}

.subcomments {
  margin-left: 20px;
  padding-left: 10px;
  background-color: #f5f5f5;
}

.media-evaluate .media-body {
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.media-evaluate .title {
  color: #919191;
  margin-bottom: 5px;
}

.media-evaluate .title a {
  margin-right: 5px;
}

.media-evaluate .title .date {
  margin-left: 5px;
}

.media-evaluate .actions {
  margin-top: 10px;
}

.media-evaluate .actions .show-full-btn,
.media-evaluate .actions .show-short-btn {
  visibility: hidden;
}

.media-evaluate:hover .actions .show-full-btn,
.media-evaluate:hover .actions .show-short-btn {
  visibility: visible;
}

.media-group-list {
  margin-top: -20px;
}

.media-group {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .media-group {
    margin-top: 15px;
  }
}

.media-group:first-child {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .media-group:first-child {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .media-group .avatar-square-md {
    width: 40px !important;
    height: 40px !important;
  }
}

.media-group .title {
  height: 20px;
  overflow: hidden;
  margin: 5px 0;
}

@media (max-width: 767px) {
  .media-group .title {
    margin: 0;
  }
}

.media-group .metas {
  color: #c1c1c1;
}

.media-group .metas span {
  margin-right: 10px;
}

.media-group .metas i {
  margin-right: 5px;
  font-size: 14px;
}

.editor-text {
  overflow: hidden;
}

.editor-text img {
  max-width: 100% !important;
  height: auto !important;
}

.empty {
  text-align: center;
  color: #c1c1c1;
  padding: 20px 0;
}

/*通用按钮*/
.btn-primary {
  border-color: #4b8fff;
  background-color: #4b8fff;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #fff;
  background-color: #1e73ff;
}

.btn-link {
  font-weight: normal;
  border-radius: 0;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.focus {
  color: #1e73ff;
  text-decoration: none;
}

.btn-success {
  border-color: #56bb2b;
  background-color: #70d445;
  color: #fff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #fff;
  background-color: #5ac32d;
}

.btn-default {
  color: #616161;
  background-color: #f5f5f5;
  border-color: #dcdcdc;
}

.btn-default.disabled:hover,
.btn-default[disabled]:hover {
  color: #616161;
  background-color: #f5f5f5;
  border-color: #dcdcdc;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default.active {
  color: #616161;
  background-color: #e1e1e1;
}

.btn.btn-xlg {
  font-size: 14px;
  padding: 11px 35px;
}

/*话题*/
.thread-list .metas {
  font-size: 12px;
  color: #999;
}

.thread-list .metas .userImg {
  vertical-align: bottom;
}

.thread-list .metas .divider {
  margin: 0 5px;
  color: #ccc;
}

.thread-list .media-left {
  padding-right: 18px;
}

.thread-list .media-heading {
  margin-bottom: 10px;
}

.thread-list .reply-num {
  display: inline-block;
  width: 60px;
  height: 60px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #f1f1f1;
  color: #919191;
  text-align: center;
  font-size: 12px;
}

.thread-list .reply-num strong {
  display: block;
  font-size: 20px;
  font-weight: normal;
  padding: 7px 0 0;
}

/*头像样式*/
.avatar-xxs {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.avatar-xs {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
}

.avatar-vip {
  position: absolute;
  width: 23px;
  right: 8px;
  bottom: 8px;
  overflow: hidden;
}

.avatar-sm {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.avatar-md {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
  max-width: 60px;
}

.avatar-lg {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 120px !important;
  height: 120px !important;
}

/*超链接样式*/
.link-dark {
  color: #313131 !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-dark:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-light {
  color: #616161 !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-light:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-lighter {
  color: #919191 !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-lighter:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-primary {
  color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-primary:hover {
  color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-white {
  color: #fff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-white:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-primary {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-primary:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a.transition {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a.transition:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*通用颜色*/
.bg-primary {
  background-color: #1e73ff !important;
}

.color-primary {
  color: #1e73ff !important;
}

.color-warning {
  color: #ff8a0c !important;
}

.color-success {
  color: #70d445 !important;
}

.color-info {
  color: #4b8fff !important;
}

.color-danger {
  color: #e83d2c !important;
}

.color-yellow {
  color: #fbc02d !important;
}

.gray-darker {
  color: #313131 !important;
}

.gray-dark {
  color: #616161 !important;
}

.color-gray {
  color: #919191 !important;
}

.gray-medium {
  color: #c1c1c1 !important;
}

.gray-light {
  color: #e1e1e1 !important;
}

.gray-lighter {
  color: #f5f5f5 !important;
}

.text-sm {
  font-size: 12px !important;
}

.text-normal {
  font-size: 12px;
}

.well {
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/*用户下拉菜单*/
.dropdown-menu {
  border: none;
}

.dropdown-menu .el-menu--popup {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.dropdown-menu .el-menu--popup .dropdown-header {
  padding: 5px 20px;
  margin-bottom: 10px;
  text-align: center;
  color: #616161;
  font-size: 14px;
  border-bottom: 1px solid #f5f5f5;
}

.dropdown-header .item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-header .item.header-name {
  color: #333;
  font-size: 16px;
  vertical-align: middle;
  margin-bottom: 5px;
}

.dropdown-header .item.header-name .picture-vip {
  /*height: 28px;*/
  vertical-align: middle;
}

.dropdown-header .item.header-no-name {
  color: #999;
  font-size: 14px;
  margin-bottom: 5px;
}

.dropdown-header .item.header-no-name>a {
  color: inherit;
}

.dropdown-header .item.header-no-name>a>i {
  padding: 0 4px;
  font-size: 14px;
}

.dropdown-menu>li {
  position: relative;
}

.dropdown-menu>li>a {
  color: #616161;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dropdown-menu>li>a:hover {
  background: #1e73ff;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dropdown-menu .el-menu--popup {
  box-shadow: none;
}

.dropdown-menu .el-menu--popup>li {
  position: relative;
}

.dropdown-menu .el-menu--popup>li>a {
  color: #616161;
  padding: 8px 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dropdown-menu .el-menu--popup>li>a:hover {
  background: #1e73ff;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dropdown-menu .el-menu--popup>li a {
  display: block;
}

.dropdown-menu .el-menu--popup>li i {
  padding: 0 10px;
}

.nav-pills>li {
  margin-right: 5px;
}

.nav-pills>li>a {
  padding: 10px 15px;
  color: #616161;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav-pills>li>a:hover {
  background-color: #f5f5f5;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav-pills>li.active>a {
  color: #fff;
  background: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav-pills.nav-pills-sm>li>a {
  font-size: 12px;
  line-height: 1.5;
  padding: 4px 13px;
}

.el-pagination {
  text-align: center;
  margin: 25px 0;
}

.btn {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dropdown-menu {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.dropdown-menu .dropdown-header {
  padding: 5px 20px;
  margin-bottom: 10px;
  /*text-align: center;*/
  color: #616161;
  font-size: 14px;
  border-bottom: 1px solid #f5f5f5;
}

/*弹出框*/
.es-card {
  width: 330px;
  max-width: 330px;
  z-index: 999;
}

.es-card .popover-content {
  padding: 0;
}

.es-card .card-header {
  position: relative;
  padding: 15px;
  min-height: 95px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #f5f5f5;
}

.es-card .media {
  padding-right: 70px;
}

.es-card .media-left {
  position: relative;
}

.es-card .avatar-md {
  border: 2px solid #fff;
}

.es-card .user-avatar {
  position: relative;
}

.es-card .icon-user-status {
  position: absolute;
  bottom: 0;
  right: 0;
}

.es-card .content {
  font-size: 12px;
  margin-top: 6px;
  color: #919191;
}

.es-card .metas {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 70px;
  text-align: right;
}

.es-card .metas .btn {
  margin-bottom: 12px;
}

.es-card .metas .btn:last-child {
  margin-bottom: 0;
}

.es-card .card-body {
  line-height: 24px;
  padding: 20px;
  color: #919191;
}

.es-card .card-footer {
  border-top: 1px solid #f5f5f5;
}

.es-card .card-footer span {
  float: left;
  width: 33.33%;
  margin: 5px auto;
  text-align: center;
  line-height: 24px;
  border-right: 1px solid #f5f5f5;
}

.es-card .card-footer span:last-child {
  border: none;
}

.media-evaluate .media-body {
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.media-evaluate .title {
  color: #919191;
  margin-bottom: 5px;
}

.media-evaluate .title a {
  margin-right: 5px;
}

.media-evaluate .title .date {
  margin-left: 5px;
}

.media-evaluate .actions {
  margin-top: 10px;
}

.media-evaluate .actions .show-full-btn,
.media-evaluate .actions .show-short-btn {
  visibility: hidden;
}

.media-evaluate:hover .actions .show-full-btn,
.media-evaluate:hover .actions .show-short-btn {
  visibility: visible;
}

.icon-user-status {
  display: block;
  width: 20px;
  height: 20px;
  line-height: 15px;
  text-align: center;
  border: 2px solid #fff;
  background: #e83d2c;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.icon-user-status>i {
  font-size: 12px;
  vertical-align: middle;
}

.icon-user-status>img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.icon-user-status.icon-md {
  width: 30px;
  height: 30px;
  line-height: 25px;
}

.icon-user-status.icon-md>i {
  font-size: 18px;
}

.avatar-wrap {
  position: relative;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar-wrap .icon-user-status {
  position: absolute;
  bottom: 0;
  right: 0;
}

.score {
  margin-bottom: 10px;
}

.score i {
  color: #e1e1e1;
}

.score span {
  color: #919191;
  font-size: 12px;
  margin-left: 8px;
}

.subject-buy-form .form-group,
.combine-buy-form .form-group {
  padding: 0 20px;
}

.subject-buy-form .form-group .rows,
.combine-buy-form .form-group .rows {
  font-size: 16px;
}

.subject-buy-form .form-group .btn,
.combine-buy-form .form-group .btn {
  margin-bottom: 15px;
  margin-right: 5px;
}

.modal-title {
  word-break: break-all;
  color: #313131;
}

.modal-body {
  word-wrap: break-word;
  overflow: hidden;
}

.modal-body img {
  max-width: 100%;
  height: auto;
}

.modal-open {
  overflow: hidden;
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal {
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}

.modal-backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn+.btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modal-footer .btn-group .btn+.btn {
  margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 10% auto;
  }

  .modal-sm {
    width: 400px;
  }
}

/*通用按钮*/

.btn {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn:hover,
.btn:focus,
.btn.focus,
.btn.active {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
}

.btn.btn-lg {
  font-size: 14px;
}

.btn.btn-xlg {
  font-size: 14px;
  padding: 11px 35px;
}

.btn>i {
  font-size: 14px;
}

.btn-link {
  color: #313131;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.focus {
  color: #1e73ff;
  text-decoration: none;
}

.btn-gray {
  color: #616161;
}

.btn-gray:hover,
.btn-gray:focus,
.btn-gray.focus {
  color: #1e73ff;
  text-decoration: none;
}

.btn-default {
  color: #616161;
  background-color: #f5f5f5;
  border-color: #dcdcdc;
}

.btn-default.disabled:hover,
.btn-default[disabled]:hover {
  color: #616161;
  background-color: #f5f5f5;
  border-color: #dcdcdc;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default.active {
  color: #616161;
  background-color: #e1e1e1;
}

.btn-primary {
  border-color: #4b8fff;
  background-color: #4b8fff;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #fff;
  background-color: #1e73ff;
}

.btn-success {
  border-color: #56bb2b;
  background-color: #70d445;
  color: #fff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #fff;
  background-color: #5ac32d;
}

.btn-info {
  border-color: #4b8fff;
  background-color: #4b8fff;
  color: #fff;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #fff;
  background-color: #1e73ff;
}

.btn-warning {
  border-color: #d87000;
  background-color: #ff8a0c;
  color: #fff;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #fff;
  background-color: #e27500;
}

.btn-danger {
  border-color: #cb2616;
  background-color: #e83d2c;
  color: #fff;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #fff;
  background-color: #d42817;
}

.btn-circle {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 32px;
  border-radius: 50%;
  background: none;
  border: 1px solid #919191;
  color: #919191;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-circle:hover {
  color: #1e73ff;
  border-color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-circle.active {
  background: #1e73ff;
  border: 1px solid #1e73ff;
  color: #fff;
}

.btn-circle.danger {
  color: #fff;
  border-color: #e83d2c;
  background: #e83d2c;
}

.btn-circle.primary {
  color: #fff;
  border-color: #1e73ff;
  background: #1e73ff;
}

.btn-circle.info {
  color: #fff;
  border-color: #1e73ff;
  background: #1e73ff;
}

.btn-circle.btn-circle-xs {
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 12px;
}

.btn-circle.btn-circle-xs i {
  font-size: 14px;
}

.btn-circle.btn-circle-md {
  width: 40px;
  height: 40px;
  line-height: 38px;
}

.btn-circle.btn-circle-md i {
  font-size: 24px;
}

.btn-tag {
  display: inline-block;
  padding: 6px 10px;
  margin: 5px 5px 5px 0;
  color: #1e73ff;
  font-size: 12px;
  background-color: #f9fdff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-tag.active,
.btn-tag:hover {
  color: #fff;
  background-color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-fat {
  padding-left: 30px;
  padding-right: 30px;
}

.btn-fat-small {
  padding-left: 20px;
  padding-right: 20px;
}

.alert>i {
  margin-right: 10px;
}

.alert.alert-lg {
  padding: 30px 20px;
}

.alert.alert-xs {
  font-size: 12px;
  padding: 5px 10px;
  margin-bottom: 0;
}

.alert.alert-sm {
  padding: 8px 10px;
}

.alert a {
  text-decoration: none;
}

.alert-blank {
  color: #000;
  background-color: #fafafa;
}

.alert-success {
  border-color: #cddfb4;
  background-color: #f2fae3;
  color: #659f13;
}

.alert-success .close,
.alert-success .alert-link {
  color: #529819;
}

.alert-success hr {
  border-top-color: #c1d7a2;
}

.alert-info {
  border-color: #78abff;
  background-color: #78abff;
  color: #4b8fff;
}

.alert-info .close,
.alert-info .alert-link {
  color: #2180b5;
}

.alert-info hr {
  border-top-color: #bfe0f7;
}

.alert-notice {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  border: none;
  color: #1e73ff;
}

.alert-notice .swiper-container {
  position: relative;
  height: 38px;
  line-height: 38px;
  overflow: hidden;
}

.alert-notice .swiper-wrapper {
  padding-right: 30px;
}

.alert-notice .swiper-slide {
  float: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.alert-notice .close {
  float: none;
  position: absolute;
  top: 5px;
  right: 0;
  width: 30px;
}

.alert-warning {
  border-color: #ffd0b7;
  background-color: #fff9cd;
  color: #ff5e06;
}

.alert-warning .close,
.alert-warning .alert-link {
  color: #c34805;
}

.alert-warning hr {
  border-top-color: #ffbf9e;
}

.alert-danger {
  border-color: #f0c7bd;
  background-color: #fff1f0;
  color: #e74c3c;
}

.alert-danger .close,
.alert-danger .alert-link {
  color: #b50a00;
}

.alert-danger hr {
  border-top-color: #ebb5a8;
}

.alert-default {
  border-color: #e1e1e1;
  background-color: #f5f5f5;
}

.alert-default .close,
.alert-default .alert-link {
  color: #919191;
}

.alert-default.alert-edit:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #ffd0b7;
  background-color: #fff9cd;
  color: #ff5e06;
}

.alert-default.alert-edit:hover .close,
.alert-default.alert-edit:hover .alert-link {
  color: #c34805;
}

.alert-default.alert-edit:hover hr {
  border-top-color: #ffbf9e;
}

.alert-default.alert-edit:hover .editor>a {
  color: #cc5700;
}

.alert-default.alert-edit:hover .details {
  border-color: #ffa363;
}

.alert-default.alert-edit .alert-header {
  position: relative;
}

.alert-default.alert-edit .alert-header:hover {
  cursor: pointer;
}

.alert-default.alert-edit .icon-click {
  position: absolute;
  top: 0;
  left: 5px;
}

.alert-default.alert-edit .title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  padding: 0 150px 0 30px;
}

.alert-default.alert-edit .editor,
.alert-default.alert-edit .data {
  position: absolute;
  top: 0;
  right: 5px;
}

.alert-default.alert-edit .editor>a {
  color: #919191;
}

.alert-default.alert-edit .details {
  display: none;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e1e1e1;
}

.set-email-alert {
  font-size: 12px;
  text-align: center;
  margin-bottom: 0px;
  border-radius: 0px;
  padding: 5px;
}

.set-email-alert .close {
  font-size: 16px;
}

.period-list {
  margin-top: 20px;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.period-list>li {
  margin-bottom: 8px;
  padding: 10px 15px;
}

.period-list .chapter,
.period-list .section {
  background-color: #f5f5f5;
  color: #919191;
}

.period-list .chapter {
  position: relative;
}

.period-list .chapter:hover {
  cursor: pointer;
}

.period-list .chapter .es-icon-toc {
  position: absolute;
  top: 11px;
  left: 15px;
}

.period-list .chapter .period-show {
  position: absolute;
  top: 8px;
  right: 15px;
}

.period-list .chapter .title {
  padding: 0 30px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.period-list .section {
  padding-left: 45px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.period-list .period {
  position: relative;
  padding: 10px 210px 10px 10px;
}

.period-list .period.open-lesson-item {
  padding: 10px 290px 10px 10px;
}

.period-list .period.testpaper-item {
  padding: 10px 260px 10px 10px;
}

.period-list .period:hover,
.period-list .period.item-active {
  background-color: #f2fae3;
}

.period-list .period.disable {
  background: none;
}

.period-list .period>span,
.period-list .period>a {
  color: #919191;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  height: 22px;
}

.period-list .period .es-icon-undone,
.period-list .period .es-icon-doing,
.period-list .period .es-icon-done1 {
  position: absolute;
  top: 11px;
  left: 5px;
}

.period-list .period .title {
  color: #616161;
}

.period-list .period .course-type .lesson-start-time {
  font-size: 12px;
  color: #919191;
}

.period-list .period .course-type i {
  font-size: 20px;
}

.period-list .period .course-type small {
  margin-top: -3px;
}

.period-list .period .course-type .custom-btn i {
  float: left;
  line-height: 22px;
}

.period-list .period .custom-btn small {
  float: right;
  line-height: 24px;
  margin: 0;
}

@media (max-width: 767px) {
  .period-list .period {
    padding: 10px 10px 10px 45px;
  }

  .period-list .period.open-lesson-item {
    padding: 10px 10px 10px 45px;
  }

  .period-list .period.testpaper-item {
    padding: 10px 10px 10px 45px;
  }

  .period-list .period .date {
    position: static;
  }

  .period-list .period .period-state,
  .period-list .period .course-type {
    position: static;
    text-align: right;
    margin-top: 5px;
  }

  .period-list-compatible .period.open-lesson-item {
    padding: 10px 10px 10px 30px;
  }

  .period-list-compatible .period.testpaper-item {
    padding: 10px 10px 10px 30px;
  }

  .period-list-compatible .period .course-type {
    width: 68px;
    margin-top: 0;
  }

  .period-list-compatible .period .status-icon {
    margin-top: 11px;
  }

  .period-list-compatible .period .course-type .videoBtn {
    margin-bottom: 10px;
    margin-top: 0;
    margin-left: 0;
  }
}

.thread {
  margin-bottom: 50px;
  overflow-x: auto;
  overflow-y: hidden;
}

.thread-header {
  margin-bottom: 15px;
}

.thread-title {
  font-size: 20px;
  line-height: 36px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  word-wrap: break-word;
  overflow: hidden;
}

.thread-event-title {
  font-size: 20px;
  line-height: 36px;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  overflow: hidden;
}

.thread-event-title-bar {
  margin-top: 10px;
}

.thread-metas {
  color: #999;
  font-size: 12px;
}

.thread-body {
  margin-bottom: 15px;
  word-wrap: break-word;
  overflow: hidden;
}

.thread-body img {
  max-width: 100%;
  height: auto;
}

.thread-footer {
  text-align: right;
}

.thread-posts-heading {
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 3px solid #eee;
  margin-bottom: 20px;
  color: #000;
}

.thread-posts-heading .glyphicon {
  color: #ccc;
  font-size: 12px;
  margin-right: 5px;
}

.thread-post {
  overflow: visible;
}

.show-user {
  color: #bdb76b;
  font-size: 18px;
}

.thread pre,
.thread-post pre {
  overflow-x: scroll;
  word-wrap: normal;
}

.thread-post-dropdown {
  float: right;
  position: relative;
}

.thread-post-dropdown .dropdown-toggle {
  text-decoration: none;
}

.thread-post .thread-post-dropdown .dropdown-toggle {
  visibility: hidden;
}

.thread-post:hover .thread-post-dropdown .dropdown-toggle {
  visibility: visible;
}

.thread-post-action {
  text-align: right;
  font-size: 12px;
  visibility: hidden;
}

.thread-post:hover .thread-post-action {
  visibility: visible;
}

.thread-list-small {
  font-size: 13px;
}

.thread-list-small .metas {
  font-size: 12px;
  color: #999;
}

.thread-list-small .metas a {
  color: #777;
}

.thread-list-small .thread-item-body {
  padding-left: 10px;
  padding-right: 10px;
}

.thread-show .thread-breadcrumb {
  font-size: 13px;
  margin-bottom: 6px;
}

.thread-show .thread-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  color: inherit;
  font-weight: bold;
  line-height: 1;
  display: inline;
}

.thread-show .thread-metas {
  margin-top: 6px;
}

.thread-post-list .metas {
  font-size: 13px;
}

.thread-post-list .metas .nickname {
  font-weight: bold;
}

.thread-post-list .user-avatar img {
  width: 48px;
  height: 48px;
}

.thread-post-list .thread-post-interaction {
  font-size: 13px;
}

.thread-post-list .thread-post-interaction .interaction {
  margin-right: 10px;
}

.thread-subpost-list .user-avatar img {
  width: 32px;
  height: 32px;
}

.thread-subpost-container {
  margin-top: 10px;
  padding: 10px;
  background-color: #fdfdfd;
  border-radius: 3px;
  border: 1px solid #fdfdfd;
}

.thread-subpost-morebar {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
}

.thread-subpost-form {
  margin-top: 10px;
}

.thread-subpost-list .thread-subpost {
  margin-top: 10px;
  padding-bottom: 10px;
  border-color: #f6f6f6;
}

.thread-subpost-list .thread-subpost:first-child {
  margin-top: 0px;
}

.thread-subpost-list .thread-subpost:last-child {
  padding-bottom: 0px;
}

.thread-post-list .thread-post .thread-post-manage-dropdown {
  visibility: hidden;
}

.thread-post-list .thread-post:hover .thread-post-manage-dropdown {
  visibility: visible;
}

.thread-post-list .thread-post:hover .thread-subpost .thread-post-manage-dropdown {
  visibility: hidden;
}

.subcomments {
  margin-left: 20px;
  padding-left: 10px;
  background-color: #f5f5f5;
}

a.blue {
  color: #1e73ff;
}

@media (max-width: 767px) {
  .form-horizontal .form-control {
    margin-bottom: 10px;
  }
}

.form-control {
  color: #616161;
  border-color: #e1e1e1;
}

.form-control.form-control-lg {
  height: 40px;
}

.form-control::-webkit-input-placeholder {
  color: #919191;
}

.form-control:-moz-placeholder {
  color: #919191;
}

.form-control::-moz-placeholder {
  color: #919191;
}

.form-control:-ms-input-placeholder {
  color: #919191;
}

.label {
  font-weight: normal;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  vertical-align: middle;
  display: inline-block;
}

.label.label-md {
  font-size: 100%;
}

.label.label-xs {
  font-size: 12px;
  padding: 2px;
}

.label-success {
  background-color: #70d445;
}

.es-piece {
  margin-bottom: 30px;
}

.es-piece.piece-lg .piece-header {
  font-size: 22px;
  font-weight: 500;
  padding: 0 30px 0 0;
}

.es-piece.piece-lg .piece-header .more {
  font-size: 14px;
}

.es-piece.piece-lg .piece-header:after {
  display: none;
}

.es-piece .piece-header {
  position: relative;
  margin: 5px auto 20px auto;
  padding: 0 30px 0 15px;
  color: #313131;
  font-size: 16px;
}

.es-piece .piece-header:after {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 4px;
  height: 16px;
  background-color: #1e73ff;
}

.es-piece .piece-header .more {
  position: absolute;
  right: 0;
  top: 5px;
}

.es-piece .piece-body .piece-body-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.es-piece .piece-body .piece-body-list>li {
  padding: 15px 0;
  border-bottom: 1px solid #f5f5f5;
}

.es-share {
  position: relative;
  display: inline-block;
}

.es-share.top .dropdown-menu {
  top: auto;
  bottom: 100%;
  left: 0;
  margin-left: -60px;
  margin-bottom: 10px;
}

.es-share.top .dropdown-menu:after {
  top: 100%;
  left: 50%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 8px solid rgba(64, 64, 64, 0);
  border-top-color: #404040;
  margin-left: -8px;
}

.es-share .dropdown-menu {
  min-width: 145px !important;
  padding: 3px 10px !important;
  background-color: #404040 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  line-height: 30px !important;
}

.es-share .dropdown-menu a {
  margin: 3px 2px 2px 6px !important;
  color: #fff !important;
}

.es-share .dropdown-menu i {
  font-size: 20px !important;
}

.es-share i[class*="es-icon"] {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.es-share .es-icon-weixin:hover {
  color: #70d445;
}

.es-share .es-icon-weibo:hover {
  color: #e83d2c;
}

.es-share .es-icon-qzone:hover {
  color: #ff8a0c;
}

.es-share .es-icon-qq:hover {
  color: #4b8fff;
}

.es-qrcode {
  position: relative;
  text-align: center;
}

@media (max-width: 767px) {
  .es-qrcode {
    display: none;
  }
}

.es-qrcode>i {
  font-size: 24px;
  color: #919191;
}

.es-qrcode .qrcode-popover {
  position: absolute;
  padding: 5px 10px;
  font-size: 12px;
  background: #fff;
  color: #313131;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: none \9;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.es-qrcode .qrcode-popover img {
  margin-bottom: 10px;
  width: 110px;
  height: 110px;
}

.es-qrcode.toolbar-qrcode {
  color: #ccc;
}

.es-qrcode.toolbar-qrcode .qrcode-popover {
  top: 0;
  right: 100%;
  transform-origin: 100% 0;
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -o-transform-origin: 100% 0;
}

.es-qrcode.top .qrcode-popover {
  top: 0;
  left: 0;
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
}

.es-qrcode.bottom .qrcode-popover {
  bottom: 0;
  right: 0;
  transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
}

.es-qrcode:hover {
  cursor: pointer;
}

.es-qrcode.open .qrcode-popover {
  display: block \9;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  filter: alpha(opacity=100);
}

.lt-ie9 .es-qrcode .mask {
  border: 1px solid #e1e1e1;
}

.qrcode-popover {
  position: relative;
}

.qrcode-popover:hover .qrcode-content {
  display: block \9;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  filter: alpha(opacity=100);
}

.qrcode-popover.top .qrcode-content {
  left: -65px;
  top: -205px;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}

.qrcode-popover.top .qrcode-content:after {
  top: 100%;
  left: 50%;
  margin-left: -13px;
  border-top-color: #fff;
}

.qrcode-popover.top .qrcode-content img {
  width: 180px;
  height: 180px;
}

.qrcode-popover .qrcode-content {
  position: absolute;
  padding: 5px;
  z-index: 1;
  background: #fff;
  display: none \9;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.qrcode-popover .qrcode-content:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 13px;
}

.es-section {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #e4ecf3;
}

.es-section.section-lg {
  min-height: 400px;
}

.es-section .section-header {
  position: relative;
  margin-bottom: 20px;
  border-bottom: 1px solid #f5f5f5;
}

.es-section .section-header h1 {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 20px;
}

.es-section .section-header h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
}

.es-section .section-header .more {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  color: #919191;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.es-section .section-header .more:hover {
  color: #616161;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.es-section .section-header .more>i {
  font-size: 24px;
}

.aboutus-content {
  min-width: 1300px;
  font-family: PingFangSC-Regular;
}

.question-analysis-wrap td{
  border: 1px solid;
}

.show-scrollbar {
  &::-webkit-scrollbar-track-piece {
    background-color: #f1f1f1;
    border-left: 1px solid #e6ebf5;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    background-clip: padding-box;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    min-height: 28px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #787878;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
}