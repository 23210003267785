


















































































































.recomand-container {
  width: 300px;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  .title-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #313131;
    line-height: 20px;
    height: 20px;
    .more-circle {
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      color: #919191;
      line-height: 20px;
    }
  }
  .circle-list {
    padding-top: 24px;
    .circle-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .logo {
        width: 52px;
        height: 52px;
        border-radius: 20px;
        margin-right: 12px;
        position: relative;
        cursor: pointer;
        .logo-img {
          border-radius: 20px;
          width: 100%;
          height: 100%;
        }
        .hot {
          width: 28px;
          height: 16px;
          background: #ffe4e5;
          border-radius: 8px;
          // backdrop-filter: blur(10px);
          font-size: 10px;
          color: #ff6262;
          line-height: 16px;
          text-align: center;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .info-con {
        .title {
          width: 134px;
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          color: #313131;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .num-con {
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #919191;
          line-height: 17px;
          margin-top: 4px;

          .num-span {
            color: #313131;
          }
        }
      }
      .add-btn {
        width: 56px;
        height: 25px;
        background: #eaf1fe;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        color: #4b8fff;
        margin-left: 14px;
        cursor: pointer;
        .icon {
          font-size: 14px;
        }
        &.is-entered {
          background-color: #cccccc;
          color: #ffffff;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
