










































































.comment-replay-container {
  width: 659px;
  height: 581px;
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  .top-con {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 17px 0 24px;
    border-bottom: 1px solid #efefef;
    font-size: 16px;
    font-weight: 600;
    color: #313131;
    line-height: 22px;
    .close-icon {
      width: 22px;
      height: 23px;
      cursor: pointer;
    }
  }
  .content {
    padding: 12px 24px 0 24px;
    overflow: auto;
    height: 522px;
    ::v-deep {
      .comment-item-con {
        &::after {
          display: none;
        }
      }
    }
  }
  .bottom-tip {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #bbbbbb;
    line-height: 20px;
    padding-bottom: 24px;
  }
}
@media only screen and (max-height: 700px) {
  .comment-replay-container {
    transform: scale(0.8);
  }
}
