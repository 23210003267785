

























@font-face {
  font-family: 'AlibabaPuHuiTi';
  src: url('../../../../../assets/fonts/AlibabaPuHuiTi-2-105-Heavy.otf');
  font-weight: normal;
  font-style: normal;
}
::v-deep {
  .prize-tips-diaolog {
    background-color: transparent !important;
    box-shadow: none !important;
    position: absolute !important;
    margin: 0 !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tips-content {
      background: linear-gradient(134deg, #fff9f3 0%, #ffe3c1 100%);
      box-shadow: 0rem 0.0391rem 0.1563rem 0rem rgba(0, 0, 0, 0.1);
      border-radius: 0.1563rem;
      padding-top: 0.3906rem;
      position: relative;
      flex-shrink: 0;
      .close-icon {
        width: 0.7813rem;
        height: 0.7813rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0.1953rem;
        top: 0.1953rem;
        cursor: pointer;
        .close-icon-img {
          width: 0.2734rem;
          height: 0.2734rem;
        }
      }
      .dialog-head-con {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 0.4102rem;
        .c-title {
          display: inline-block;
          height: 0.5859rem;
          font-weight: 600;
          background: linear-gradient(180deg, #fe393c 0%, #cf171e 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: 0.5859rem;
          font-size: 0.4297rem;
          position: relative;
          margin: 0 0.1563rem;
          font-family: AlibabaPuHuiTi;
          &::after,
          &::before {
            content: '';
            display: inline-block;
            width: 0.8008rem;
            height: 0.0586rem;
            background: linear-gradient(297deg, rgba(239, 201, 158, 0) 0%, #fe3535 100%, #da9865 100%);
            border-radius: 0.0391rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          &::before {
            left: -1.2695rem;
          }
          &::after {
            right: -1.2695rem;
          }
          &::before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
