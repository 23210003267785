



























































































































































































.el-message {
  z-index: 3000;
}
.bt-container {
  .fixed-head {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
  }
  .bt-aside {
    position: absolute;
    top: 0;
    left: -250px;
    height: 100%;
    width: 250px;
  }
  .bt-main {
    flex: 1;
    min-height: 500px;
    height: 100%;
    background-color: #f6f9fb;
    .container {
      margin-bottom: 50px;
    }
    #content-container {
      min-height: 400px;
    }
    @media (max-width: 767px) {
      #content-container {
        min-height: 250px;
      }
    }
  }
  .auto-height {
    min-height: 100%;
  }
  &.show-sidebar {
    transform: translateX(250px);
    .bt-mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .black-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
  }
  .love-you {
    position: absolute;
    height: 421px;
    width: 713px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close-icon {
      position: absolute;
      top: -50px;
      right: 0;
      cursor: pointer;
    }
  }
}
.activity-layout {
  ::v-deep {
    .es-footer {
      padding-bottom: 100px;
    }
  }
}
