



































































































































































































































































::v-deep {
  .errata-drawer {
    overflow: inherit !important;
    .errata-drawer-content {
      width: 100%;
      height: 100vh !important;
      position: relative;
      display: flex;
      flex-direction: column;
      .errata-drawer-close {
        width: 14px;
        height: 82px;
        position: absolute;
        left: -14px;
        top: 52px;
        cursor: pointer;
        .errata-drawer-close-icon {
          width: 100%;
          height: 100%;
        }
      }
      .add-header {
        flex-shrink: 0;
        padding: 20px 20px 0;
        .add-header-ctx {
          height: 56px;
          width: 100%;
          background: #f7f7fc;
          border-radius: 0px 0px 2px 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 28px;
          .header-title {
            color: #363636;
            font-size: 18px;
            font-weight: bolder;
          }
          .header-btn-list {
            display: flex;
            align-items: center;
            .header-btn-1 {
              height: 30px;
              padding: 0 30px;
              font-size: 16px;
              color: #4b8fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              border: 1px solid #4b8fff;
              margin-right: 16px;
            }
            .header-btn-2 {
              height: 30px;
              padding: 0 30px;
              font-size: 16px;
              background: #4b8fff;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              border: 1px solid #4b8fff;
            }
          }
        }
      }
      .add-content {
        height: 0;
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
        padding: 35px 48px;
        .default-add-title {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: bolder;
          color: #363636;
          margin-bottom: 16px;
          .required-text {
            color: #ff6262;
            margin-right: 3px;
          }
          .choose-again {
            font-size: 16px;
            color: #4b8fff;
            font-weight: bolder;
            margin-left: auto;
            cursor: pointer;
          }
        }
        .choose-book-ctx {
          width: 260px;
          height: 130px;
          border: 1px dotted #bcbcbc;
          color: #8e8e93;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          .el-icon-plus {
            font-size: 16px;
            margin-right: 5px;
          }
        }
        .page-ctx {
          display: inline-block;
          border-bottom: 1px solid #efefef;
          font-size: 16px;
          color: #5a5a5a;
          .page-input {
            width: 240px;
            .el-input__inner {
              border: none !important;
              font-size: 16px;
              height: 36px;
              padding-left: 0px;
            }
          }
        }
        .textarea-ctx {
          border-radius: 8px;
          .el-textarea__inner {
            background: #f7f8f9 !important;
            border-radius: 8px !important;
            border: none !important;
            font-size: 16px;
            height: 128px;
            padding: 20px;
            resize: none;
          }
          .el-input__count {
            font-size: 16px;
            color: #8e8e93;
            background: #f7f8f9 !important;
            right: 20px;
            bottom: 12px;
          }
        }
      }
    }
  }
}
.upload-img-tips {
  font-size: 14px;
  color: #5a5a5a;
  line-height: 20px;
  margin-top: 16px;
}
.default-box {
  margin-bottom: 24px;
}
.error-box {
  .choose-book-ctx {
    border: 1px dotted #ff6262 !important;
  }
  .page-ctx {
    border-bottom: 1px solid #ff6262 !important;
    ::v-deep {
      .page-input .el-input__inner {
        color: #ff6262 !important;
        &::-webkit-input-placeholder {
          color: #ff6262 !important;
        }
      }
    }
  }
  .textarea-ctx {
    border: 1px solid #ff6262 !important;
  }
  .error-text {
    margin-top: 4px;
    color: #ff6262;
    font-size: 16px;
  }
}
.book-info {
  display: flex;
  .book-picture-box {
    width: 57px;
    height: 80px;
    position: relative;
    .book-picture-shadow {
      position: absolute;
      left: 0;
      right: 0;
      top: 12px;
      bottom: 0;
      box-shadow: 0px 2px 8px 0px rgba(0, 30, 117, 0.09);
    }
    .book-picture-img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 99;
    }
    .book-picture {
      width: 100%;
    }
    .book-type {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
    }
  }
  .show-shadow {
    box-shadow: 0px 2px 8px 0px rgba(0, 30, 117, 0.09);
  }
  .book-title {
    font-size: 16px;
    color: #5a5a5a;
    word-break: break-all;
    margin-left: 12px;
    font-weight: bolder;
  }
}
