

























































































































































































































































































































.ask-create {
  display: flex;
  .ask-create-left {
    flex: 1;
    width: 0;
    padding-right: 20px;
    margin-bottom: 60px;
    .ask-create-left-ctx {
      padding: 48px 32px;
      background: url('https://tmp.btclass.cn/yunyin/o_1h97lvfbbra11rt7e0f1tg71a5pa.png') #ffffff;
      background-repeat: no-repeat;
      position: relative;
      .ask-form-header {
        font-size: 18px;
        color: #313131;
        font-weight: bolder;
        margin-bottom: 20px;
        text-align: center;
      }
      .ask-form-title {
        color: #313131;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        .required {
          color: #ff6262;
        }
        .choose-again {
          font-size: 14px;
          color: #4b8fff;
          cursor: pointer;
          margin-left: auto;
        }
      }
      .submit-btn {
        margin-top: 40px;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        cursor: pointer;
      }
      .ask-form-textarea {
        ::v-deep {
          .el-textarea__inner {
            height: 130px;
            resize: none;
          }
        }
      }
      .choose-question {
        height: 38px;
        background: #f7f8f9;
        border-radius: 4px;
        .not-id {
          height: 100%;
          font-size: 14px;
          color: #919191;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .el-icon-plus {
            margin-right: 3px;
          }
        }
        .no-category {
          pointer-events: none;
        }
        .c-question {
          background: #f7f8f9;
          border-radius: 4px;
          margin-bottom: 12px;
          height: 38px;
          width: 100%;
          display: flex;
          align-items: center;
          .c-q-icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-left: 2px;
          }
          .c-q-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 14px;
            color: #5a5a5a;
            line-height: 20px;
            padding-right: 16px;
          }
        }
      }
      .ask-award {
        .ask-award-type {
          display: flex;
          align-items: center;
          ::v-deep {
            .el-radio {
              height: 25px;
              margin-bottom: 0px;
              margin-right: 20px;
              display: flex;
              align-items: center;
            }
            .el-radio__label {
              padding-bottom: 2px;
              padding-left: 4px;
              font-weight: 400;
            }
            .el-radio__input.is-checked .el-radio__inner {
              border: 1px solid #4b8fff;
              background-color: #ffffff;
            }
            .el-radio__inner::after {
              background-color: #4b8fff;
              width: 6px;
              height: 6px;
            }
          }
          .choose-quan {
            margin-left: auto;
            color: #4b8fff;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .award-type-1 {
          // min-height: 176px;
          .no-coupon {
            color: #919191;
            font-size: 14px;
          }
        }
        .award-type-2 {
          // min-height: 176px;
        }
        .B-list {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          .B-list-item-default {
            display: flex;
            width: 100px;
            height: 38px;
            border-radius: 4px;
            background: #f8f8f8;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            border: 1px solid #ffffff;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
          .is-active {
            border: 1px solid #4b8fff !important;
          }
          .B-list-item-input {
            display: flex;
            width: 0;
            flex: 1;
            height: 38px;
            border-radius: 4px;
            background: #f8f8f8;
            align-items: center;
            margin-right: 16px;
            padding-left: 12px;
            border: 1px solid #ffffff;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            ::v-deep {
              .el-input__inner {
                border: none;
                background: #f8f8f8;
                padding-left: 0px;
                height: 36px;
              }
            }
          }
        }
        .b-number {
          display: flex;
          align-items: center;
          .b-number-1 {
            font-size: 14px;
            color: #595959;
            line-height: 20px;
            margin-right: 20px;
            cursor: pointer;
            .blue-text {
              color: #4b8fff;
            }
          }
          .b-number-2 {
            font-size: 14px;
            color: #4b8fff;
            line-height: 20px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              width: 14px;
              height: 14px;
              margin-right: 4px;
            }
          }
          .b-number-3 {
            color: #4b8fff;
            font-size: 14px;
            cursor: pointer;
            line-height: 20px;
          }
        }
      }
    }
  }
  .ask-create-right {
    flex-shrink: 0;
    width: 300px;
  }
}
.m-b-14 {
  margin-bottom: 14px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
