
































































































































































































































































.is-win {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
  }

  /*定义滚动条轨道：内阴影+圆角*/
  ::-webkit-scrollbar-track {
    background-color: #eee;
  }

  /*定义滑块，即滚动条里面可以拖动的那个*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
  }
}
