























































.img-container {
  position: relative;

  .img {
    width: 100%;
  }

  .hot-item {
    position: absolute;
    // background-color: azure;
    // opacity: 0.3;
  }
}
