





































































































.scroll-num-list {
  display: flex;
  flex-direction: column;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.42, 0, 0.62, 1.2);
  overflow: hidden;
  .num-item {
    display: flex;
    justify-content: center;
  }
}
