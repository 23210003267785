




















































































































































































::v-deep {
  .c-title {
    font-size: 0.4688rem !important;
  }
}
.bind-mobile-container {
  width: 10.9375rem;
  margin-top: 0.3906rem;
  color: #616161;
  .head-content {
    .title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 0.3906rem;
        font-weight: 600;
        color: #131313;
        height: 0.5469rem;
      }
      .jump {
        font-size: 0.2734rem;
        cursor: pointer;
      }
    }
    .head-tips {
      font-size: 0.2734rem;
      height: 0.3906rem;
      line-height: 0.3906rem;
      margin-top: 0.0781rem;
    }
  }
  .binding-con {
    width: 100%;
    margin-top: 0.6836rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .input-item {
      width: 9.1406rem;
      height: 1.0156rem;
      background: #ffffff;
      border-radius: 0.1563rem;
      margin-bottom: 0.4688rem;
      .input-content {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 0.3906rem;
        .form-control {
          width: 100%;
          height: 100%;
          display: inline-block;
          border: none;
          padding-left: 0;
          font-size: 0.3516rem;
          color: #511700;
          &::placeholder {
            color: rgba($color: #511700, $alpha: 0.32);
          }
        }
        .form-control-sms {
          width: 5.8594rem;
        }
        .send-sms-btn {
          font-size: 0.3516rem;
          width: 2.5977rem;
          position: absolute;
          right: 0;
          bottom: 0;
          height: 100%;
          cursor: not-allowed;
          padding: 0 0.3906rem 0 0.1953rem;

          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba($color: #511700, $alpha: 0.32);
          &::after {
            content: '';
            display: inline-block;
            width: 0.0195rem;
            height: 0.3516rem;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba($color: #511700, $alpha: 0.31);
          }
          &.active {
            color: #511700;
            cursor: pointer;
          }
        }
      }
    }
    .btns-con {
      display: inline-block;
      margin-bottom: 0.4297rem;
      .submit-btn {
        font-size: 0.332rem;
        width: 4.8633rem;
        height: 1.1914rem;
        background-image: url('https://tmp.btclass.cn/yunyin/o_1hejomu4knm01e85176q1t811c1ng.png');
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #511700;
        background-color: transparent;
        border: none;
        opacity: 1;
        &.is-disabled {
          opacity: 0.7;
        }
      }
    }
    .question-tips {
      text-align: center;
    }
  }
  .bind-success-con {
    margin-top: 0.7422rem;
    text-align: center;
    padding: 0.9766rem 0.4688rem 1.9531rem 0.4688rem;
    .bind-txt {
      font-size: 0.3125rem;
      font-weight: 600;
      height: 0.4297rem;
      line-height: 0.4297rem;
      color: #313131;
    }
  }
}

.help-block {
  color: #f56c6c;
  font-size: 0.2344rem;
  line-height: 0.2344rem;
  margin: -0.3125rem 0 0.0781rem 0rem;
  padding-left: 0.8984rem;
  margin-right: auto;
}
