

























































































































































































































.ask-index {
  display: flex;
  .ask-index-left {
    flex: 1;
    width: 0;
    padding-right: 20px;
    .ask-index-left-container {
      .filter-box {
        padding: 20px 32px 0px;
        border-radius: 4px;
        background: #ffffff;
        // min-height: 175px;
      }
      .filter-result {
        position: relative;
        min-height: 600px;
        .ask-index-list {
          border-radius: 4px;
          .ask-index-item {
            padding: 20px 32px 20px;
            background: #ffffff;
            border-radius: 4px;
            margin-bottom: 10px;
          }
        }
        .no-list-ctx {
          padding: 160px 0 254px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          .no-list-icon {
            width: 113px;
            height: 101px;
            margin-bottom: 32px;
          }
          .no-list-tip {
            font-size: 16px;
            color: #5a5a5a;
            margin-bottom: 40px;
            line-height: 22px;
          }
          .to-index-btn {
            width: 300px;
            height: 40px;
            border-radius: 20px;
            color: #ffffff;
            background: #4b8fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .ask-index-no-result {
          border: 1px solid red;
        }
        .more-loading-text {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #bfbfbf;
          font-size: 14px;
          margin: 20px auto;
          .loading-text {
            padding-left: 6px;
          }
        }
        .no-more-text {
          display: flex;
          justify-content: center;
          color: #bfbfbf;
          font-size: 14px;
          margin: 20px auto;
          .line-item {
            flex-shrink: 0;
            height: 11px;
            width: 70px;
            border-bottom: 1px solid #efefef;
          }
          .line-text {
            flex-shrink: 0;
            padding: 0 8px;
          }
        }
      }
    }
  }
  .ask-index-right {
    flex-shrink: 0;
    width: 300px;
    margin-bottom: 32px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
}
