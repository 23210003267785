
























































.menu-shell-container {
  position: relative;
  flex-shrink: 0;
  .menu-fixed {
    position: fixed;
    top: 0;
  }
  .menu-absolute {
    position: absolute;
    bottom: 0;
    top: inherit;
  }
}
