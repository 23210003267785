


























.tag-vip-con {
  display: flex;
  align-items: center;
  .hot-icon {
    width: 27px;
    height: 15px;
    margin-left: 4px;
  }
}
.vip-icon {
  display: inline-block;
  width: 28px;
  height: 14px;
}
.tags {
  height: 14px;
  display: flex;
  align-items: center;
  .tag-item {
    flex-shrink: 0;
    min-width: 28px;
    display: inline-block;
    height: 14px;
    background: linear-gradient(316deg, #ff673a 0%, #ff8d58 100%);
    border-radius: 3px;
    line-height: 14px;
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 0 4px;
    text-align: center;
    margin-right: 4px;
  }
}
