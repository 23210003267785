










































.avator-con {
  position: relative;
  .avator {
    height: 100%;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }
  .office-v {
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }
  .user-tag-vip-con {
    position: absolute;
    right: -4px;
    bottom: 0;
    transform: translateX(100%);
  }
}
