



































































































































.verify-code-login {
  width: 252px;
  margin: 20px auto 0;

  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    color: #363636;
    height: 31px;
    padding: 7px 7px 7px 0;
    outline-style: none;
    line-height: normal;
    border-radius: 0;
    font-size: 12px;
  }
  ::v-deep .el-form-item__content {
    line-height: 32px;
  }
  .forget-password {
    color: #bbbbbb;
    float: right;
    font-size: 12px;
    margin-top: -15px;
  }
  ::v-deep .verify-code-wrap .el-form-item__content {
    display: flex;
    align-items: flex-end;
  }
  ::v-deep .el-form-item__error {
    text-align: left;
  }
  .send-msg-btn {
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #4b8fff;
    color: #4b8fff;
    height: 26px;
    line-height: 26px;
    flex-shrink: 0;
    padding: 0 8px;
    cursor: pointer;
    margin-left: 10px;

    &.disabled {
      border-color: #cccccc;
      color: #cccccc;
    }
  }
}
.full-width-btn {
  width: 100%;
  background: #4b8fff;
  height: 34px;
  line-height: 34px;
  padding: 0;
  border: none;
  margin: 10px 0 20px 0;

  &:disabled {
    background: #cccccc;
    border-color: #cccccc;
    color: #ffffff;
  }
}
