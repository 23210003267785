






















.loading {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .loading-text {
    text-align: center;
  }
}
