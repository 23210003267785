
























































.upload-imgs-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 500px;
  .img-item {
    position: relative;
    margin-right: 12px;
    margin-bottom: 12px;

    &:hover {
      .img-close {
        display: block !important;
      }
      .hover-box {
        display: flex;
      }
    }
    .img-item-ctx {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
    .img-close {
      display: none;
      position: absolute;
      right: -10px;
      top: -10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .hover-box {
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      display: none;
      justify-content: space-around;
      align-items: center;
      padding: 0 28px;
      border-radius: 4px;
      .h-icon {
        font-size: 22px;
      }

      .hover-box-item {
        height: 65px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .el-icon-delete,
        .el-icon-edit-outline {
          color: white;
        }
        .tip-text {
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          padding-top: 13px;
        }
        &.edit-btn {
          width: 32px;
          position: relative;
          overflow: hidden;
        }
      }
    }
  }
  .edit-upload-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
  }
}
