




























































.more-circle-container {
  display: grid;
  grid-template-columns: 865px 300px;
  grid-column-gap: 10px;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #363636;
    line-height: 28px;
  }
  .more-circle-content {
    padding: 24px 48px;
    background-color: #fff;
    border-radius: 8px;
    .circle-list {
      display: grid;
      grid-template-columns: repeat(5, 144px);
      grid-gap: 12px 12px;
      margin-top: 27px;
    }
  }
  .tip-span {
    margin-top: 16px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 30px;
    color: #919191;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
