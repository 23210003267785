






















































































































































.B-list {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  .B-list-item-default {
    display: flex;
    width: 100px;
    height: 38px;
    border-radius: 4px;
    background: #f8f8f8;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border: 1px solid #ffffff;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  .is-active {
    border: 1px solid #4b8fff !important;
  }
  .B-list-item-input {
    display: flex;
    width: 0;
    flex: 1;
    height: 38px;
    border-radius: 4px;
    background: #f8f8f8;
    align-items: center;
    margin-right: 16px;
    padding-left: 12px;
    border: 1px solid #ffffff;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    ::v-deep {
      .el-input__inner {
        border: none;
        background: #f8f8f8;
        padding-left: 0px;
        height: 36px;
      }
    }
  }
}
.b-number {
  display: flex;
  align-items: center;
  .b-number-1 {
    font-size: 14px;
    color: #595959;
    line-height: 20px;
    margin-right: 20px;
    .blue-text {
      color: #4b8fff;
    }
    .blue-text-loading {
      color: #4b8fff;
    }
  }
  .b-number-2 {
    font-size: 14px;
    color: #4b8fff;
    line-height: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
  .b-number-3 {
    color: #4b8fff;
    font-size: 14px;
    cursor: pointer;
    line-height: 20px;
  }
}
