
















































































































































































.bind-mobile-dialog {
  border-radius: 8px;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 500;
  }
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
  }
  .bind-mobile-container {
    margin-top: 20px;
    color: #616161;
    .head-content {
      .title-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 20px;
          font-weight: 600;
          color: #131313;
          height: 28px;
        }
        .jump {
          font-size: 14px;
          cursor: pointer;
        }
      }
      .head-tips {
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        margin-top: 4px;
      }
    }
    .binding-con {
      width: 100%;
      margin-top: 35px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .input-item {
        margin-bottom: 24px;
        height: 40px;
        line-height: 40px;
        // .input-label {
        //   width: 70px;
        //   text-align: left;
        //   font-size: 14px;
        //   display: inline-block;
        //   font-weight: 400;
        // }
        .input-content {
          display: inline-block;
          position: relative;
          width: 251px;
          .form-control {
            width: 100%;
            height: 40px;
            line-height: 40px;
            display: inline-block;
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding-left: 4px;
          }
          .form-control-sms {
            width: 160px;
          }
          .send-sms-btn {
            width: 85px;
            border: 1px solid #cccccc;
            color: #cccccc;
            position: absolute;
            right: 0;
            bottom: 0;
            height: 26px;
            line-height: 26px;
            text-align: center;
            border-radius: 2px;
            cursor: not-allowed;
            &.active {
              color: #1e73ff;
              border-color: #1e73ff;
              cursor: pointer;
            }
          }
        }
      }
      .btns-con {
        display: inline-block;
        .submit-btn {
          width: 251px;
          border-radius: 2px;
          margin: 37px 0 70px 0;
        }
      }
      .question-tips {
        text-align: center;
      }
    }
    .bind-success-con {
      margin-top: 38px;
      text-align: center;
      padding: 50px 24px 100px 24px;
      .bind-txt {
        font-size: 16px;
        font-weight: 600;
        height: 22px;
        line-height: 22px;
        color: #313131;
      }
    }
  }
  .submit-btn {
    border-radius: 4px;
    font-size: 14px;
    &.is-disabled {
      background: #cccccc;
      border-color: #cccccc;
      color: #ffffff;
    }
  }
  .success-notice {
    width: 172px;
    height: 112px;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .el-icon-circle-check {
      font-size: 42px;
      font-weight: blod;
    }
  }

  .help-block {
    color: #f56c6c;
    font-size: 12px;
    line-height: 12px;
    margin: -16px 0 4px 0px;
  }
}
