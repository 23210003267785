






















































.bt-category-select {
  ::v-deep {
    .el-select {
      width: 100% !important;
    }
  }
}
