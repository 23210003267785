/* common */
body,
ol,
ul,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  /* height: 100%; */
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #616161;
  background: #f5f8fa;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: #1e73ff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a:hover {
  color: #78ABFF;
  text-decoration: none;
}

em {
  font-style: normal;
}

li {
  list-style: none;
}

img {
  border: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

p {
  word-wrap: break-word;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.mt5 {
  margin-top: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mr10 {
  margin-right: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.ml20 {
  margin-left: 20px;
}

.mr20 {
  margin-right: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.ml15 {
  margin-left: 15px;
}

.mr15 {
  margin-right: 15px;
}

.ml0 {
  margin-left: 0;
}

.mt0 {
  margin-top: 0;
}

.mr0 {
  margin-right: 0;
}

.mb0 {
  margin-bottom: 0;
}

.pt0 {
  padding-top: 0;
}

.pr0 {
  padding-right: 0;
}

.pb0 {
  padding-bottom: 0;
}

.pl0 {
  padding-left: 0;
}

.pt5 {
  padding-top: 5px;
}

.pb5 {
  padding-bottom: 5px;
}

.pl5 {
  padding-left: 5px;
}

.pr5 {
  padding-right: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pb10 {
  padding-bottom: 10px;
}

.pl10 {
  padding-left: 10px;
}

.pr10 {
  padding-right: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pb15 {
  padding-bottom: 15px;
}

.pl15 {
  padding-left: 15px;
}

.pr15 {
  padding-right: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.pts {
  padding-top: 5px !important;
}

.ptm {
  padding-top: 10px !important;
}

.ptl {
  padding-top: 20px !important;
}

.prs {
  padding-right: 5px !important;
}

.prm {
  padding-right: 10px !important;
}

.prl {
  padding-right: 20px !important;
}

.pbs {
  padding-bottom: 5px !important;
}

.pbm {
  padding-bottom: 10px !important;
}

.pbl {
  padding-bottom: 20px !important;
}

.pls {
  padding-left: 5px !important;
}

.plm {
  padding-left: 10px !important;
}

.pll {
  padding-left: 20px !important;
}

.mts {
  margin-top: 5px !important;
}

.mtm {
  margin-top: 10px !important;
}

.mtl {
  margin-top: 20px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mts-o {
  margin-top: -5px !important;
}

.mtm-o {
  margin-top: -10px !important;
}

.mt15-o {
  margin-top: -15px !important;
}

.mtl-o {
  margin-top: -20px !important;
}

.mrs {
  margin-right: 5px !important;
}

.mrm {
  margin-right: 10px !important;
}

.mrl {
  margin-right: 20px !important;
}

.mbs {
  margin-bottom: 5px !important;
}

.mbm {
  margin-bottom: 10px !important;
}

.mbl {
  margin-bottom: 20px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mbs-o {
  margin-bottom: -5px !important;
}

.mbm-o {
  margin-bottom: -10px !important;
}

.mb15-o {
  margin-bottom: -15px !important;
}

.mbl-o {
  margin-bottom: -20px !important;
}

.mls {
  margin-left: 5px !important;
}

.mlm {
  margin-left: 10px !important;
}

.mll {
  margin-left: 20px !important;
}

.mhs {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mhm {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mhl {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mvs {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mvm {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mvl {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.phs {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.phm {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.phl {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pvs {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pvm {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pvl {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.mrs-o {
  margin-right: -5px !important;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.text-primary {
  color: #1e73ff !important;
}

.text-success {
  color: #70d445 !important;
}

.text-info {
  color: #4b8fff !important;
}

.text-warning {
  color: #ff8a0c !important;
}

.text-danger {
  color: #e83d2c !important;
}

.text-sm {
  font-size: 12px !important;
}

.text-md {
  font-size: 14px !important;
}

.text-lg {
  font-size: 16px !important;
}

.text-xlg {
  font-size: 18px !important;
}

.tac {
  text-align: center !important;
}

.tal {
  text-align: left !important;
}

.tar {
  text-align: right !important;
}

.fss {
  font-size: 10px;
}

.fsn {
  font-size: 12px;
}

.fsm {
  font-size: 14px;
}

.fsl {
  font-size: 16px;
}

.fsxl {
  font-size: 18px;
}

.text-small {
  font-size: 10px;
}

.text-normal {
  font-size: 12px;
}

.text-medium {
  font-size: 14px;
}

.text-large {
  font-size: 16px;
}

.text-xlarge {
  font-size: 18px;
}

.es-icon {
  line-height: 1;
}

.p-lg {
  line-height: 30px;
  margin-bottom: 30px;
}

.label {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.editor-text {
  overflow: hidden;
}

.editor-text img {
  max-width: 100% !important;
  height: auto !important;
}

.table td {
  word-wrap: break-word;
  word-break: break-all;
}

.section-gray {
  background-color: #f5f8fa !important;
}

.section-gray .text-line h5>span {
  background-color: #f5f8fa !important;
}

.section-wihte {
  background-color: #fff !important;
}

.section-wihte .text-line h5>span {
  background-color: #fff !important;
}

.anniversary-model-title {
  display: flex;
  align-items: center;
}

.anniversary-model-title .invite-anniversary img {
  display: block;
  height: 28px;
  margin-left: 8px;
}

.tag-anniversary-item {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 1.36rem;
  height: auto;
}

.tag-anniversary-info {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 72px;
  height: auto;
}

.img-responsive-mini {
  z-index: 1;
}

.divider-line {
  border-bottom: 1px solid #f5f5f5;
}

.h400 {
  min-height: 400px;
}

.inline-block {
  display: inline-block;
}

.empty {
  text-align: center;
  color: #c1c1c1;
  padding: 20px 0;
}

.link-dark {
  color: #313131 !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-dark:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-light {
  color: #616161 !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-light:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-lighter {
  color: #919191 !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-lighter:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-primary {
  color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-primary:hover {
  color: #1e73ff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-white {
  color: #fff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-white:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-primary {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link-primary:hover {
  color: #1e73ff !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a.transition {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a.transition:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  text-decoration: underline;
}

.ellipsis,
.text-overflow {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.es-box-shadow {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.global-icon-loading {
  color: #fff !important;
  font-size: 30px;
}

.loading {
  z-index: 1;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.click-span {
  cursor: pointer;
  color: #4b8fff;
}

.my-center-dialog-container {
  box-shadow: none;
  background-color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
}

.my-center-dialog-container .el-dialog__header {
  display: none;
}

.my-center-dialog-container .el-dialog__body {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-center-dialog-container .el-dialog__body .container{
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
}