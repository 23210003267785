






































































































































































































































































































































































































































































































































































































































































































































































































































.grand-rank-dialog {
  box-shadow: 0rem .0391rem .1563rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: .1563rem;
  width: 10.7422rem;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  background: transparent !important;
  box-shadow: none !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 !important;

    .activity-diaolog-content {
      width: 10.7422rem;
      height: 100%;
      border-radius: .1563rem;
      background-color: transparent;
      position: relative;
      flex-shrink: 0;

      .close-icon {
        width: .7813rem;
        height: .7813rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: .1953rem;
        top: .1953rem;
        cursor: pointer;

        .close-icon-img {
          width: .2734rem;
          height: .2734rem;
        }
      }

      .dialog-head-con {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: .6055rem;
        padding-bottom: .7422rem;
        background-image: url(https://tmp.btclass.cn/yunyin/o_1hcrv3tr71rtsus81rgb1q6g9mi.png);
        height: 3.6328rem;
        width: 100%;
        background-size: cover;

        .c-title {
          display: inline-block;
          height: .6445rem;
          font-weight: 600;
          background: linear-gradient(180deg, #fe393c 0%, #cf171e 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: .6445rem;
          margin-bottom: 0 .3125rem .3125rem .3125rem;
          font-size: .6641rem;
          position: relative;
          margin: 0 .1953rem;
          font-family: AlibabaPuHuiTi;

          &::after,
          &::before {
            content: '';
            display: inline-block;
            width: .8984rem;
            height: .0977rem;
            background: linear-gradient(297deg, rgba(239, 201, 158, 0) 0%, #fe3535 100%, #da9865 100%);
            border-radius: .0391rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            left: -1.2695rem;
          }

          &::after {
            right: -1.2695rem;
          }

          &::before {
            transform: rotate(180deg);
          }
        }

        .s-title {
          font-size: .5469rem;
          font-weight: normal;
          color: #363636;
          line-height: .7422rem;
          margin-top: .1563rem;
          font-family: AlibabaPuHuiTiMedium;
        }
      }

      .group-qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: .3125rem;
        color: #363636;
        line-height: .4297rem;
        padding-top: .5273rem;
        padding-bottom: .7813rem;
        background-image: url(https://tmp.btclass.cn/yunyin/o_1hcrv3tr71qtvr4vhbns06hoih.png);
        background-size: 100% 100%;
        margin-top: -0.0293rem;

        .qr-bg {
          width: 5.5469rem;
          height: 5.7617rem;
          background-image: url(https://tmp.btclass.cn/yunyin/o_1hcmo846k1tn5qvmmicek4a0ic.png);
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          .qr-img {
            width: 4.2969rem;
            height: 4.2969rem;
            margin-top: .625rem;
          }

          .tips {
            height: .5273rem;
            font-size: .3906rem;
            font-weight: normal;
            color: #c46245;
            line-height: .5273rem;
            margin-top: .1563rem;
          }
        }


        .f500 {
          font-weight: 500;
          font-family: AlibabaPuHuiTi;
        }
      }
    }

    .dailog-img-container {
      width: 11.7188rem;
      position: relative;
      flex-shrink: 0;

      .close-hot {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
}
