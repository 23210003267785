






















































.circle-confirm-dialog {
  padding-bottom: 28px !important;
  .el-message-box__content {
    padding-top: 24px;
    font-size: 16px;
    font-weight: 600;
    color: #262626;
    line-height: 24px;
  }
  .el-message-box__btns {
    padding-top: 9px;
    .el-button {
      border-radius: 18px;
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
