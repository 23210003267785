<!-- 问答模块-选择题目 -->
<template>
  <el-dialog custom-class="choose-question" title="提示" :visible.sync="syncVisible" width="856px" :close-on-click-modal="false">
    <div class="choose-question-header">
      <div class="back-ctx" @click="closeDialog"><i class="el-icon-arrow-left"></i>{{ activeCategory.name || '返回' }}</div>
      <div class="search-tabs">
        <el-tabs v-model="tabType" @tab-click="changeTabType">
          <el-tab-pane label="错题" name="mistakes"></el-tab-pane>
          <el-tab-pane label="收藏" name="favorite"></el-tab-pane>
        </el-tabs>
      </div>
      <el-button class="submit-btn" size="small" type="primary" :disabled="!activeQId" @click="submitHandler">确定</el-button>
    </div>
    <div class="search-from">
      <el-select class="search-select" popper-class="ask-select" size="small" v-model="searchType" placeholder="请选择" @change="changeSearchType">
        <el-option v-for="item in searchOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <el-input class="search-input" v-model="searchContent" size="small" :placeholder="`请输入${searchType === 'key' ? '关键字' : '题目ID'}搜索`" @keyup.enter.native="searchList"></el-input>
      <el-button class="search-btn" size="small" @click="searchList">搜索</el-button>
      <el-button class="reset-btn" size="small" @click="resteList">重置</el-button>
    </div>
    <div class="search-list" v-loading="isLoading">
      <template v-if="searchResultList.length">
        <div class="search-list-item" :class="{ 'no-bottom': index + 1 === searchResultList.length }" v-for="(item, index) in searchResultList" :key="index" @click="chooseQ(item)">
          <div class="active-status" :class="{ 'q-active': true }">
            <img v-if="item.question.isDel" src="https://tmp.btclass.cn/yunyin/o_1ha3jv529rcoda410ft1i3a1mgla.png"/>
            <img v-else-if="activeQId === item.question.id" src="https://tmp.btclass.cn/yunyin/o_1h9cil05k4rd1hs7o4cdiaisb.png" />
            <img v-else src="https://tmp.btclass.cn/yunyin/o_1h9cil05knptesp71r1br4jiac.png" />
          </div>
          <div class="q-content">
            <div class="q-stem">
              <span class="q-type">（{{ questionTypes[item.question.type] }}）</span><span :class="{ 'is-del': item.question.isDel }">{{ item.question.stem }}</span>
            </div>
            <div class="q-id">题目ID: {{ item.question.id }}</div>
          </div>
        </div>
      </template>
      <div v-else-if="!isLoading" class="no-list-ctx">
        <img class="no-list-icon" src="https://tmp.btclass.cn/yunyin/o_1h9crsvrdos71f0h1mtlgum8f7a.png" />
        <template v-if="!searchResultStatus">
          <div v-if="tabType === 'mistakes'">没有错题可以关联哦~</div>
          <div v-if="tabType === 'favorite'">没有收藏过题目哦~</div>
        </template>
        <div v-else>没有找到相关的题目哦～</div>
      </div>
    </div>
    <div class="search-footer">
      <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="true"
        :total="pagination.total"
        :page-size="pagination.limit"
        :current-page="pagination.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import FacingCard from '@/pc/views/my-coin/facing/_component/facing-card.vue';
import { getEasyToWrongQuestions, getQuestionsFavorites } from '@/api/ask';

@Component({
  components: {
    FacingCard,
  },
})
export default class ChooseCoupon extends Vue {
  @PropSync('visible', { type: Boolean, default: false }) syncVisible: any;
  @Prop({ type: Object, default: () => {} }) activeCategory: any;
  activeInfo: any = {};

  isLoading: boolean = true;
  tabType: string = 'mistakes'; // 选择题目类型 mistakes 错题 ｜ favorite 收藏
  searchOptions: any = [
    {
      value: 'key',
      label: '关键字',
    },
    {
      value: 'id',
      label: '题目ID',
    },
  ];
  searchType: string = 'key'; // 关键字 key ｜ 题目ID id
  searchContent: string = ''; // 搜索内容
  searchResultList: any = []; // 搜索结果
  searchResultStatus: boolean = false; // 是否在搜索

  questionTypes: any = {
    single_choice: '单选',
    choice: '多选',
    uncertain_choice: '不定项选择',
    fill: '填空',
    determine: '判断',
    essay: '问答',
    material: '材料',
  };

  pagination: any = {
    // 分页器数据
    total: 0,
    page: 1,
    limit: 5,
  };

  get activeQId() {
    return (this.activeInfo.question && this.activeInfo.question.id) || 0;
  }

  // 切换搜索类型
  changeSearchType() {
    this.searchContent = '';
  }

  // 重置列表
  async resteList() {
    this.pagination.total = 0;
    this.pagination.page = 1;
    this.searchContent = '';
    this.activeInfo = {};
    await this.getquestionList();
  }
  // 搜索
  async searchList() {
    if (this.searchType === 'id' && (isNaN(Number(this.searchContent)) || this.searchContent.indexOf('.') !== -1)) {
      this.$message.warning('ID必须为数字');
      return;
    }
    this.pagination.page = 1;
    await this.getquestionList();
  }

  @Watch('syncVisible')
  async watchVisible(val) {
    // 关闭重置
    if (!val) {
      this.activeInfo = {};
      await this.watchCategoryId(this.activeCategory.id);
    }
  }

  // 分类修改，则重新拉一下列表
  @Watch('activeCategory.id', { immediate: true })
  async watchCategoryId(id) {
    if (id) {
      this.tabType = 'mistakes';
      this.searchType = 'key';
      await this.resteList();
    }
  }

  // 类型修改，也重新拉一下列表、
  async changeTabType(tab, event) {
    console.log(tab, event);
    await this.resteList();
  }

  // 获取题目数据
  async getquestionList() {
    this.isLoading = true;
    if (this.tabType === 'mistakes') {
      await this.getMistakesList();
    }
    if (this.tabType === 'favorite') {
      await this.getFavoriteList();
    }
    this.isLoading = false;
  }

  // 获取错题列表
  async getMistakesList() {
    try {
      let params: any = {
        categoryId: this.activeCategory.id,
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        isEasyWrong: 0, // 非易错题
      };
      if (this.searchContent) {
        params.questionId = this.searchType === 'id' ? this.searchContent : undefined;
        params.stem = this.searchType === 'id' ? undefined : this.searchContent;
      }
      this.searchResultStatus = !!(params.questionId || params.stem);
      const { count, rows } = await getEasyToWrongQuestions(params);
      this.pagination.total = count;
      this.searchResultList = rows;
      console.log('获取错题列表', params, { count, rows });
    } catch (e) {
      console.log('获取错题列表失败', e);
    }
  }

  // 获取收藏题目的列表
  async getFavoriteList() {
    try {
      let params: any = {
        categoryId: this.activeCategory.id,
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        needCount: 1,
      };
      if (this.searchContent) {
        params.questionIds = this.searchType === 'id' ? this.searchContent + '' : undefined;
        params.stem = this.searchType === 'id' ? undefined : this.searchContent;
      }
      this.searchResultStatus = !!(params.questionIds || params.stem);
      const { count, rows } = await getQuestionsFavorites(params);
      this.pagination.total = count;
      rows.forEach(item => {
        item['question'] = {
          type: item.type,
          id: item.questionId,
          stem: item.stem,
          isDel: item.isDel,
        };
      });
      this.searchResultList = rows;
      console.log('获取收藏题目的列表', params, { count, rows });
    } catch (e) {
      console.log('获取收藏题目的列表失败', e);
    }
  }

  // 切换页数
  async handleCurrentChange(val) {
    this.pagination.page = val;
    await this.getquestionList();
    console.log('切换页数', val, this.pagination.page);
  }

  // 选择题目
  chooseQ(item) {
    // 已删除的不可选中
    if (item.question.isDel) return;
    this.activeInfo = item;
  }

  // 关闭弹窗
  closeDialog() {
    this.syncVisible = false;
  }

  // 确定
  submitHandler() {
    this.$emit('chooseQuestion', this.activeInfo);
    this.syncVisible = false;
  }
}
</script>

<style>
.ask-select .selected {
  color: #4B8FFF !important;
}
</style>

<style lang="scss" scoped>
::v-deep {
  .choose-question {
    margin-top: 10vh !important;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .choose-question-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 32px 0;
      border-bottom: 1px solid #d8d8d8;
      .back-ctx {
        display: flex;
        align-items: center;
        color: #595959;
        font-size: 16px;
        margin-top: 5px;
        cursor: pointer;
      }
      .search-tabs {
        .el-tabs__item {
          height: 28px;
          line-height: 28px;
          margin-bottom: 15px;
          font-size: 16px;
          color: #313131;
        }
        .is-active {
          color: #4b8fff;
        }
        .el-tabs__active-bar {
          height: 4px;
          border-radius: 2px;
          background: #4b8fff;
        }
        .el-tabs__nav-wrap::after {
          height: 1px;
          background-color: #d8d8d8;
        }
        .el-tabs__header {
          margin-bottom: 0px !important;
        }
      }
      .submit-btn {
        width: 91px;
        height: 32px;
        border-radius: 4px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        &.is-disabled {
          background: rgba(75, 143, 255, 0.39) !important;
          border: none !important;
        }
      }
    }
    .search-from {
      padding: 20px 32px;
      display: flex;
      align-items: center;
      .search-select {
        .el-input__inner {
          width: 90px;
          font-size: 14px;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
        }
      }
      .el-select .el-input__inner:focus,
      .el-select .el-input.is-focus .el-input__inner {
        border: 1px solid #4b8fff !important;
      }
      .search-input {
        .el-input__inner {
          width: 100%;
          font-size: 14px;
          border-radius: 0px 4px 4px 0px;
        }
      }
      .search-btn {
        flex-shrink: 0;
        width: 80px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #4b8fff;
        color: #4b8fff;
        font-size: 16px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .reset-btn {
        flex-shrink: 0;
        width: 80px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        color: #cccccc;
        font-size: 16px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .search-list {
      height: 568px;
      overflow: hidden;
      overflow-y: auto;
      padding: 0 32px;
      .search-list-item {
        display: flex;
        border-bottom: 1px solid #efefef;
        margin-bottom: 16px;
        cursor: pointer;
        .active-status {
          flex-shrink: 0;
          margin-right: 12px;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .q-content {
          flex: 1;
          width: 0;
          .q-stem {
            font-size: 16px;
            color: #313131;
            line-height: 24px;
            margin-bottom: 8px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // line-clamp: 2;
            // -webkit-box-orient: vertical;
            .q-type {
              color: #4b8fff;
            }
            .is-del {
              text-decoration: line-through;
            }
          }
          .q-id {
            color: #919191;
            font-size: 14px;
            margin-bottom: 16px;
            text-align: right;
          }
        }
      }
      .no-bottom {
        border-bottom: none;
        margin-bottom: 0px;
      }
      .no-list-ctx {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        color: #919191;
        line-height: 20px;
        padding-top: 150px;
        .no-list-icon {
          width: 118px;
          height: 92px;
          margin-bottom: 30px;
        }
      }
    }
    .search-footer {
      flex-shrink: 0;
      .el-pagination {
        padding: 20px 0 !important;
        margin: 0 !important;
        .active {
          background-color: #4B8FFF !important;
        }
      }
    }
  }
}
</style>