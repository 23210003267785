














































































































































































































































































































































































































































































































































































































::v-deep {
  .course-list-con {
    .home-course-list {
      .bt-course-card {
        height: 5.9469rem !important;
      }
    }
  }
}

.jian-mian-li {
  display: flex;
  justify-content: space-between;

  .left {
    width: calc(589 / 51.2) + rem;

    .item {
      margin-bottom: calc(20 / 51.2) + rem;
      width: 100%;
      cursor: pointer;
    }
  }

  .right {
    width: calc(589 / 51.2) + rem;

    img {
      margin-bottom: calc(20 / 51.2) + rem;
      width: 100%;
      cursor: pointer;
    }
  }
}

.jifen-box {
  width: calc(1200 / 51.2) + rem;

  .jifen-content {
    width: 100%;
  }
}

.julebu-box {
  width: calc(1200 / 51.2) + rem;
  position: relative;

  .julebu-content {
    width: 100%;
  }

  .julebu-code {
    position: absolute;
    top: 5.7rem;
    right: 2.2rem;
    width: 3rem;
    height: 3rem;
  }
}

.xuetong-box {
  width: calc(1200 / 51.2) + rem;
  position: relative;

  .xuetong-content {
    width: 100%;
  }

  .xuetong-hots-item {
    position: absolute;
    cursor: pointer;
  }

  .hot-tip {
    background: rgba(0, 0, 0, 0.3);
  }
}

.plan-con {
  margin-top: 0 !important;
}

.s-title {
  margin-top: calc(20 / 51.2) + rem !important;
}

.red-packet {
  margin-bottom: 1.5625rem;
  .receive-hb-text {
    position: absolute;
    top: 5.95rem;
    left: 3.1rem;
    height: 2rem;
    width: 4.6rem;
    cursor: pointer;
    font-size: 0.5rem;
    text-align: right;
    color: #841210;
    .text-1 {
      line-height: 0.7rem;
    }
    .text-2 {
      line-height: 0.7rem;
      margin-top: 0.1rem;
    }
  }
  .receive-hb {
    position: absolute;
    top: 5.6rem;
    left: 2rem;
    height: 3.6rem;
    width: 7.5rem;
    cursor: pointer;
  }
}
