


























































































































































































































::v-deep {
  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__nav-scroll {
    // display: flex;
    // justify-content: center;
    padding-left: 48px;
  }
  .el-tabs__item {
    height: 54px;
    line-height: 54px;
    font-size: 16px;
    color: #969696;
    padding: 0 50px;
  }
  .el-tabs__item:hover,
  .el-tabs__active-bar,
  .el-tabs__item.is-active {
    color: #4b8fff;
  }
  .el-tabs__item.is-active {
    font-weight: 600 !important;
  }
  .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #e5e5e5;
  }
}
.book-errata-my-container {
  width: 100%;
  min-width: 1175px;
  padding: 0 40px;
  min-height: 929px;
  // width: 100%;
  // min-width: 1175px;
  // max-width: 1440px;
  // min-height: 929px;
  // background: #ffffff;
  // border-radius: 6px;
  // margin: 30px auto;
  // position: relative;
  // display: flex;
  // flex-direction: column;
  .book-errata-my-ctx {
    width: 100%;
    max-width: 1440px;
    min-height: 929px;
    background: #ffffff;
    border-radius: 6px;
    margin: 30px auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .my-errata-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 48px;
    border-bottom: 1px solid #e5e5e5;
    .my-errata-btn {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 18px;
      font-weight: bolder;
      .back-icon {
        width: 25px;
        min-width: 25px;
        display: block;
        margin-right: 4px;
        cursor: pointer;
      }
    }
  }
  .my-errata-content {
    height: 0;
    flex: 1;
    display: flex;
    .my-errata-content-left {
      flex-shrink: 0;
      width: 440px;
      width: 398px;
      border-right: 1px solid #e5e5e5;
      /*修改滚动条样式*/
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 2px;
        background: #efefef !important;
        margin-right: 4px !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #c7c7c7;
        border-radius: 2px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #c7c7c7;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
      .errata-list {
        height: 804px;
        margin-top: 1px;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        .no-result {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #8e8e93;
        }
      }
    }
    .my-errata-content-right {
      width: 0;
      flex: 1;
      height: 858px;
      position: relative;
      overflow: hidden;
      overflow-y: auto;
      /*修改滚动条样式*/
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 2px;
        background: #efefef !important;
        margin-right: 4px !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #c7c7c7;
        border-radius: 2px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #c7c7c7;
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
      .list-no-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 150px;
        .no-result-icon {
          width: 131px;
          display: block;
          margin-bottom: 22px;
        }
        .no-result-text {
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }
      }
    }
  }
}
// @media (max-width: 1400px) {
//   .book-errata-my-container {
//     max-width: 1200px !important;
//   }
// }
