































































































































.bt-center {
  .bt-more {
    border: 1px solid #4b8fff;
    border-radius: 2px;
    margin-top: 1px !important;
  }
}
