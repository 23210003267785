



















































































































































































































































.bind-mobile-container {
  background-color: #fff;
  border-radius: 8px;
  width: 500px;
  color: #616161;
  padding: 20px 32px;
  position: relative;
  .close-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 16px;
    top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
  }
  .head-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #262626;
      line-height: 24px;
    }
    .s-title {
      font-size: 14px;
      font-weight: 400;
      color: #919191;
      line-height: 24px;
      margin-top: 4px;
    }
  }
  .subtitle-slot {
    margin-top: 4px;
    margin-bottom: 16px;
  }
  .binding-con {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .input-item {
      margin-bottom: 20px;
      height: 40px;
      line-height: 40px;
      .input-label {
        width: 80px;
        text-align: right;
        line-height: 40px;
        font-size: 16px;
        color: #313131;
        font-weight: 400;
        white-space: nowrap;
      }
      .input-content {
        display: inline-block;
        position: relative;
        width: 352px;
        .form-control {
          font-size: 14px;
          width: 100%;
          height: 40px;
          line-height: 40px;
          display: inline-block;
          border-radius: 4px;
          padding-left: 10px;
          font-weight: 400 !important;
          color: #313131 !important;
          &::placeholder {
            color: #cccccc;
          }
        }
        .send-sms-btn {
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #4b8fff;
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: not-allowed;
          min-width: 104px;
          font-size: 14px;
          font-weight: 400;
          opacity: 0.6;
          &::after {
            content: '';
            width: 1px;
            height: 23px;
            background-color: #cccccc;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          &.active {
            cursor: pointer;
            opacity: 1;
          }
        }
      }
    }
    .btns-con {
      display: inline-block;
      .submit-btn {
        width: 300px;
        margin-top: 20px;
      }
    }
    .question-tips {
      text-align: center;
    }
  }
  .has-subtitle {
    margin-top: 0px;
  }
  .bind-success-con {
    margin-top: 38px;
    text-align: center;
    padding: 50px 24px 100px 24px;
    .bind-txt {
      font-size: 16px;
      font-weight: 600;
      height: 22px;
      line-height: 22px;
      color: #313131;
    }
  }
}
.submit-btn {
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  &.is-disabled {
    background: #cccccc;
    border-color: #cccccc;
    color: #ffffff;
    &:hover {
      background: #cccccc;
      border-color: #cccccc;
    }
  }
}
.success-notice {
  width: 172px;
  height: 112px;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .el-icon-circle-check {
    font-size: 42px;
    font-weight: blod;
  }
}

.help-block {
  color: #f56c6c;
  font-size: 12px;
  line-height: 12px;
  margin: -16px 0 4px 0px;
  padding-left: 80px;
}

.can-jump {
  font-size: 14px;
  color: #8e8e93;
  margin-top: 16px;
  text-align: center;
  cursor: pointer;
}
