



























































































.recommend-course {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 16px 8px 16px;
  position: relative;
  .header-ctx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .header-title {
      color: #313131;
      font-size: 16px;
      font-weight: bolder;
      display: flex;
      align-items: center;
      img {
        flex-shrink: 0;
        width: 33px;
        height: 16px;
        margin-left: 4px;
        margin-bottom: 1px;
      }
    }
    .header-more {
      color: #919191;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .list-ctx {
    .list-item {
      margin-bottom: 8px;
      display: flex;
      cursor: pointer;
      .item-img {
        flex-shrink: 0;
        width: 110px;
        height: 62px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
      .item-ctx {
        padding-left: 8px;
        .item-title {
          color: #313131;
          font-size: 12px;
          line-height: 17px;
          margin-bottom: 11px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          min-height: 36px;
        }
        .item-footer {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #919191;
          img {
            width: 11px;
            height: 13px;
            margin-right: 5px;
          }
          .num-text {
            margin-bottom: -4px;
          }
        }
      }
    }
  }
}
