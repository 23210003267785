






































































































































































































































































































































































































































































.download-copy-link-dialog {
  width: 15.625rem;
  height: 10.5078rem;
  background: linear-gradient(180deg, #dbedff 0%, #ffffff 9%, #ffffff 100%);
  border-radius: 0.3125rem;
  margin: 0 !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .down-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 0.3516rem;
      color: #363636;
      line-height: 0.4883rem;
      margin-top: 0.4688rem;
      text-align: center;
    }
    .download-img {
      width: 14.9414rem;
      height: 7.8516rem;
    }
    .close-icon {
      width: 0.7813rem;
      height: 0.7813rem;
      cursor: pointer;
      position: absolute;
      right: 0.3125rem;
      top: 0.1563rem;
    }
    .copy-button {
      width: 6.543rem;
      height: 0.7813rem;
      background: #4b8fff;
      border-radius: 0.3906rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 0.3125rem;
      color: #ffffff;
      margin-top: 0.4102rem;
      cursor: pointer;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__footer,
  .el-dialog__header {
    display: none;
  }
}
