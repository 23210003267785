



















































::v-deep {
  .delete-notes {
    margin-top: calc(50vh - 79px) !important;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0 0 20px 0;
    }
    .header-ctx {
      font-size: 18px;
      color: #262626;
      padding: 20px 0 16px;
      font-weight: bolder;
      text-align: center;
      position: relative;
      .close-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
      }
    }
    .dialog-ctx {
      padding: 0 16px 0 32px;
      .tip-box {
        margin: 20px 0 24px;
        font-size: 16px;
        color: #262626;
        font-weight: bolder;
        .el-icon-warning {
          flex-shrink: 0;
          color: #faad14;
          width: 21px;
          height: 21px;
        }
      }
      .btn-list {
        display: flex;
        .btn-1 {
          width: 74px;
          height: 34px;
          border-radius: 18px;
          border: 1px solid #dcdfe6;
          color: #606266;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          cursor: pointer;
        }
        .btn-2 {
          width: 74px;
          height: 34px;
          border-radius: 18px;
          border: 1px solid #4b8fff;
          color: #ffffff;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #4b8fff;
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
  }
}
