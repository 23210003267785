


























































.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
