




















































































/*修改滚动条样式*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #efefef !important;
  margin-right: 4px !important;
}
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: #c7c7c7;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.tabs-chapter-page-box {
  min-width: 135px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 27px 0 0 0;
  border-right: 1px solid #e5e5e5;
  background: #ffffff;
  .tabs-chapter-page-title {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 8px;
  }
  .tabs-chapter-page-scroll {
    height: 0;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 16px 0 20px;
    position: relative;
    .page-loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.4);
    }
  }
  .page-item {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efefef;
    border-radius: 4px;
    font-size: 14px;
    color: #5a5a5a;
    margin-bottom: 16px;
    margin-right: 16px;
    cursor: pointer;
    &:hover {
      background: rgba(75, 143, 255, 0.1);
      color: #5a5a5a;
    }
  }
  .un-read-page-item {
    border: 1px solid #efefef;
    background: #ffffff;
  }
  .is-active {
    background: #4b8fff !important;
    border: 1px solid #4b8fff !important;
    color: #ffffff !important;
    .is-read {
      color: #ffffff !important;
    }
  }
}
.is-read {
  color: #bbbbbb;
}
