

































































.recommend-dynamic-container {
  padding: 24px 33px;
  width: 780px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 10px;
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #313131;
    line-height: 22px;
  }
  .dynamic-item {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #efefef;

    .dynamic-title {
      font-size: 16px;
      font-weight: 400;
      color: #313131;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      margin-bottom: 4px;
      &:hover {
        color: #4b8fff;
      }
    }
    .info-list {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #919191;
      line-height: 17px;
      .info-item {
        margin-right: 32px;
      }
    }
  }
  .more-btn {
    width: 716px;
    height: 40px;
    margin: 0 auto;
    background: #fafafa;
    margin-top: 15px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #313131;
    cursor: pointer;
  }
}
