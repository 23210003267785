




















































































































































.about-user-container {
  background-color: #fff;
  padding: 16px;
  margin-bottom: 10px;
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #313131;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .user-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .user-name {
      font-size: 14px;
      font-weight: 600;
      color: #363636;
      line-height: 20px;
      margin: 8px 0;
      text-align: center;
      cursor: pointer;
    }
  }
  .intro-con {
    width: 250px;
    max-height: 34px;
    font-size: 12px;
    color: #313131;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding-left: 18px;
    position: relative;
    margin-bottom: 8px;
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      background-image: url('https://tmp.btclass.cn/yunyin/o_1hft0nebs1mlmkkqnli1sd3vkga.png');
      background-size: cover;
      position: absolute;
      left: 0;
      background-repeat: no-repeat;
      top: 0;
    }
  }
  .info-list {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    cursor: pointer;

    .info-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #5a5a5a;
      line-height: 20px;
      .num {
        color: #8e8e93;
      }
    }
    .focus-item {
      padding: 0 24px;
      margin: 0 24px;
      &::after,
      &::before {
        content: '';
        width: 1px;
        height: 14px;
        background-color: #cccccc;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        right: 0;
      }
      &::before {
        left: 0;
      }
    }
  }
  .focue-btn {
    width: 180px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #4b8fff;
    font-size: 14px;
    font-weight: 600;
    color: #4b8fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
    &.is-focus {
      color: #cccccc;
      border-radius: 15px;
      border: 1px solid #cccccc;
    }
    .icon {
      font-size: 20px;
    }
  }
}
